import React, { Component } from "react";
import TitleOfPage from "../components/TitleOfPage";

import UserDetails from "../components/cart/UserDetails";

import "../styles/userDetails.css";

export default class YourDetails extends Component {
  state = {
    restName:
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant")).name,
  };
  render() {
    return (
      <React.Fragment>
        <TitleOfPage title={`Your Details | ${this.state.restName}`} />
        <UserDetails />
      </React.Fragment>
    );
  }
}
