import React, { useState } from "react";
import axios from "axios";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ForgetPasswordModal() {
  const [emailRequest, setEmailRequest] = useState("");
  const [emailRequestError, setEmailRequestError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  // chnage input
  const handleChange = (e) => {
    setEmailRequest(e.target.value);
  };

  // validate
  const validate = () => {
    let emailRequestError = "";

    if (emailRequest === "") {
      emailRequestError = "Email is required";
    } else if (!emailRequest.includes("@")) {
      emailRequestError = "Email is invalid";
    }

    if (emailRequestError) {
      setEmailRequestError(emailRequestError);
      return false;
    }

    return true;
  };

  // forget password
  const ForgetPassowrdReset = () => {
    if (validate()) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/user/password/reset?email=${emailRequest}`
        )
        .then((res) => {
          setEmailRequest("");
          setSuccessMsg(res.data.message);

          document.getElementById("forgetpasswordModel").click();
        })
        .catch((err) => {
          if (err.response.data.code === 1012) {
            setEmailRequest("");
            setErrorMsg("Email is not found");
          }
        });
    }
  };

  // close modal
  const close = () => {
    setSuccessMsg("");
  };

  return (
    <React.Fragment>
      {successMsg !== "" ? (
        <SweetAlert
          success
          title="Successfull"
          onConfirm={close}
          confirmBtnText="OK"
          confirmBtnStyle={{ background: "#f26666", border: 0, width: 70 }}
        >
          Email has been sent successfully!
        </SweetAlert>
      ) : null}

      <div
        className="modal fade login-sign-up-modal"
        id="forgetpasswordModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="forgetpassword"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered forgot-password-main"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="update-bank-model-heading">
                Send reset password email
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-3">
              <p className="text-success mb-0 ml-1">{successMsg}</p>
              <p className="text-danger mb-0 ml-1">{errorMsg}</p>
              <form>
                <div className="form-row m-0">
                  <div className="col mb-2">
                    <label
                      htmlFor="emailRequest"
                      className="normal-bold-text ml-0"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      className={classnames("form-control", {
                        "is-invalid": emailRequestError,
                      })}
                      id="emailRequest"
                      value={emailRequest}
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">{emailRequestError}</div>
                  </div>
                </div>
              </form>

              <div className="login-or-reg-buttons">
                <div className="login-or-reg-button"></div>
                <div className="login-or-reg-button">
                  <button
                    className="btn w-100 mx-auto mt-3 mb-0"
                    onClick={ForgetPassowrdReset}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
