import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getParams } from "../methods/getParams";
import { withRouter } from "react-router-dom";

export default class Order extends Component {
  state = {
    isSuccess: false,
  };

  componentDidMount = () => {
    const success = getParams("success");

    if (success === "true") {
      this.setState({ isSuccess: JSON.parse(success) });
      this.next();
    }
  };

  // next
  next = () => {
    const restData = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant"));
    localStorage.removeItem(`cart_${restData.id}`);
    localStorage.removeItem("orderDetails");
    localStorage.removeItem("deliveryAddress");
    localStorage.removeItem("deliveryNote");
    localStorage.removeItem("orderType");
    localStorage.removeItem("asap");
    localStorage.removeItem("expectedAt");
    localStorage.removeItem("totalExtraCost");
    localStorage.removeItem("extraCostData");
    localStorage.removeItem("cartSubTotal");
    localStorage.removeItem("selectedOrderTypeData");
    localStorage.removeItem("matchMinOrder");
    localStorage.removeItem("guest_checkout");
    localStorage.removeItem("guest_details");

    setTimeout(() => {
      this.props.history.push("/");
    }, 10000);
  };

  render() {
    return (
      <div
        style={{
          background:
            localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
              ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
              : "#f26666",
          width: "100vw",
          height: "100vh",
        }}
      >
        <div style={div}>
          {this.state.isSuccess ? (
            <div style={successIcon}>
              <i className="far fa-check-circle"></i>
            </div>
          ) : (
            <div style={errorIcon}>
              <i class="far fa-times-circle"></i>
            </div>
          )}
          <h1 style={successText}>{this.state.isSuccess ? "Payment Successful" : "Payment Error!"}</h1>
          {this.state.isSuccess ? <h6 style={successTextSub}>Your payment has been successfully completed</h6> : <h6 style={successTextSub}>Your payment is not completed successfully</h6>}
          <Link style={homeLink} to="/">
            Continue
          </Link>
        </div>
      </div>
    );
  }
}

const div = {
  width: "320px",
  backgroundColor: "#ffffff",
  padding: "1rem",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const successIcon = {
  textAlign: "center",
  fontSize: "3.4rem",
  color: "#28a745",
};

const errorIcon = {
  textAlign: "center",
  fontSize: "3.4rem",
  color: "#ff6347",
};

const successTextSub = {
  textAlign: "center",
  marginTop: "1rem",
  fontWeight: "300",
  color: "#808080",
};

const successText = {
  textAlign: "center",
  fontSize: "1.4rem",
  fontWeight: "400",
};

const homeLink = {
  display: "block",
  margin: "auto",
  marginTop: 20,
  width: "100%",
  textAlign: "center",
  border: "1.5px solid",
  padding: 10,
  backgroundColor: "#28a745",
  color: "#ffffff",
};
