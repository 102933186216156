import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { hexToRgb } from "../../methods/CalBrightness";

class NavbarPlain extends Component {
  constructor() {
    super();
    this.state = {
      listingId: JSON.parse(localStorage.getItem("restaurant"))?.id ?? null,
      extId: JSON.parse(localStorage.getItem("restaurant"))?.extId ?? null,
      restName: JSON.parse(localStorage.getItem("restaurant"))?.name ?? null,
      redirectToMenu: false,
    };
  }

  componentDidMount = () => {
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        localStorage.getItem("restaurant")
          ? JSON.parse(localStorage.getItem("restaurant")).branding
            ? JSON.parse(localStorage.getItem("restaurant")).branding
                .metaKeywords
              ? JSON.parse(localStorage.getItem("restaurant")).branding
                  .metaKeywords
              : ""
            : ""
          : ""
      );

    const backgroundColor =
      JSON.parse(localStorage.getItem("restaurant"))?.branding?.colorHeader !==
        undefined &&
      JSON.parse(localStorage.getItem("restaurant"))?.branding?.colorHeader
        ? JSON.parse(localStorage.getItem("restaurant"))?.branding?.colorHeader
        : null;

    document.documentElement.style.setProperty(
      "--main-background-color",
      backgroundColor
    );
  };

  render() {
    return (
      <React.Fragment>
        <nav
          className="navbar sticky-top navbar-light"
          style={{
            background:
              JSON.parse(localStorage.getItem("restaurant"))?.branding
                ?.colorHeader ?? "#151b26",
            height: "65px",
          }}
        >
          <div className="container-fluid">
            <div
              className="nav-go-to-menu-main"
              style={{
                color:
                  hexToRgb(
                    JSON.parse(localStorage.getItem("restaurant"))?.branding
                      ?.colorHeader ?? "#000000"
                  ) > 0.65
                    ? "#000"
                    : "#fff",
              }}
            >
              <div className="nav-go-to-menu-inner hide-on-mobile">
                <Link className="nav-go-to-menu" to={`/`}>
                  Menu
                </Link>
              </div>

              <div className="nav-go-to-menu-inner hide-on-mobile">
                <Link className="nav-go-to-menu" to="/store-details">
                  Store Details
                </Link>
              </div>

              <div className="nav-mobile-view ">
                <div
                  className="nav-mobile-view-button"
                  id="navDropdownMenu"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    color:
                      hexToRgb(
                        JSON.parse(localStorage.getItem("restaurant"))?.branding
                          ?.colorHeader ?? "#000000"
                      ) > 0.65
                        ? "#000"
                        : "#fff",
                  }}
                >
                  <i className="fas fa-bars"></i>
                </div>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navDropdownMenu"
                >
                  <Link className="nav-go-to-menu dropdown-item" to={`/`}>
                    <i className="far fa-list-alt"></i> Menu
                  </Link>
                  <Link
                    className="nav-go-to-menu dropdown-item"
                    to="/store-details"
                  >
                    <i className="fas fa-info"></i> Store Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default withRouter(NavbarPlain);
