import React, { Component } from "react";
import { Link, Redirect, withRouter , BrowserRouter as Router } from "react-router-dom";
import Cookies from "js-cookie";
import Favicon from "react-favicon";
import { itemsOfCart } from "../../methods/CartMethods";
import { hexToRgb } from "../../methods/CalBrightness";

import Cart from "../../pages/NewCart";
import LoginRegisterPopup from "../loginRegister/PopupWindow";
import ForgetPasswordModal from "../ForgetPasswordModal";
import { getUserProfileAuth, userLogin, getUserProfileOnLogin, userRegister, userLoginAfterRegister, resendEmailVerificationAPI } from "../api/UserDetailsAPI";
import "../../styles/dateTimePicker.css";
import ManageOrderTimeOnResClosed from "../orderManage/ManageOrderTimeOnResClosed";
import { errorAllert, successAlert } from "../../methods/Alert";

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      listingId: localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).id,
      extId: localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).extId,
      restName: localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).name,
      username: localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).name,
      userEmail: localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).email,
      redirectToMenu: false,
      // login
      email: "",
      password: "",

      emailError: "",
      passwordError: "",

      // register
      name: "",
      regEmail: "",
      regPwd: "",
      regPwdConfirm: "",

      nameError: "",
      regEmailError: "",
      regPwdError: "",
      regPwdConfError: "",

      // alerts
      registerSuccess: false,

      loginSuccess: false,
      loginFail: false,
      emailNotVerified: false,
      showResendSuccess: false,
      showResendFail: false,

      // loading
      loading: false,
      loadingReg: false,

      // show hide cart
      viewCart: false,

      // If in cart Page, Redirect to User Details
      redirectToUserDetail: false,
      toggleRender: false,
      updateExpectedAt: "",
    };
  }

  componentDidMount = () => {
    if (document.cookie.includes("auth_token")) {
      this.getUserProfileData();
    }

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        localStorage.getItem("restaurant")
          ? JSON.parse(localStorage.getItem("restaurant")).branding
            ? JSON.parse(localStorage.getItem("restaurant")).branding.metaKeywords
              ? JSON.parse(localStorage.getItem("restaurant")).branding.metaKeywords
              : ""
            : ""
          : ""
      );

    const backgroundColor =
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant")).branding &&
      JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader !== undefined &&
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant")).branding &&
      JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
        ? localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
        : null;

    document.documentElement.style.setProperty("--main-background-color", backgroundColor);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.expectedAt !== prevState.expectedAt) {
      return { expectedAt: nextProps.expectedAt };
    }
    return null;
  }

  async getUserProfileData() {
    try {
      let res = await getUserProfileAuth();

      if (res && res.status === 200) {
        if (typeof window !== "undefined") {
          localStorage.setItem("profile", JSON.stringify(res.data));
        }

        this.setState({
          username: res.data.name,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  // handle inputs
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // login
  handleLogin = (e, email, password) => {
    e.preventDefault();

    this.setState({
      registerSuccess: false,
      loginSuccess: false,
      loginFail: false,
      emailNotVerified: false,
      showResendSuccess: false,
      showResendFail: false,
      email: email,
    });

    // create user data object
    const loginData = {
      email: email,
      password: password,
      accountType: "email",
      clientId: "web",
    };

    // set loading
    this.setState({ loading: true, loginSuccess: false });

    userLogin(
      loginData,
      (data) => {
        if (document.location.host.includes("spritzer")) {
          document.cookie = `auth_token=${JSON.stringify(data)};domain=spritzer.app`;
        } else {
          document.cookie = `auth_token=${JSON.stringify(data)}`;
        }

        getUserProfileOnLogin(
          data.providerToken,
          (userData) => {
            if (typeof window !== "undefined") {
              localStorage.setItem("profile", JSON.stringify(userData));
            }

            this.setState({
              username: userData.name,
              email: "",
              password: "",
              emailError: "",
              passwordError: "",
              loading: false,
              loginSuccess: true,
              loginFail: false,
            });

            document.getElementById("login-sign-up-modal").click();
            localStorage.removeItem("guest_details");

            if (window.location.href.indexOf("cart") > -1) {
              this.setState({ redirectToUserDetail: true });
            }
          },
          (error) => {
            console.log(error);
            this.setState({
              emailError: "",
              passwordError: "",
              loading: false,
              loginFail: true,
              loginSuccess: false,
            });
          }
        );
      },
      (error) => {
        if (error.code === 1010) {
          this.setState({
            emailError: "",
            passwordError: "",
            loading: false,
            loginFail: true,
            loginSuccess: false,
          });
        } else if (error.code === 1011) {
          this.setState({
            emailError: "",
            passwordError: "",
            loading: false,
            emailNotVerified: true,
            loginFail: false,
            loginSuccess: false,
          });
        } else {
          this.setState({
            emailError: "",
            passwordError: "",
            loading: false,
            loginFail: true,
            loginSuccess: false,
          });
        }
      }
    );
  };

  // register
  handleRegister = (e, name, regEmail, regPwd) => {
    e.preventDefault();
    // set loading
    this.setState({ loadingReg: true, registerSuccess: false });

    this.setState({ regEmail, regPwd });

    // create user data object
    const user = {
      name: name,
      email: regEmail,
      password: regPwd,
      accountType: "email",
      isBusiness: false,
    };

    userRegister(
      user,
      (data) => {
        const loginData = {
          email: this.state.regEmail,
          password: this.state.regPwd,
          accountType: "email",
          clientId: "web",
        };

        userLoginAfterRegister(
          loginData,
          (logData) => {
            if (document.location.host.includes("spritzer")) {
              document.cookie = `auth_token=${JSON.stringify(logData)};domain=spritzer.app`;
            } else {
              document.cookie = `auth_token=${JSON.stringify(logData)}`;
            }

            getUserProfileOnLogin(
              logData.providerToken,
              (profileData) => {
                if (typeof window !== "undefined") {
                  localStorage.setItem("profile", JSON.stringify(profileData));
                }

                this.setState({
                  username: profileData.name,
                  name: "",
                  regEmail: "",
                  regPwd: "",
                  regPwdConfirm: "",
                  regEmailError: "",
                  nameError: "",
                  regPwdError: "",
                  regPwdConfError: "",
                  loadingReg: false,
                  registerSuccess: true,
                });

                document.getElementById("login-sign-up-modal").click();
                successAlert("Registration Successful!");
              },
              (error) => {
                console.log(error);
                this.setState({
                  loadingReg: false,
                  registerSuccess: false,
                });
              }
            );
          },
          (error) => {
            console.log(error);
            this.setState({
              loadingReg: false,
              registerSuccess: false,
            });
          }
        );
      },

      (error) => {
        if (error.code === 1004) {
          this.setState({
            regEmailError: "Register Error!",
            nameError: "",
            regPwdError: "",
            regPwdConfError: "",
            loadingReg: false,
            registerSuccess: false,
          });
          errorAllert("Email already exists. Please try again!");
        }
      }
    );
  };

  resendEmailVerification = () => {
    resendEmailVerificationAPI(
      this.state.email,
      (data) => {
        console.log(data);
        this.setState({
          emailNotVerified: false,
          showResendSuccess: true,
          loginSuccess: false,
          loginFail: false,
        });
      },

      (error) => {
        console.log(error);
        this.setState({
          emailNotVerified: false,
          showResendFail: true,
          loginSuccess: false,
          loginFail: false,
        });
      }
    );
  };

  closeModal = () => {
    this.setState({
      extId: localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).extId,
      username: "",

      redirectToMenu: false,

      // login
      email: "",
      password: "",

      emailError: "",
      passwordError: "",

      // register
      name: "",
      regEmail: "",
      regPwd: "",
      regPwdConfirm: "",

      nameError: "",
      regEmailError: "",
      regPwdError: "",
      regPwdConfError: "",

      // alerts
      registerSuccess: false,
      loginSuccess: false,
      loginFail: false,
      emailNotVerified: false,
      showResendSuccess: false,
      showResendFail: false,

      // loading
      loading: false,
      loadingReg: false,
    });
  };

  handleLogout = () => {
    if (document.location.host.includes("spritzer")) {
      document.cookie = "auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "guest_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      Cookies.remove("auth_token", { path: "", domain: ".spritzer.app" });
      Cookies.remove("guest_token", { path: "", domain: ".spritzer.app" });
    } else {
      document.cookie = "auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "guest_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      Cookies.remove("auth_token", { path: "" });
      Cookies.remove("guest_token", { path: "" });
    }

    localStorage.removeItem("listing");
    localStorage.removeItem("profile");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("totalExtraCost");
    localStorage.removeItem("extraCostData");
    localStorage.removeItem("cartSubTotal");
    localStorage.removeItem("selectedOrderTypeData");
    localStorage.removeItem("deliveryAddress");
    localStorage.removeItem("guest_checkout");
    localStorage.removeItem("guest_details");

    this.setState({ redirectToMenu: true });
  };

  orderType = (type) => {
    if (type === "delivery") {
      this.setState({ delivery: true, pickup: false, dine_in: false });
    } else if (type === "pickup") {
      this.setState({ delivery: false, pickup: true, dine_in: false });
    } else {
      this.setState({ delivery: false, pickup: false, dine_in: true });
    }

    localStorage.setItem("orderType", type);
  };

  getTime = (value) => {
    this.setState({ scheduleTime: value.format("HH:mm") });
  };

  handleCartDisplay = () => {
    this.setState({ viewCart: true });
  };

  handleCartDisplayClose = () => {
    this.setState({ viewCart: false }, () => {
      this.setState({ viewCart: false });
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.redirectToMenu ? <Redirect to="/" /> : null}

        {localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.imageFavicon ? (
          <Favicon url={JSON.parse(localStorage.getItem("restaurant")).branding.imageFavicon.url} />
        ) : null}

        <nav
          className="navbar sticky-top navbar-light"
          style={{
            background:
              localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                ? JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                : "#151b26",
            height: "65px",
          }}
        >
          <div className="container-fluid">
            <div
              className="nav-go-to-menu-main"
              style={{
                color:
                  hexToRgb(
                    localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                      ? JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                      : "#000000"
                  ) > 0.65
                    ? "#000"
                    : "#fff",
              }}
            >
              <div className="nav-go-to-menu-inner hide-on-mobile">
                <Link className="nav-go-to-menu" to={`/`}>
                  Menu
                </Link>
              </div>

              <div className="nav-go-to-menu-inner hide-on-mobile">
                <Link className="nav-go-to-menu" to="/store-details">
                  Store Details
                </Link>
              </div>

              <div className="nav-mobile-view ">
                <div
                  className="nav-mobile-view-button"
                  id="navDropdownMenu"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    color:
                      hexToRgb(
                        localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                          ? JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                          : "#000000"
                      ) > 0.65
                        ? "#000"
                        : "#fff",
                  }}
                >
                  <i className="fas fa-bars"></i>
                </div>
                <div className="dropdown-menu" aria-labelledby="navDropdownMenu">
                  <Link className="nav-go-to-menu dropdown-item" to={`/`}>
                    <i className="far fa-list-alt"></i> Menu
                  </Link>
                  <Link className="nav-go-to-menu dropdown-item" to="/store-details">
                    <i className="fas fa-info"></i> Store Details
                  </Link>
                  <span className="nav-go-to-menu dropdown-item" onClick={this.handleCartDisplay}>
                    <i className="fas fa-shopping-cart"></i> View Cart
                  </span>
                  {document.cookie.includes("auth_token") ? null : (
                    <span className="dropdown-item" data-toggle="modal" data-target="#login-sign-up-modal">
                      <i className="fas fa-sign-in-alt"></i> Sign in
                    </span>
                  )}
                </div>
              </div>
            </div>

            {this.props.displayFinalizedInfo ? (
              <div
                className="finalized-order-info"
                style={{
                  color:
                    hexToRgb(
                      localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                        ? JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                        : "#000000"
                    ) > 0.65
                      ? "#000"
                      : "#fff",
                }}
              >
                <div className="finalized-order-type">
                  {localStorage.getItem("orderType") === "delivery" ? (
                    <div>
                      <i className="fas fa-shipping-fast"></i> Delivery
                    </div>
                  ) : localStorage.getItem("orderType") === "pickup" ? (
                    <div>
                      <i className="far fa-hand-paper"></i>Pickup
                    </div>
                  ) : (
                    <div>
                      <i className="fas fa-utensils"></i>Dine In
                    </div>
                  )}
                </div>

                <div className="finalized-order-time">
                  {localStorage.getItem("expectedAt") !== "" && localStorage.getItem("asap") === "false" ? (
                    <div>
                      <i className="fas fa-calendar-alt"></i>
                      {localStorage.getItem("expectedAt")}
                    </div>
                  ) : null}
                  {localStorage.getItem("expectedAt") === "" && localStorage.getItem("asap") === "true" ? (
                    <div>
                      <i className="far fa-clock"></i>ASAP
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            <div className="nav-right-btn-block">
              <div className="shopping-cart" onClick={this.handleCartDisplay}>
                <div>
                  <i
                    className="fas fa-shopping-cart"
                    style={{
                      color:
                        hexToRgb(
                          localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                            ? JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                            : "#000000"
                        ) > 0.65
                          ? "#000"
                          : "#fff",
                    }}
                  ></i>
                  <span
                    className="cart-badge"
                    style={{
                      background:
                        localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                          ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                          : "#ff7068",
                    }}
                  >
                    {" "}
                    {itemsOfCart(this.props.restId)}
                  </span>
                </div>

                {this.state.viewCart ? <Cart handleCartDisplayClose={this.handleCartDisplayClose} cartUpdate={this.props.cartUpdate} /> : null}
              </div>

              {document.cookie.includes("auth_token") ? (
                <div
                  className="nav-main-buttons"
                  style={{
                    color:
                      hexToRgb(
                        localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                          ? JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                          : "#000000"
                      ) > 0.65
                        ? "#000"
                        : "#fff",
                  }}
                >
                  <div className="btn-group float-right user-display-btn" style={{ cursor: "pointer" }}>
                    <span className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i
                        className="fas fa-user-circle"
                        style={{
                          verticalAlign: "middle",
                          fontSize: "27px",
                          color:
                            hexToRgb(
                              localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                                ? JSON.parse(localStorage.getItem("restaurant")).branding.colorHeader
                                : "#000000"
                            ) > 0.65
                              ? "#000"
                              : "#fff",
                        }}
                      ></i>
                    </span>

                    <div className="dropdown-menu dropdown-menu-right user-account-info-popup" style={{ zIndex: 9999, fontSize: 13 }}>
                      <div className="nav-user-info">
                        <div className="display-user-name-nav">{this.state.username}</div>
                        <div className="display-user-email-nav">
                          {localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).email}
                          {localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).isActivated ? <i className="fas fa-check-circle"></i> : null}
                        </div>
                        <span className="nav-user-active-state pl-2">{localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).isActivated ? null : "(Not activated)"}</span>
                      </div>

                      <a
                        className="dropdown-item text-dark"
                        href={process.env.REACT_APP_API_URL === "https://api.spritzer.app" ? "https://www.spritzer.app/my-account" : "https://www-dev.spritzer.app/my-account"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ lineHeight: "30px" }}
                      >
                        <i className="fas fa-user"></i> &nbsp; My Account
                      </a>
                      <a
                        className="dropdown-item text-dark"
                        href={process.env.REACT_APP_API_URL === "https://api.spritzer.app" ? "https://www.spritzer.app/my-orders" : "https://www-dev.spritzer.app/my-orders"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ lineHeight: "30px" }}
                      >
                        <i className="fas fa-receipt"></i> &nbsp; Orders
                      </a>

                      <hr />

                      <Link className="dropdown-item text-dark logout-link" to="#" onClick={this.handleLogout} style={{ lineHeight: "30px" }}>
                        <i className="fas fa-sign-out-alt"></i> &nbsp; Logout
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="hide-on-mobile" style={{ zIndex: 9999 }}>
                  {document.cookie.includes("auth_token") ? null : (
                    <span className="login-btn" data-toggle="modal" data-target="#login-sign-up-modal">
                      {/* <i className="fas fa-sign-in-alt"></i> */}
                      Sign in
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </nav>

        {this.props.handleOrderShedule ? <ManageOrderTimeOnResClosed handleOrderShedule={this.props.handleOrderShedule} /> : null}

        {this.state.redirectToUserDetail ? <Redirect to="/checkout/your-details" /> : null}

        <LoginRegisterPopup
          handleLogin={this.handleLogin}
          loadingProgress={this.state.loading}
          loadingProgressReg={this.state.loadingReg}
          loginFail={this.state.loginFail}
          emailNotVerified={this.state.emailNotVerified}
          showResendSuccess={this.state.showResendSuccess}
          showResendFail={this.state.showResendFail}
          loginSuccess={this.state.loginSuccess}
          handleRegister={this.handleRegister}
          registerSuccess={this.state.registerSuccess}
          resendEmailVerification={this.resendEmailVerification}
        />

        <ForgetPasswordModal />
      </React.Fragment>
    );
  }
}

export default withRouter(Navbar);
