import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import test from "./pages/test";
import MenuRedirect from "./pages/MenuRedirect";
import MenuItems from "./pages/MenuItems";
import Cart from "./pages/Cart";
import YourDetails from "./pages/YourDetails";
import Order from "./pages/Order";
import PageNotFound from "./pages/PageNotFound";
// import CardDetails from "./pages/CardDetails";
import StoreDetails from "./pages/StoreDetails";
import ReactNotifications from "react-notifications-component";
import ConfirmOrder from "./pages/ConfirmOrder";
import LocalPaymentRedirect from "./pages/LocalPaymentRedirect";
import ServerErrorPage from "../src/pages/ServerErrorPage";
import OrderStatus from "./pages/OrderStatus";
import "react-notifications-component/dist/theme.css";
// load isAuthenticated method
import { isAuthenticated, isAuthenticatedWithGuest } from "./auth/auth";
import Guest from "./pages/Guest";
import axios, { AxiosError } from "axios";
import { logErrors } from "./components/api/LogErrorsAPI";
import AppContext from "./context/AppContext";

// create a private route for customers
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const PrivateRouteWithGuest = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticatedWithGuest() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const ERROR_PAGES = Object.freeze({
  NotFound: "NotFound",
  ServerError: "ServerError",
  NoConnection: "NoConnection",
  AuthenticationError: "AuthenticationError",
});

function App() {
  const [errorPage, setErrorPage] = useState(undefined);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      logErrors(error);
      if (error instanceof AxiosError) {
        const { status: statusCode } = error.response;
        if (statusCode === 401) {
          setErrorPage(ERROR_PAGES.AuthenticationError);
        }
        if (statusCode === 404) {
          setErrorPage(ERROR_PAGES.NotFound);
        }
        if (statusCode >= 500) {
          setErrorPage(ERROR_PAGES.ServerError);
        }
      } else {
        setErrorPage(ERROR_PAGES.NoConnection);
      }
      return Promise.reject(error);
    }
  );

  const AppErrorPage = () => {
    if (errorPage === ERROR_PAGES.NotFound) {
      return <PageNotFound />;
    }
    if (errorPage === ERROR_PAGES.ServerError) {
      return <ServerErrorPage isNetworkError={false} />;
    }
    if (errorPage === ERROR_PAGES.NoConnection) {
      return <ServerErrorPage isNetworkError={true} />;
    }
    if (errorPage === ERROR_PAGES.AuthenticationError) {
      return <MenuItems />;
    }

    return false;
  };

  return (
    <AppContext.Provider value={{ errorPage, setErrorPage }}>
      <>
        <ReactNotifications />
        <Router>
          {errorPage === undefined ? (
            <Switch>
              <Route path="/" exact component={MenuItems} />
              <Route path="/order/:trackingid" exact component={OrderStatus} />
              <Route path="/cart" exact component={Cart} />
              <Route path="/guest" exact component={Guest} />
              <Route path="/store-details" exact component={StoreDetails} />
              <Route path="/order-complete" exact component={ConfirmOrder} />
              <PrivateRouteWithGuest
                path="/checkout/your-details/order"
                exact
                component={Order}
              />
              <PrivateRoute
                path="/checkout/your-details"
                exact
                component={YourDetails}
              />
              <Route path="/page-not-found" exact component={PageNotFound} />
              <Route
                path="/server-error-page"
                exact
                component={ServerErrorPage}
              />
              <Route path="/test/:id" exact component={test} />
              {/* <Route path="/:id" exact component={MenuRedirect} /> */}
              <Route
                path="/checkout/local-payment-redirect"
                exact
                component={LocalPaymentRedirect}
              />
              <Route path="*" component={PageNotFound} />
            </Switch>
          ) : (
            <AppErrorPage />
          )}
        </Router>
      </>
    </AppContext.Provider>
  );
}

export default App;
