import React, { useContext } from "react";
import "../styles/serverErrorStyles.css";
import AppContext from "../context/AppContext";

const restaurantData = localStorage.getItem("restaurant");
const restaurant = restaurantData !== undefined ? JSON.parse(restaurantData) : "undefined";

const styles = {
  wrapper: {
    background: restaurant?.branding?.colorAccent ?? " #f26666",
    width: "100vw",
    height: "100vh",
  },
};

const ServerErrorPage = ({ isNetworkError }) => {
  const networkErrorDescription = "Please check your Connection and try again.";
  const serverErrorDescription = `Internal Server Error`;
  const { setErrorPage } = useContext(AppContext);

  return (
    <div style={styles.wrapper}>
      <div className="centered-button">
        <h1 className="error-code">
          {isNetworkError ? "You’re Offline" : 500}
        </h1>
        <h1 className="error-description ">
          {isNetworkError ? networkErrorDescription : serverErrorDescription}
        </h1>
        <div className="main-container">
          <div
            className="reload-button"
            onClick={() => {
              setErrorPage(undefined);
            }}
          >
            {isNetworkError ? "Reload Page" : "Try Again"}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServerErrorPage;
