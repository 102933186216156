import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

const LocalPaymentRedirect = () => {
  const [orderData, setOrderData] = useState(null);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  useEffect(() => {
    const orderData = Buffer.from(query.get("order"), "base64").toString("utf8");

    const finalOrderData = JSON.parse(orderData);

    if (finalOrderData) {
      setOrderData(finalOrderData);
    } else {
      alert("No order data found");
    }
  }, []);

  useEffect(() => {
    if (orderData !== null && orderData.ipg === "payhere") {
      document.getElementById("pay-by-payhere").click();
    }
  }, [orderData]);

  return (
    <div>
      Please wait. We're processing your payment...
      <form
        id="ext-merchant-frm"
        action={process.env.REACT_APP_API_URL === "https://api-dev.spritzer.app" ? "https://sandbox.payhere.lk/pay/checkout" : "https://www.payhere.lk/pay/checkout"}
        method="post"
        acceptCharset="UTF-8"
        encType="application/x-www-form-urlencoded"
      >
        <input type="hidden" name="merchant_id" value={orderData?.payhereParams?.merchantId} />
        <input type="hidden" name="return_url" value={orderData?.payhereParams?.returnUrl} />
        <input type="hidden" name="cancel_url" value={orderData?.payhereParams?.cancelUrl} />
        <input type="hidden" name="notify_url" value={orderData?.payhereParams?.notifyUrl} />
        <input type="hidden" name="first_name" value={orderData?.payhereParams?.firstName} />
        <input type="hidden" name="last_name" value={orderData?.payhereParams?.lastName} />
        <input type="hidden" name="email" value={orderData?.payhereParams?.email} />
        <input type="hidden" name="phone" value={orderData?.payhereParams?.phone} />
        <input type="hidden" name="address" value={orderData?.payhereParams?.address} />
        <input type="hidden" name="city" value={orderData?.payhereParams?.city} />
        <input type="hidden" name="country" value={orderData?.payhereParams?.country} />
        <input type="hidden" name="hash" value={orderData?.payhereParams?.hash} />
        <input type="hidden" name="order_id" value={orderData?.payhereParams?.orderId} />
        <input type="hidden" name="items" value={orderData?.payhereParams?.items} />
        <input type="hidden" name="currency" value={orderData?.payhereParams?.currency} />
        <input type="hidden" name="amount" value={orderData?.payhereParams?.amount} />
        <button id="pay-by-payhere" hidden>
          Submit
        </button>
      </form>
    </div>
  );
};

export default LocalPaymentRedirect;
