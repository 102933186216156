import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";

import { removeCartItem } from "../../methods/CartMethods";

class CartItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      restName: "",
      tableName: "",
      currency: {}
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount = () => {
    let restData = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant"));

    let tempItem = localStorage.getItem(`cart_${restData.id}`) ? JSON.parse(localStorage.getItem(`cart_${restData.id}`)) : [];

    this.setState({
      extId: restData.extId,
      items: tempItem,
      restId: restData.id,
      restName: restData.name,
      currency: restData.currency
    });

    if (localStorage.getItem("orderType") === "dine_in" && localStorage.getItem("tableId")) {
      const table = restData.deliveryDetail.dineInSettings.tables.filter((filter) => filter.id === localStorage.getItem("tableId"));

      if (table[0]) {
        this.setState({ tableName: table[0].name });
      } else {
        this.setState({ tableName: "" });
      }
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  // Hide Popup When Clicks Outside
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.handleCartDisplayCloseFn();
    }
  }

  handleOnItemCountChange = (event, id) => {
    let cart = [];

    if (localStorage.getItem(`cart_${this.state.restId}`)) {
      cart = JSON.parse(localStorage.getItem(`cart_${this.state.restId}`));
    }

    cart.map((item, i) => {
      if (i === id) {
        cart[i].count = event.target.value;
      }
    });

    localStorage.setItem(`cart_${this.state.restId}`, JSON.stringify(cart));

    const updatedCart = JSON.parse(localStorage.getItem(`cart_${this.state.restId}`));
    this.setState({ items: updatedCart });
  };

  // remove items from cart
  handleRemoveCartItem = (id) => {
    // execute removeCartItem methord
    removeCartItem(id, this.state.restId);

    // update the cart
    const cart = JSON.parse(localStorage.getItem(`cart_${this.state.restId}`));
    this.setState({ items: cart });
    this.props.cartUpdate();
  };

  storeTotal = (e) => {
    e.preventDefault();
    if (this.state.items.length > 0) {
      const preOrderItems = this.state.items.filter((item) => item?.preOrderHours > 0);

      if (preOrderItems.length > 0) {
        if (localStorage.getItem("asap") == "false" && localStorage.getItem("expectedAt")) {
          this.setState({ matchMinOrder: true });
          this.props.history.push(`/cart`);
        } else {
          document.getElementById("schedule-order").click();
        }
      } else {
        if (localStorage.getItem("asap") == "true") {
          this.setState({ matchMinOrder: true });
          this.props.history.push(`/cart`);
        } else if (localStorage.getItem("asap") == "false" && localStorage.getItem("expectedAt")) {
          this.setState({ matchMinOrder: true });
          this.props.history.push(`/cart`);
        }
      }
    }
  };

  buildCountOptions(minOrderCount, maxOrderCount) {
    let options = [];
    for (let i = minOrderCount; i <= maxOrderCount; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    let filtered = options.filter(function (el) {
      return el != null;
    });
    return filtered;
  }

  // TODO: make utility and move into it
  getFormattedPrice = (price, currency) => {
    const symbol = this.getCurrencySymbol(currency);
    return `${symbol} ${price}`;
  }

  // TODO: make utility and move into it
  getCurrencySymbol = (currency) => {
    return currency.symbol || currency.code;
  };

  render() {
    let addonSubTotal = 0;
    this.state.items.map((item) => {
      const count = item.count;
      const addonTotal =
        item.addOns &&
        item.addOns.reduce((currentValue, nextValue) => {
          return currentValue + parseInt(nextValue.price);
        }, 0);
      addonSubTotal = addonSubTotal + addonTotal * count;
    });

    let portionSizeTotal = 0;
    this.state.items.map((item) => {
      const count = item.count;
      if (item.portionSize.price) {
        portionSizeTotal = portionSizeTotal + item.portionSize.price * count;
      } else {
        let price = item.displayPriceDiscounted ? item.displayPriceDiscounted : item.displayPrice;
        portionSizeTotal = portionSizeTotal + price * count;
      }
    });

    const subTotal = addonSubTotal + portionSizeTotal;

    return (
      <div className="cart-items-popup-display cart-main cus-con-for-all" ref={this.setWrapperRef}>
        <div className="close-cart" onClick={this.props.handleCartDisplayCloseFn}>
          <span aria-hidden="true">&times;</span>
        </div>
        <div className="container">
          <div className="cart-header">
            <div className="cart-main-title">
              <h5 className="cart-heading">Your {localStorage.getItem("orderType") === "delivery" ? "Delivery" : localStorage.getItem("orderType") === "pickup" ? "Pickup" : "Dine In"} order</h5>
            </div>
          </div>

          <div className="cart-sec popup">
            <div className="col p-0 cart-details-popup">
              <div>
                {localStorage.getItem("orderType") === "dine_in" && localStorage.getItem("tableId") ? (
                  <p className="deliver-from text-muted" style={{ fontSize: 14 }}>
                    Table : {this.state.tableName}
                  </p>
                ) : null}
                {localStorage.getItem("expectedAt") ? (
                  <p className="deliver-from text-muted" style={{ fontSize: 14 }}>
                    {localStorage.getItem("expectedAt") && localStorage.getItem("expectedAt")}
                  </p>
                ) : null}
                <hr />
                {!this.state.items.length ? <p className="text-danger empty-cart-notify">Cart is Empty</p> : null}
                <div className="cart-popup" style={{ overflowX: "auto" }}>
                  <div className="table item-table">
                    {this.state.items.map((cartItem, i) => (
                      <div className="item-table-item-details-main" key={i}>
                        <div className="item-table-item-details">
                          <div className="item-table-item-details-item-count">
                            <select
                              name="order-qty"
                              className="order-qty"
                              value={cartItem.count}
                              onChange={(e) => {
                                this.handleOnItemCountChange(e, i);
                              }}
                            >
                              {this.buildCountOptions(cartItem.minOrderQty, cartItem.maxOrderQty)}
                            </select>
                          </div>
                          <div className="item-table-item-details-item-name">{cartItem.name}</div>
                          <div className="item-table-item-details-item-price">
                            {`${this.getCurrencySymbol(this.state.currency)} `}
                            <NumberFormat
                              value={(
                                (cartItem.portionSize.price
                                  ? cartItem.portionSize.price * cartItem.count
                                  : cartItem.hasDiscount
                                  ? cartItem.displayPriceDiscounted * cartItem.count
                                  : cartItem.displayPrice * cartItem.count) +
                                (cartItem.addOns &&
                                  cartItem.addOns.reduce((currentValue, nextValue) => {
                                    return currentValue + parseInt(nextValue.price) * cartItem.count;
                                  }, 0))
                              ).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                            <div className="remove-cart-item">
                              <span onClick={() => this.handleRemoveCartItem(i)}>Remove Item</span>
                            </div>
                          </div>
                        </div>

                        <div className="portion-display-cart">
                          {cartItem.portionSize && cartItem.portionSize.price ? (
                            <div key={i} className="addons-display-cart-addon-item">
                              Portion Size: {cartItem.portionSize.name}
                            </div>
                          ) : null}
                        </div>

                        <div className="addons-display-cart">
                          {cartItem.addOns && cartItem.addOns.length > 0 ? <h6 className="addons-display-cart-title">Add-Ons</h6> : null}
                          {cartItem.addOns &&
                            cartItem.addOns.map((addon, i) => (
                              <div key={i} className="addons-display-cart-addon-item">
                                {addon.name}
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="cart-items-checkout-btn">
                <button
                  className="checkout mt-3"
                  onClick={this.storeTotal}
                  disabled={!this.state.items.length}
                  style={{
                    background:
                      localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                        ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                        : "#ff7068",
                  }}
                >
                  {`Continue to Checkout 
                    ${this.getFormattedPrice(subTotal.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      }), 
                      this.state.currency)
                    }`
                  }
                </button>
              </div>
            </div>
          </div>
        </div>

        <input type="hidden" id="schedule-order" data-toggle="modal" data-target="#schedulePreOrder" />
      </div>
    );
  }
}

export default withRouter(CartItems);
