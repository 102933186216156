import React, { Component } from "react";
import ScheduleTimes from "../menu/ScheduleTimes";
import moment from "moment";
import { getAvailableOrderScheduleTime } from "../api/CheckoutAPI";
import { withRouter } from "react-router-dom";
import "../../styles/home.css";

import { returnTimesInBetween } from "../../methods/getSchedule";

class ManageOrderTime extends Component {
  state = {
    listingId:
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant")).id,
    acceptDays: [],
    orderAcceptingHours: [],
    orderAcceptingHoursByDay: [],
    asap: false,
    expectedAt: "",
    orderDate: "",
    scheduleTime: "",

    delivery: false,
    pickup: false,
    dine_in: false,
    tableName: "",

    invalidTimeError: "",
    invalidDateError: "",
    isTimeAvailable: false,
  };

  componentDidMount = () => {
    this.initailFunc();
  };

  initailFunc = () => {
    if (localStorage.getItem("asap")) {
      this.setState({ asap: JSON.parse(localStorage.getItem("asap")) });
    } else {
      this.setState({ asap: true });
      localStorage.setItem("asap", true);
    }

    if (localStorage.getItem("expectedAt")) {
      const tempExpect = moment(localStorage.getItem("expectedAt")).format(
        "YYYY MM DD"
      );
      const tempExpectTime = moment(localStorage.getItem("expectedAt")).format(
        "HH:mm"
      );
      this.setState({
        expectedAt: localStorage.getItem("expectedAt"),
        orderDate: tempExpect,
        scheduleTime: tempExpectTime,
      });
    } else {
      localStorage.setItem("expectedAt", "");
    }

    if (
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant")).deliveryDetail &&
      JSON.parse(localStorage.getItem("restaurant")).deliveryDetail
        .orderAcceptingHours
    ) {
      this.setState({
        orderAcceptingHours: localStorage.getItem("restaurant")
          ? JSON.parse(localStorage.getItem("restaurant")).deliveryDetail
            ? JSON.parse(localStorage.getItem("restaurant")).deliveryDetail
                .orderAcceptingHours
            : []
          : [],
      });
    }

    let acceptDays = [];
    let day;

    for (let i = 0; i < 14; i++) {
      day = moment()
        .add(`${[i]}`, "days")
        .calendar("YYYY/MM/DD");
      acceptDays = [...acceptDays, day];
    }

    this.setState({ acceptDays });

    // get table name
    this.getTableName();

    // Revert schedule time to ASAP for older schedule
    const currentDateTime = moment().format("YYYY-MM-DD HH:mm");
    const expectedDateTime = moment(localStorage.getItem("expectedAt")).format("YYYY-MM-DD HH:mm");

    if (moment(expectedDateTime).isBefore(currentDateTime)) {
      this.selectASAP();
    }
  };

  getTableName = () => {
    let restData =
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant"));

    if (
      localStorage.getItem("orderType") === "dine_in" &&
      localStorage.getItem("tableId")
    ) {
      const table = restData.deliveryDetail.dineInSettings.tables.filter(
        (filter) => filter.id === localStorage.getItem("tableId")
      );

      if (table[0]) {
        this.setState({ tableName: table[0].name });
      } else {
        this.setState({ tableName: "" });
      }
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.expectedAt !== undefined &&
      nextProps.expectedAt !== prevState.expectedAt
    ) {
      return { expectedAt: nextProps.expectedAt };
    }
    return null;
  }

  selectASAP = () => {
    this.setState({
      expectedAt: "",
      asap: true,
    });

    localStorage.setItem("expectedAt", "");
    localStorage.setItem("asap", true);
  };

  schedule = () => {
    if (this.state.orderDate && this.state.scheduleTime) {
      if (this.state.invalidTimeError === "") {
        let expectedAt = `${this.state.orderDate} ${this.state.scheduleTime}:00.0`;
        expectedAt = moment(expectedAt).format("YYYY-MM-DD HH:mm:ss.0");

        this.setState({
          expectedAt: expectedAt,
          asap: false,
        });

        localStorage.setItem("expectedAt", expectedAt);
        localStorage.setItem("asap", false);
        if (this.props.handleOrderShedule) {
          this.props.handleOrderShedule(expectedAt);
        }
        if (this.props.rerenderParentCallback) {
          this.props.rerenderParentCallback();
        }
        document.getElementById("schedule").click();
      }
    } else {
      this.setState({
        invalidTimeError: "Please fill all fields",
      });
    }
  };

  handleScheduleTime = (e) => {
    if (this.state.orderDate !== "") {
      this.setState({ scheduleTime: e.target.value });

      let expectedAt = `${this.state.orderDate} ${e.target.value}:00.0`;
      expectedAt = moment(expectedAt).format("YYYY-MM-DD HH:mm:ss.0");

      const listingId = this.state.listingId ? this.state.listingId : localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).id

      getAvailableOrderScheduleTime(
        expectedAt,
        listingId,
        (data) => {
          if (data.value === true) {
            this.setState({ invalidTimeError: "", isTimeAvailable: true });
          } else {
            this.setState({
              invalidTimeError: "The restaurant is not accepting given time",
              isTimeAvailable: false,
            });
          }
        },
        (error) => {
          console.log(error);
          this.setState({
            invalidTimeError: `The restaurant is not accepting given date and/or time. Error code: ${error}`,
            isTimeAvailable: false,
          });
        }
      );
    } else {
      this.setState({
        invalidDateError: "Please select a day first",
        isTimeAvailable: false,
      });
    }
  };

  // handle inputs
  handleChange = (e) => {
    const day = moment(e.target.value).format("dddd");

    const findDay =
      this.state.orderAcceptingHours.length > 0 &&
      this.state.orderAcceptingHours.filter((el) => el.day === day);

    if (findDay.length > 0) {
      let orderAcceptingHoursByDay = [];

      findDay.forEach((el) => {
        orderAcceptingHoursByDay = orderAcceptingHoursByDay.concat(
          returnTimesInBetween(el.openTime, el.closeTime)
        );
      });

      const currentTime = moment().format("HH:mm");
      const currentDate = moment().format("YYYY-MM-DD");
      const dropdownSelectedDate = moment(e.target.value).format("YYYY-MM-DD");
    
      if (moment(currentDate).isSame(dropdownSelectedDate)) {
        orderAcceptingHoursByDay = orderAcceptingHoursByDay.filter((item) => moment(item.format24,"HH:mm").isAfter(moment(currentTime,"HH:mm")) ? item : null);
      }

      this.setState({ orderAcceptingHoursByDay });
    } else {
      this.setState({ orderAcceptingHoursByDay: [] });
    }

    this.setState({
      [e.target.name]: e.target.value,
      scheduleTime: "",
      isTimeAvailable: false,
      invalidDateError: "",
      invalidTimeError: "",
    });
  };

  returnTableName = () => {
    let restData =
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant"));

    if (
      localStorage.getItem("orderType") === "dine_in" &&
      localStorage.getItem("tableId")
    ) {
      const table = restData.deliveryDetail.dineInSettings.tables.filter(
        (filter) => filter.id === localStorage.getItem("tableId")
      );

      if (table[0]) {
        return table[0].name;
      } else {
        return "";
      }
    }
  };

  isClosed = (day) => {
    if (this.state.orderAcceptingHours.length > 0) {
      const isDays = this.state.orderAcceptingHours.filter(
        (el) => el.day === moment(day).format("dddd")
      );

      if (isDays.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  render() {
    return (
      <div>
        <div className="btn-group delivery-pickup-dine-select">
          {localStorage.getItem("orderType") &&
          localStorage.getItem("orderType") === "dine_in" ? (
            `Table: ${this.returnTableName()}`
          ) : (
            <>
              <span
                className="dropdown-toggle order-time-change-inner"
                data-toggle={"dropdown"}
                aria-haspopup="true"
                aria-expanded="false"
              >
                {(this.state.asap === false &&
                  localStorage.getItem("restaurant") &&
                  JSON.parse(localStorage.getItem("restaurant")).deliveryDetail
                    .isAvailable === false &&
                  this.state.expectedAt !== "") ||
                (this.state.asap === false &&
                  localStorage.getItem("restaurant") &&
                  JSON.parse(localStorage.getItem("restaurant")).deliveryDetail
                    .isAvailable === true &&
                  this.state.expectedAt !== "") ? (
                  <i className="fas fa-calendar-alt pr-2"></i>
                ) : this.state.asap === false &&
                  localStorage.getItem("restaurant") &&
                  JSON.parse(localStorage.getItem("restaurant")).deliveryDetail
                    .isAvailable === false &&
                  this.state.expectedAt === "" ? (
                  <i className="fas fa-calendar-alt pr-2"></i>
                ) : (
                  <i className="fas fa-clock"></i>
                )}

                {(this.state.asap === false &&
                  localStorage.getItem("restaurant") &&
                  JSON.parse(localStorage.getItem("restaurant")).deliveryDetail
                    .isAvailable === false &&
                  this.state.expectedAt !== "") ||
                (this.state.asap === false &&
                  localStorage.getItem("restaurant") &&
                  JSON.parse(localStorage.getItem("restaurant")).deliveryDetail
                    .isAvailable === true &&
                  this.state.expectedAt !== "")
                  ? moment.utc(this.state.expectedAt).format("llll")
                  : this.state.asap === false &&
                    localStorage.getItem("restaurant") &&
                    JSON.parse(localStorage.getItem("restaurant"))
                      .deliveryDetail.isAvailable === false &&
                    this.state.expectedAt === ""
                  ? "Schedule an Order"
                  : "ASAP"}
              </span>

              <div
                className="dropdown-menu"
                style={{ zIndex: 9999, fontSize: 13 }}
                >
                {localStorage.getItem("restaurant") &&
                JSON.parse(localStorage.getItem("restaurant")).deliveryDetail
                  .isAvailable === true ? (
                  <p onClick={this.selectASAP}>
                    <i className="far fa-clock"></i>&nbsp; &nbsp; ASAP
                  </p>
                ) : null}
                {JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.allowScheduled === true ? 
                  (<p data-toggle="modal" data-target="#schedule">
                    <i className="fas fa-calendar-alt"></i> &nbsp; &nbsp; Schedule
                  </p> ): null }
              </div> 
            </>
          )}
        </div>

        {/* Schedule Popup */}
        <div
          className="modal fade"
          id="schedule"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered date-time-picker-modal"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="m-0">Pick a time</h6>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="date-time-modal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body p-0">
                <div className="form-group p-0 mt-4 ">
                  <p
                    style={{
                      fontSize: "80%",
                      color: "#dc3545",
                      margin: "8px 0 8px 0",
                      textAlign: "center",
                    }}
                  >
                    {this.state.invalidDateError}
                  </p>
                  <select
                    className="form-control custom-select schedule-date"
                    name="orderDate"
                    value={this.state.orderDate}
                    onChange={this.handleChange}
                  >
                    <option value="" selected hidden>
                      - Select a Day -
                    </option>
                    {this.state.acceptDays &&
                      this.state.acceptDays.map((day, i) => (
                        <option
                          key={i}
                          value={moment(day).format("YYYY-MM-DD")}
                          disabled={this.isClosed(day)}
                        >
                          {moment(day).format("dddd - DD MMM")}{" "}
                          {this.isClosed(day) && " - Close"}
                        </option>
                      ))}
                  </select>

                  <select
                    className={`form-control custom-select mt-3 schedule-date ${
                      this.state.orderDate === ""
                        ? "disabled-select-option"
                        : ""
                    }`}
                    name="scheduleTime"
                    value={this.state.scheduleTime}
                    onChange={this.handleScheduleTime}
                  >
                    <option selected hidden value="">
                      - Select a Time -
                    </option>
                    {this.state.orderAcceptingHoursByDay.length > 0 ? (
                      this.state.orderAcceptingHoursByDay.map((el, i) => (
                        <option value={el.format24} key={i}>
                          {el.format12}
                        </option>
                      ))
                    ) : (
                      <ScheduleTimes />
                    )}
                  </select>

                  <p
                    style={{
                      width: "80%",
                      fontSize: "80%",
                      color: "#dc3545",
                      margin: "auto",
                      marginTop: 5,
                    }}
                  >
                    {this.state.invalidTimeError}
                  </p>
                </div>

                <div className="text-center">
                  <button
                    className="schedule-btn"
                    onClick={this.schedule}
                    disabled={this.state.isTimeAvailable === false}
                    style={{
                      background:
                        localStorage.getItem("restaurant") &&
                        JSON.parse(localStorage.getItem("restaurant"))
                          .branding &&
                        JSON.parse(localStorage.getItem("restaurant")).branding
                          .colorAccent
                          ? JSON.parse(localStorage.getItem("restaurant"))
                              .branding.colorAccent
                          : "#f26666",
                    }}
                  >
                    Schedule
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ManageOrderTime);
