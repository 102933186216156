import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { continueWithEmail } from "../api/UserDetailsAPI";
import "../../styles/LoginSignup.css";
import { successAlert, warningAlert } from "../../methods/Alert";

const initialState = {
  // login
  email: "",

  emailError: "",

  // alerts
  registerSuccess: false,
  loginSuccess: false,
  loginFail: false,
  emailNotVerified: false,
  showResendSuccess: false,
  showResendFail: false,

  // loading
  loading: false,
  loadingReg: false,
};

class EmailContinue extends Component {
  state = initialState;

  // handle inputs
  handleEmailChange = (e) => {
    this.setState({ [e.target.name]: e.target.value.trim() });
};

  // validate
  validate = () => {
    let emailError = "";
    let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (this.state.email === "") {
      emailError = "Email is required";
    } else if (!this.state.email.match(mailformat)) {
      emailError = "You have entered an invalid email address!";
    }

    if (emailError) {
      this.setState({
        emailError,
      });

      return false;
    }
    return true;
  };

  // login
  handleLogin = (e) => {
    e.preventDefault();

    if (this.validate()) {
      this.setState({
        loginSuccess: false,
        loginFail: false,
        emailNotVerified: false,
        showResendSuccess: false,
        showResendFail: false,
      });

      // set loading
      this.setState({ loading: true, loginSuccess: false });

      const loginData = this.state.email;

      continueWithEmail(
        loginData,
        (data) => {
          if (data.is_exists === true) {
            localStorage.setItem("userEmail", JSON.stringify(loginData));
            this.props.setCurrentUserEmail(loginData);
            this.props.handleEmailScreenChange();
            successAlert("Please enter your password to login.");
          }
          this.setState({
            email: "",
            loading: false,
            loginSuccess: true,
            loginFail: false,
          });
        },

        (error) => {
          if (error.is_exists === false) {
            this.props.setCurrentUserEmail(loginData);
            this.props.handleLoginScreenChange();
            warningAlert("You are not registered. Please register now.");
          } else {
            console.log(error);
            this.setState({
              loading: false,
              loginFail: true,
              loginSuccess: false,
            });
          }
        }
      );
    }
  };

  handleGuest = () => {
    let now = new Date();
    let time = now.getTime();
    time += 3600 * 1000;
    now.setTime(time);
    document.cookie =
      "guest_token=" +
      "guest" +
      time +
      "; expires=" +
      now.toUTCString() +
      "; path=/";
    this.props.history.push("/guest");
    document.getElementById("login-sign-up-modal-close").click();
  };

  render() {
    return (
      <div className="login-main" ref={this.props.containerRef}>
        <div>
          {this.state.emailNotVerified ? (
            <React.Fragment>
              <p className="login-notify">
                Your email has not been verified. Please check your inbox for
                the verification email or click "Resend" to send it again.
              </p>

              <buttonc
                type="button"
                className="resend-btn"
                onClick={this.resendEmailVerification}
              >
                Resend
              </buttonc>
            </React.Fragment>
          ) : null}

          <form noValidate className="login-reg-form">
            <div className="form-group">
              <label htmlFor="con-email">
                {this.props.history.location.pathname === "/cart"
                  ? "Enter Email to Continue"
                  : "Email"}
              </label>
              <input
                type="text"
                className={classnames("form-control", {
                  "is-invalid": this.state.emailError,
                })}
                id="con-email"
                name="email"
                onChange={this.handleEmailChange}
                value={this.state.email}
                required
              />
              <div className="invalid-feedback">{this.state.emailError}</div>
            </div>

            <div
              className="login-or-reg-buttons"
              style={{ display: "block", marginTop: 15 }}
            >
              <div className="login-or-reg-button"></div>
              <div className="login-or-reg-button">
                <button
                  className="btn"
                  onClick={this.handleLogin}
                  style={{
                    background:
                      localStorage.getItem("restaurant") &&
                      JSON.parse(localStorage.getItem("restaurant")).branding &&
                      JSON.parse(localStorage.getItem("restaurant")).branding
                        .colorAccent
                        ? JSON.parse(localStorage.getItem("restaurant"))
                            .branding.colorAccent
                        : "#ff7068",
                  }}
                >
                  {this.state.loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span className="sr-only">Continuing...</span>
                    </div>
                  ) : null}
                  {this.state.loading ? (
                    " Continuing..."
                  ) : (
                    <span> Continue</span>
                  )}
                </button>
              </div>
            </div>

            {this.props.history.location.pathname === "/cart" ? (
              <>
                <div className="login-or-text">
                  <span></span> <span>OR</span> <span></span>
                </div>
                <div className="login-or-reg-button">
                  <button
                    type="button"
                    className="btn"
                    onClick={this.handleGuest}
                    style={{
                      background:
                        localStorage.getItem("restaurant") &&
                        JSON.parse(localStorage.getItem("restaurant"))
                          .branding &&
                        JSON.parse(localStorage.getItem("restaurant")).branding
                          .colorAccent
                          ? JSON.parse(localStorage.getItem("restaurant"))
                              .branding.colorAccent
                          : "#ff7068",
                    }}
                  >
                    Continue as a Guest
                  </button>
                </div>
              </>
            ) : null}
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(EmailContinue);
