import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class PageNotFound extends Component {
  render() {
    return (
      <div
        style={{
          background:
            localStorage.getItem("restaurant") &&
            JSON.parse(localStorage.getItem("restaurant")).branding &&
            JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
              ? JSON.parse(localStorage.getItem("restaurant")).branding
                  .colorAccent
              : "#f26666",
          width: "100vw",
          height: "100vh",
        }}
      >
        <div style={div}>
          <h1 style={fourZeroFour}>404</h1>
          <h1 style={pageNotFound}>Page Not Found</h1>
            <Link style={homeLink} to={`/`}>
              Home
            </Link>
        </div>
      </div>
    );
  }
}

const div = {
  width: "320px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const fourZeroFour = {
  color: "#fff",
  textAlign: "center",
  fontSize: "86px",
  fontWeight: 900,
  letterSpacing: "8px",
};

const pageNotFound = {
  color: "#fff",
  textAlign: "center",
};

const homeLink = {
  display: "block",
  margin: "auto",
  marginTop: 20,
  width: 100,
  color: "#fff",
  textAlign: "center",
  border: "1.5px solid",
  padding: 5,
};
