import React, { Component } from "react";
import Checkout from "../components/cart/Checkout";
import TitleOfPage from "../components/TitleOfPage";

import "../styles/order.css";

export default class Order extends Component {
  state = {
    restName:
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant")).name,
  };
  render() {
    return (
      <React.Fragment>
        <TitleOfPage title={`Order | ${this.state.restName}`} />
        <Checkout />
      </React.Fragment>
    );
  }
}
