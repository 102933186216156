import axios from "axios";

export const getListingAPI = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v2/listings/${localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).id}`)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data);
    });
};
