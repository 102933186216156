import React, { Component } from "react";
import classnames from "classnames";

import "../../styles/LoginSignup.css";

const initialState = {
    // login
    email: "",
    password: "",

    emailError: "",
    passwordError: "",

    // loading
    loading: false,
};

class Login extends Component {
    state = initialState;

    componentDidMount() {
        this.setState({
            email: this.props.currentUserEmail
        });

        const backgroundColor = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent;
        document.documentElement.style.setProperty("--main-background-color", backgroundColor);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.loading !== nextProps.loadingProgress) {
            return {
                loading: nextProps.loadingProgress
            };
        }
    }

    // handle inputs
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleEmailChange = (e) => {
        this.setState({ [e.target.name]: e.target.value.trim() });
    };

    // validate
    validate = () => {
        let emailError = "";
        let passwordError = "";

        let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (this.state.email === "") {
            emailError = "Email is required";
        } else if (!this.state.email.match(mailformat)) {
            emailError = "You have entered an invalid email address!";
        } else {
            emailError = "";
        }

        if (this.state.password === "") {
            passwordError = "Password is required";
        } else {
            passwordError = "";
        }

        if (emailError || passwordError) {
            this.setState({
                emailError,
                passwordError,
            });
            return false;
        }
        return true;
    };

    // login
    handleLogin = (e) => {
        e.preventDefault();

        if (this.validate()) {
            this.props.handleLoginFn(e, this.state.email, this.state.password);
        }
    };

    render() {
        return (
            <div className="login-main" ref={this.props.containerRef}>
                <div>
                    <form noValidate className="login-reg-form">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="log-email"
                                className={classnames("form-control", {
                                    "is-invalid": this.state.emailError,
                                })}
                                id="log-email"
                                name="email"
                                onChange={this.handleEmailChange}
                                value={this.state.email}
                            />
                            <div className="invalid-feedback">
                                {this.state.emailError}
                            </div>
                        </div>

                        <div className="form-group p-0 mb-0">
                            <label htmlFor="pwd">Password</label>
                            <input
                                type="password"
                                className={classnames("form-control", {
                                    "is-invalid": this.state.passwordError,
                                })}
                                id="pwd"
                                name="password"
                                onChange={this.handleChange}
                                value={this.state.password}
                            />
                            <div className="invalid-feedback">
                                {this.state.passwordError}
                            </div>
                        </div>

                        <div className="login-or-reg-buttons">
                            <div className="login-or-reg-button">
                                <h6 className="login-or-reg-text-btn" onClick={() => this.props.handleLoginScreenChange()}>Use a different email</h6>
                            </div>
                            <div className="login-or-reg-button">
                                <button
                                    type="submit"
                                    className="btn"
                                    onClick={this.handleLogin}
                                >
                                    {this.state.loading ? (
                                        <div
                                            className="spinner-border spinner-border-sm text-light"
                                            role="status"
                                        >
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) : null}
                                    {this.state.loading ? (
                                        " Logging..."
                                    ) : (
                                            <span> Login</span>
                                        )}
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="forgot-password-btn">
                        <p data-toggle="modal" data-target="#forgetpasswordModel" onClick={() => document.getElementById("login-sign-up-modal").click()} >
                            Forgot your password?
                      </p>
                    </div>
                </div>

            </div>
        );
    }
}

export default Login
