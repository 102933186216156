import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Lines } from "react-preloaders";

import Navbar from "../components/menu/Navbar";
import HeaderBanner from "../components/menu/HeaderBanner";
import Footer from "../components/menu/Footer";
import TitleOfPage from "../components/TitleOfPage";

import { addToCart,clearCart } from "../methods/CartMethods";
import { getAddOns } from "../components/api/AddOnsAPI";
import { getAvailableOrderScheduleTime } from "../components/api/CheckoutAPI";
import { successAlert } from "../methods/Alert";
import "../styles/menu.css";
import AppContext, { ERROR_PAGES } from "../context/AppContext";

export default class test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listingId: "",
      categories: [],
      menu: [],
      restDetails: null,
      restDeliveryDetails: null,
      currency: {},
      // pre loading
      loading: true,
      redirectToRestaurants: false,
      adingItem: [],
      itemCount: 1,
      preOrderHours: 0,
      menuAddOns: [],
      addonFields: [],
      addonErrors: {},
      addonsChecked: {},
      maxSelections: "",
      selectedPortionSize: {},
      portionSizeError: "",
      scheduleOrder: false,
      expectedAt: "",
      toggleRerender: false,
      loadingAddOns: false,
      loaded: false,
      isMenuActive : "",
      hasSchedule : true
    };
  }

  static contextType = AppContext;

  componentDidMount = () => {
    const cartVersion = 12;

    // get listing id
    axios
      .get(`${process.env.REACT_APP_API_URL}/v2/listings/v/${this.props.match.params.id}`)
      .then((listing) => {
        if (listing.data.customDomain) {
          if (window.location.hostname !== listing.data.customDomain) {
            const queryString = new URLSearchParams(window.location.search);
            if (queryString.get("table")) {
              window.location.replace(`https://${listing.data.customDomain}?table=${queryString.get("table")}`);
            } else {
              window.location.replace(`https://${listing.data.customDomain}`);
            }
          }
        }

        this.setState({
          extId: listing.data.extId,
          listingId: listing.data.id,
          restDeliveryDetails: listing.data.deliveryDetail,
          restDetails: listing.data,
          currency: listing.data.currency,
          hasSchedule : listing.data.deliveryDetail.allowScheduled
        });

        if(listing.data.deliveryDetail.isAvailable === false && this.state.hasSchedule === false) {
          this.setState({isMenuActive:"disabled"});
        }

        localStorage.setItem("restaurant", JSON.stringify(listing.data));
        localStorage.removeItem(`cart${listing.data.id}`);

        if (localStorage.getItem("cart_version") != cartVersion) {
          localStorage.setItem(`cart_${listing.data.id}`, []);
          localStorage.setItem("cart_version", cartVersion);
        }
        if (localStorage.getItem("cart_version") === null) {
          localStorage.setItem(`cart_${listing.data.id}`, []);
          localStorage.setItem("cart_version", cartVersion);
        }

        if (listing.data.deliveryDetail.isAvailable === false) {
          this.setState({ scheduleOrder: true });
          localStorage.setItem("asap", false);
        } else {
          if (localStorage.getItem("expectedAt") == "") {
            this.setState({ scheduleOrder: false });
            localStorage.setItem("asap", true);
          } else {
            this.setState({ scheduleOrder: true });
            localStorage.setItem("asap", false);
          }
        }

        //clear cart - when scheduling disabled 
        if(JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.allowScheduled === false){
          clearCart(listing.data.id);
        }

        let expectedTime = localStorage.getItem("expectedAt");

        if (expectedTime) {
          const expectedAt = moment(expectedTime).format("YYYY-MM-DD HH:mm:ss.0");
          getAvailableOrderScheduleTime(
            expectedAt,
            listing.data.id,
            (data) => {
              if (data.value === true) {
                if (listing.data.deliveryDetail.isAvailable === false) {
                  this.setState({ expectedAt: localStorage.getItem("expectedAt") });
                } else {
                  if (localStorage.getItem("asap") == "false") {
                    this.setState({ expectedAt: localStorage.getItem("expectedAt") });
                  }
                }
              } else {
                console.log(localStorage.getItem("expectedAt"));
                this.setState({ expectedAt: "" });
                localStorage.setItem("expectedAt", "");
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }

        const cartItems = localStorage.getItem(`cart_${listing.data.id}`) ? JSON.parse(localStorage.getItem(`cart_${listing.data.id}`)) : [];
        const maxPreorderHours = cartItems.reduce((prev, current) => {
          return prev.preOrderHours > current.preOrderHours ? prev : current;
        }, 0);

        this.setState({ preOrderHours: maxPreorderHours?.preOrderHours ? maxPreorderHours?.preOrderHours : 0 });

        // get menu
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/listings/${listing.data.id}/menu_items`)
          .then((res) => {
            const uniqueCategories = [...new Set(res.data.map((filter) => filter.category.id))];

            // get categories
            axios
              .get(`${process.env.REACT_APP_API_URL}/v2/listings/${listing.data.id}/menu_categories`)
              .then((res) => {
                let categories = [];

                uniqueCategories.map((cat, i) => {
                  var filterCategory = res.data.filter((filter) => {
                    return cat === filter.id;
                  });

                  categories = [...categories, filterCategory[0]];
                  categories = categories.filter((element) => element !== undefined);

                  this.setState({
                    categories: categories,
                  });
                });
              })
              .catch((err) => console.log(err));

            res.data.forEach((portion) => {
              if (portion.portionSizes && portion.portionSizes.length) {
                let lowestPortionSize = portion.portionSizes.reduce(function (prev, curr) {
                  return prev.price < curr.price ? prev : curr;
                });
                lowestPortionSize["lowest"] = true;
              }
            });

            this.setState({
              menu: res.data,
              loading: false,
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        const{ status: statusCode } = error.response
        const{ code: codeId } = error.response.data

        const { setErrorPage } = this.context;
        if (statusCode === 400 && codeId === 1012) {
          setErrorPage(ERROR_PAGES.NotFound);
        }
       });

    // set tableNo
    const queryString = new URLSearchParams(window.location.search);

    if (queryString.get("table")) {
      localStorage.setItem("tableId", queryString.get("table"));
    }

    // scroll filters
    window.addEventListener("scroll", (e) => {
      let filterLinks = document.querySelectorAll(".filter-bar .filter-badge");
      let fromTop = window.scrollY + 100;

      filterLinks.forEach((link) => {
        let section = document.querySelector(`${link.hash}-Category`);

        if (section.offsetTop <= fromTop && section.offsetTop + section.offsetHeight > fromTop) {
          link.classList.add("active-filter");
        } else {
          link.classList.remove("active-filter");
        }
      });
    });
    this.handleMinimumOrder();
    localStorage.removeItem("guest_checkout");
  };

  // add to cart
  handleAddToCart = () => {
    if (this.handleValidation()) {
      let menuItem = this.state.adingItem;

      let data = [];
      menuItem.forEach((item) => {
        const maxOrder = item.maxOrderQty > 0 ? item.maxOrderQty : (item.maxOrderQty = 20);
        const minOrder = item.minOrderQty > 0 ? item.minOrderQty : (item.minOrderQty = 1);
        item.maxOrderQty = maxOrder;
        item.minOrderQty = minOrder;
        item.count = this.state.itemCount;
        data.push(item);

        if (item.preOrderHours > 0) {
          this.setState((prevstate) => ({ preOrderHours: prevstate.preOrderHours < item.preOrderHours ? item.preOrderHours : prevstate.preOrderHours }));

          if (localStorage.getItem("expectedAt")) {
            const expectedAtDate = new Date(localStorage.getItem("expectedAt"));
            const today = new Date();
            const hoursDifference = Math.round(Math.abs(expectedAtDate - today) / 36e5);
            if (hoursDifference < item.preOrderHours) {
              localStorage.setItem("expectedAt", "");
            }
          }
        }
      });

      addToCart(data[0], this.state.restDetails.id, this.state.addonFields, this.state.selectedPortionSize, () => {
        this.setState({
          adingItem: [],
          itemCount: 1,
          selectedPortionSize: {},
        });
        successAlert("Item has been added successfully!");
      });

      const total = this.state.adingItem.reduce((currentValue, nextValue) => {
        return currentValue + this.state.itemCount * nextValue.price;
      }, 0);

      let addOnItems = [];
      for (const group of this.state.addonFields) {
        addOnItems.push(...group.items);
      }

      const addonTotal = addOnItems.reduce((currentValue, nextValue) => {
        return currentValue + parseInt(nextValue.price);
      }, 0);

      const subTotal = total + addonTotal;

      localStorage.setItem(`cartSubTotal`, JSON.stringify(subTotal));
      this.setState({
        addonsChecked: {},
        maxSelections: "",
        adingItem: [],
      });

      let menus = [];
      for (const item of this.state.menu) {
        if (item.addOns) {
          delete item.addOns;
          menus.push(item);
        } else {
          menus.push(item);
        }
      }

      this.setState({ menu: menus, addonFields: [] });
      document.getElementById("menu-item-details-modal-close").click();
    }
  };

  decrementItemCount = () => {
    if (this.state.itemCount !== 1) {
      if (this.state.adingItem[0].minOrderQty < this.state.itemCount) {
        this.setState((prevState) => ({ itemCount: prevState.itemCount - 1 }));
      }
    }
  };

  incrementItemCount = () => {
    if (this.state.adingItem[0].maxOrderQty === 0 || this.state.adingItem[0].maxOrderQty > this.state.itemCount) {
      this.setState((prevState) => ({ itemCount: prevState.itemCount + 1 }));
    }
  };

  handleGetAddOn = (itemId) => {
    this.setState({
      loaded: false,
      loadingAddOns: true,
      menuAddOns: [],
      addonFields: [],
      selectedPortionSize: {},
      portionSizeError: "",
      addonErrors: {},
    });

    getAddOns(
      this.state.listingId,
      itemId,
      (data) => {
        this.setState({
          menuAddOns: data.data,
          addonsChecked: {},
          loadingAddOns: false,
          loaded: true,
        });
        this.state.adingItem[0].portionSizes.forEach((item) => {
          document.getElementById(item.id).checked = false;
        });

        if (this.state.adingItem[0].portionSizes.length) {
          let lowestPortionSize = this.state.adingItem[0].portionSizes.reduce(function (prev, curr) {
            return prev.price < curr.price ? prev : curr;
          });

          let portionItem = {
            name: lowestPortionSize.name,
            price: lowestPortionSize.hasDiscount ? lowestPortionSize.calculatedDiscountPrice : lowestPortionSize.calculatedPrice,
            id: lowestPortionSize.id,
          };

          this.setState({ selectedPortionSize: portionItem });
          document.getElementById(lowestPortionSize.id).checked = true;
        }
      },
      (error) => {
        console.log(error);
        this.setState({ addonsChecked: {}, loadingAddOns: false });
      }
    );
  };

  handleOnSingleChange = (maxSelections, isRequired, groupId, itemId, price, e) => {
    let allAddons = this.state.addonFields.filter((item) => item.groupId === groupId).find((item) => item);
    let addonsState = this.state.addonFields;

    if (allAddons) {
      let addon = {
        name: e.target.name + ": " + e.target.value,
        price: price,
        id: itemId,
      };
      let addonsState = this.state.addonFields;
      const stateObjIdx = addonsState.map((ew) => ew.groupId).indexOf(groupId);
      addonsState[stateObjIdx].items = [addon];
      this.setState({ addonFields: addonsState });
    } else {
      let addons = {
        groupId,
        maxSelections,
        isRequired,
        items: [],
      };
      const addon = { name: e.target.name + ": " + e.target.value };
      addon["price"] = price;
      addon["id"] = itemId;
      addons.items.push(addon);
      addonsState.push(addons);
      this.setState({ addonFields: addonsState });
    }
  };

  handleOnPortionSizeChange = (itemId, price, e) => {
    let portionItem = {
      name: e.target.value,
      price: price,
      id: itemId,
    };
    this.setState({ selectedPortionSize: portionItem });
  };

  handleOnMultipleChange = (maxSelections, isRequired, addonGroupId, itemId, addonName, e, index) => {
    if (e.target.checked) {
      let item = {};
      const addon = { name: addonName };
      addon["price"] = e.target.value;
      addon["id"] = itemId;
      item = addon;

      let allAddons = this.state.addonFields.filter((item) => item.groupId === addonGroupId).find((item) => item);

      if (allAddons) {
        let addonGroup = this.state.addonFields.filter((item) => item.groupId === addonGroupId);
        let addonsState = this.state.addonFields;
        addonGroup[0].items.push(item);

        const stateObjIdx = addonsState.map((item) => item.groupId).indexOf(addonGroupId);
        addonsState[stateObjIdx] = allAddons;

        this.setState({ addonFields: addonsState });
      } else {
        let addonsState = this.state.addonFields;
        let addons = {
          groupId: addonGroupId,
          maxSelections,
          isRequired,
          items: [],
        };
        addons.items.push(item);
        addonsState.push(addons);
        this.setState({ addonFields: addonsState });
      }
      this.setState((previousState) => ({
        addonsChecked: {
          ...previousState.addonsChecked,
          [index]: !previousState.addonsChecked[index],
        },
      }));
      this.setState({ maxSelections: maxSelections });
    } else {
      let addons = this.state.addonFields.filter((item) => item.groupId === addonGroupId);
      const deleteObj = addons[0].items.filter((ev) => ev.name !== addonName);
      let addonsState = this.state.addonFields;
      const stateObjIdx = addonsState.map((gr) => gr.groupId).indexOf(addonGroupId);
      addonsState[stateObjIdx].items = deleteObj;

      this.setState((previousState) => ({
        addonsChecked: {
          ...previousState.addonsChecked,
          [index]: !previousState.addonsChecked[index],
        },
      }));

      this.setState({
        addonFields: addonsState,
        maxSelections: "",
      });
    }
  };

  handleValidation = () => {
    let selectedAddOns = this.state.addonFields;
    let errors = {};
    let formIsValid = true;

    let allAddons = this.state.menuAddOns;
    const isRequiredAvailable = allAddons.filter((group) => group.isRequired === true);
    const isRequiredCheck = selectedAddOns.map((group) => group.groupId);
    const check = isRequiredAvailable.every((group) => isRequiredCheck.includes(group.id));

    if (this.state.adingItem[0].portionSizes.length > 0 && this.state.adingItem[0].portionSizes.some((item) => item.available === true) && Object.keys(this.state.selectedPortionSize).length === 0) {
      formIsValid = false;
      this.setState({ portionSizeError: "Please select Portion Size" });
    }

    if (check === false) {
      formIsValid = false;
      isRequiredAvailable.forEach((group) => {
        errors[group.id] = `Please select ${group.name}`;
      });
    }

    this.setState({ addonErrors: errors });
    return formIsValid;
  };

  handleOrderShedule = (time) => {
    this.setState({ expectedAt: time });
  };

  handleMinimumOrder = () => {
    const selectedType = localStorage.getItem("orderType");
    let restData = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant"));
    if (["delivery"].includes(selectedType)) {
      this.setState({
        minimumOrder: restData.deliveryDetail.deliverySettings.minimumOrder,
      });
    }
    if (["pickup"].includes(selectedType)) {
      this.setState({
        minimumOrder: restData.deliveryDetail.pickupSettings.minimumOrder,
      });
    }
    if (["dine_in"].includes(selectedType)) {
      this.setState({
        minimumOrder: restData.deliveryDetail.dineInSettings.minimumOrder,
      });
    }
  };

  renderWhenCartUpdate = () => {
    this.setState((prevState) => ({
      toggleRerender: !prevState.toggleRerender,
    }));
  };

  // TODO: make utility and move into it
  getFormattedPrice = (price, currency) => {
    const symbol = this.getCurrencySymbol(currency);
    return `${symbol} ${price}`;
  }

  // TODO: make utility and move into it
  getCurrencySymbol = (currency) => {
    return currency.symbol || currency.code;
  };

  render() {
    let subTotal = 0;
    let addOnItems = [];
    for (const group of this.state.addonFields) {
      addOnItems.push(...group.items);
    }

    const addonTotal = addOnItems.reduce((currentValue, nextValue) => {
      return currentValue + parseInt(nextValue.price);
    }, 0);

    if (this.state.adingItem[0] && this.state.adingItem[0].portionSizes && this.state.adingItem[0].portionSizes.length > 0) {
      const portionSize = this.state.selectedPortionSize.price ? this.state.selectedPortionSize.price : 0;
      const portionSizePrice = portionSize * this.state.itemCount;

      const addonSubTotal = addonTotal * this.state.itemCount;
      subTotal = addonSubTotal + portionSizePrice;
    } else {
      subTotal = this.state.adingItem[0]
        ? this.state.adingItem[0].hasDiscount
          ? this.state.adingItem[0].displayPriceDiscounted * this.state.itemCount
          : this.state.adingItem[0].displayPrice * this.state.itemCount
        : 0;

      subTotal = subTotal + addonTotal;
    }

    const { addonsChecked } = this.state;
    const checkedCount = Object.keys(addonsChecked).filter((key) => addonsChecked[key]).length;

    const disabled = checkedCount >= this.state.maxSelections ? this.state.maxSelections : null;

    return (
      <React.Fragment>
        {this.state.redirectToRestaurants ? <Redirect to="/restaurants" /> : null}

        <TitleOfPage title={this.state.restDetails ? this.state.restDetails.name : ""} />

        <Lines
          color="#fff"
          background={
            localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
              ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
              : "#f26666"
          }
          animation="slide-down"
          customLoading={this.state.loading}
        />

        <div className="p-0">
          <Navbar
            restName={this.state.restDetails && this.state.restDetails.name}
            restId={this.state.restDetails && this.state.restDetails.id}
            restIsAvailable={this.state.restDetails && this.state.restDetails.deliveryDetail.isAvailable}
            name={localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).name}
            handleOrderShedule={this.handleOrderShedule}
            expectedAt={this.state.expectedAt}
            cartUpdate={this.renderWhenCartUpdate}
          />

          <div className="p-0 menu-items-sec">
            <HeaderBanner
              restDetails={this.state.restDetails}
              headerImg={this.state.restDetails && this.state.restDetails.images[0]}
              handleOrderShedule={this.handleOrderShedule}
              expectedAt={this.state.expectedAt}
              preOrderHours={this.state.preOrderHours}
            />

            <div className="container menu-cards-container">
              <div className="row sticky-top bg-light filter-bar m-0" style={{ zIndex: 2 }}>
                <div className="col p-0 ">
                  <div className="row mx-0">
                    <div className="col p-0">
                      {this.state.categories &&
                        this.state.categories
                          .sort((a, b) => a.sortOrder - b.sortOrder)
                          .map((category, i) => (
                            <a href={`#${category.title.replace(/[&\s/\\#,+()$~%.'":*?<>{}]/gi, "")}`} className="filter-badge" key={i}>
                              {category.title}
                            </a>
                          ))}
                    </div>
                  </div>
                </div>
              </div>

              {this.state.categories &&
                this.state.categories
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((category, i) => (
                    <div key={i} id={`${category.title.replace(/[&\s/\\#,+()$~%.'":*?<>{}]/gi, "")}-Category`} className="category-wrapper">
                      <span className="category-scool-element" id={`${category.title.replace(/[&\s/\\#,+()$~%.'":*?<>{}]/gi, "")}`}></span>
                      <h1 className="menu-headings">{category.title}</h1>
                      <div className="row">
                        {this.state.menu
                          .filter((filter) => filter.category.id === category.id && filter.image)
                          .sort((a, b) => a.sortOrder - b.sortOrder)
                          .map((menu, i) => (
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 menu-item-card" key={i}>
                              <div className="menu-item-card-inner" style={!menu.isAvailable ? { cursor: "not-allowed" } : {}}>
                                <div className="img-div">
                                  {menu.image ? <img src={menu.thumbnail} alt={menu.name} /> : null}
                                  {menu.isAvailable ? null : <p className="out-of-stock">Out of Stock</p>}
                                </div>
                                <div className="card-action-div">
                                  <h5 className="name">{menu.name.length > 55 ? menu.name.substring(0, 55 - 3) + "..." : menu.name}</h5>
                                  <p className="desc">{menu.description.length > 65 ? menu.description.substring(0, 65 - 3) + "..." : menu.description}</p>
                                  <div className="card-action-price-cart">
                                    {menu.displayPriceDiscounted && 
                                      <p className="discounted-price">
                                        {this.getFormattedPrice(menu.displayPrice.toFixed(2), this.state.currency)}
                                      </p>}
                                    {menu.displayPriceDiscounted ? (
                                      <p className="price">
                                        {this.getFormattedPrice(menu.displayPriceDiscounted.toFixed(2), this.state.currency)}
                                      </p>
                                    ) : (
                                      <p className="price">
                                        {this.getFormattedPrice(menu.displayPrice.toFixed(2), this.state.currency)}
                                      </p>
                                    )}

                                    {menu.isAvailable && 
                                    this.state.scheduleOrder === true && 
                                    this.state.expectedAt === "" && 
                                    localStorage.getItem("expectedAt") === "" 
                                    && !(this.state.hasSchedule === false && 
                                    this.state.isMenuActive === "disabled") ? (
                                      <>
                                        <button type="button" className="cart-btn-full-card-size" data-toggle="modal" data-target="#scheduleOnClosed" disabled={this.state.isMenuActive}></button>

                                        <button
                                          type="button"
                                          className="cart-btn"
                                          style={{
                                            background:
                                              localStorage.getItem("restaurant") &&
                                              JSON.parse(localStorage.getItem("restaurant")).branding &&
                                              JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                                ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                                : "#ff7068",
                                          }}
                                        >
                                          ADD TO CART
                                        </button>
                                      </>
                                    ) : menu.isAvailable && !(this.state.hasSchedule === false && this.state.isMenuActive === "disabled") ? (
                                      <>
                                        <button
                                          type="button"
                                          className="cart-btn-full-card-size"
                                          onClick={() => {
                                            this.setState({
                                              adingItem: [menu],
                                              itemCount: menu.minOrderQty === 0 ? 1 : menu.minOrderQty,
                                            });
                                            this.handleGetAddOn(menu.id);
                                          }}
                                          data-toggle="modal"
                                          data-target="#menu-item-details"
                                        ></button>

                                        <button
                                          type="button"
                                          className="cart-btn"
                                          style={{
                                            background:
                                              localStorage.getItem("restaurant") &&
                                              JSON.parse(localStorage.getItem("restaurant")).branding &&
                                              JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                                ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                                : "#ff7068",
                                          }}
                                        >
                                          ADD TO CART
                                        </button>
                                      </>
                                    ) : (
                                      <button type="button" className="dissable-cart-btn" disabled>
                                        ADD TO CART
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>

                      <div className="row">
                        {this.state.menu
                          .filter((filter) => filter.category.id === category.id && !filter.image)
                          .sort((a, b) => a.sortOrder - b.sortOrder)
                          .map((menu, i) => (
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 menu-item-card-empty" key={i}>
                              <div className="menu-item-card-inner" style={!menu.isAvailable ? { cursor: "not-allowed" } : {}}>
                                {menu.isAvailable ? null : <p className="out-of-stock">Out of Stock</p>}
                                <div className="card-action-div">
                                  <h5 className="name">{menu.name.length > 55 ? menu.name.substring(0, 55 - 3) + "..." : menu.name}</h5>
                                  <p className="desc">{menu.description.length > 65 ? menu.description.substring(0, 65 - 3) + "..." : menu.description}</p>
                                  <div className="card-action-price-cart">
                                    {menu.displayPriceDiscounted && 
                                      <p className="discounted-price">
                                        {this.getFormattedPrice(menu.displayPrice.toFixed(2), this.state.currency)}
                                      </p>}
                                    {menu.displayPriceDiscounted ? (
                                      <p className="price">
                                        {this.getFormattedPrice(menu.displayPriceDiscounted.toFixed(2), this.state.currency)}
                                      </p>
                                    ) : (
                                      <p className="price">
                                        {this.getFormattedPrice(menu.displayPrice.toFixed(2), this.state.currency)}
                                      </p>
                                    )}
                                    {menu.isAvailable && 
                                    this.state.scheduleOrder === true && 
                                    this.state.expectedAt === "" && 
                                    localStorage.getItem("expectedAt") === "" &&
                                    !(this.state.hasSchedule === false && 
                                      this.state.isMenuActive === "disabled") ? (
                                      <>
                                        <button type="button" className="cart-btn-full-card-size" data-toggle="modal" data-target="#scheduleOnClosed" disabled={this.state.isMenuActive}></button>

                                        <button
                                          type="button"
                                          className="cart-btn"
                                          style={{
                                            background:
                                              localStorage.getItem("restaurant") &&
                                              JSON.parse(localStorage.getItem("restaurant")).branding &&
                                              JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                                ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                                : "#ff7068",
                                          }}
                                        >
                                          ADD TO CART
                                        </button>
                                      </>
                                    ) : menu.isAvailable && !(this.state.hasSchedule === false && this.state.isMenuActive === "disabled") ? (
                                      <>
                                        <button
                                          type="button"
                                          className="cart-btn-full-card-size"
                                          onClick={() => {
                                            this.setState({
                                              adingItem: [menu],
                                              itemCount: menu.minOrderQty === 0 ? 1 : menu.minOrderQty,
                                            });
                                            this.handleGetAddOn(menu.id);
                                          }}
                                          data-toggle="modal"
                                          data-target="#menu-item-details"
                                        ></button>

                                        <button
                                          type="button"
                                          className="cart-btn"
                                          style={{
                                            background:
                                              localStorage.getItem("restaurant") &&
                                              JSON.parse(localStorage.getItem("restaurant")).branding &&
                                              JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                                ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                                : "#ff7068",
                                          }}
                                        >
                                          ADD TO CART
                                        </button>
                                      </>
                                    ) : (
                                      <button type="button" className="dissable-cart-btn" disabled>
                                        ADD TO CART
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
        <Footer restName={this.state.restDetails && this.state.restDetails.name} />

        <div className="modal fade menu-item-details-modal" id="menu-item-details" tabIndex="-1" role="dialog" aria-labelledby="menu-item-details" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md" role="document">
            <div className="modal-content menu-item-details-popup">
              <div className="menu-item-details-modal-body">
                <button type="button" className="close" id="menu-item-details-modal-close" data-dismiss="modal" aria-hidden="true" aria-label="Close">
                  <span aria-hidden="true" className="close-cart">
                    &times;
                  </span>
                </button>
                {this.state.adingItem.map((item) => (
                  <div key={item.id}>
                    {item.image ? (
                      <div className="menu-item-image">
                        <img style={this.state.loaded ? {} : { display: "none" }} src={item.image} alt="menu image" onLoad={() => this.setState({ loaded: true })} />
                        {item.image && !this.state.loaded ? (
                          <div className="addons-loading-spinner" style={{ height: "220px" }}>
                            <div className="spinner-border spinner-border" role="status"></div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    {this.state.loadingAddOns ? (
                      <div className="addons-loading-spinner" style={item.image ? { height: "150px" } : { height: "250px" }}>
                        <div className="spinner-border spinner-border-sm spinner-color-addon" role="status"></div>
                      </div>
                    ) : (
                      <div className="menu-item-details-modal-body-inner">
                        <div className="menu-item-details-title" style={!item.image ? { width: "90%" } : {}}>
                          <h6>{item.name}</h6>
                        </div>

                        <div className="menu-item-details-description">
                          <p>{item.description}</p>
                        </div>

                        <div className="add-ons-wrapper">
                          {/* Portion Size */}
                          {this.state.adingItem[0].portionSizes.length > 0 ? (
                            <div className="addons-select-main">
                              <div className="addons-title-main">
                                <h6 className="addons-title-a">Portion Size</h6>
                                <h6 className="addons-title-b">Required</h6>
                              </div>

                              <div className="addon-selection-section">
                                {this.state.adingItem[0].portionSizes
                                  .sort((a, b) => a.price - b.price)
                                  .map((portionItem) => (
                                    <div className="addon-select" key={portionItem.id}>
                                      <div>
                                        <label htmlFor={portionItem.id} className={portionItem.available === false ? "addon-disabled" : ""}>
                                          <input
                                            type="radio"
                                            id={portionItem.id}
                                            value={portionItem.name}
                                            name="portionSize"
                                            disabled={portionItem.available === false}
                                            defaultChecked={portionItem?.lowest ? portionItem.lowest : false}
                                            onChange={(event) =>
                                              this.handleOnPortionSizeChange(portionItem.id, portionItem.hasDiscount ? portionItem.calculatedDiscountPrice : portionItem.calculatedPrice, event)
                                            }
                                          />
                                          {portionItem.name}
                                        </label>
                                      </div>
                                      <div className={"addon-price" + (portionItem.available === false ? " addon-disabled" : "")}>
                                        {portionItem.hasDiscount && (
                                          <strike
                                            style={{
                                              fontSize: 12,
                                              fontWeight: 300,
                                            }}
                                          >
                                            {`${this.getCurrencySymbol(this.state.currency)} `}
                                            {portionItem.calculatedPrice.toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                            })}
                                          </strike>
                                        )}{" "}
                                        &nbsp; {`${this.getCurrencySymbol(this.state.currency)} `}
                                        {portionItem.hasDiscount
                                          ? portionItem.calculatedDiscountPrice.toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                            })
                                          : portionItem.calculatedPrice.toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                            })}
                                      </div>
                                    </div>
                                  ))}
                                <span className="addon-error">{this.state.portionSizeError}</span>
                              </div>
                            </div>
                          ) : null}

                          {/* AddOns */}
                          {this.state.menuAddOns
                            .sort((a, b) => a.sortOrder - b.sortOrder)
                            .map((addonGroup) => (
                              <div className="addons-select-main" key={addonGroup.id}>
                                <div className="addons-title-main">
                                  <h6 className="addons-title-a">{addonGroup.name}</h6>
                                  <h6 className="addons-title-b">
                                    {addonGroup.isRequired ? "Required" : "Not Required"}
                                    {addonGroup.maxSelections > 1 ? `, Select up to ${addonGroup.maxSelections}` : ""}
                                  </h6>
                                </div>

                                <div className="addon-selection-section">
                                  {addonGroup.maxSelections <= 1 && addonGroup.isRequired
                                    ? addonGroup.items
                                        .sort((a, b) => a.sortOrder - b.sortOrder)
                                        .map((item) => (
                                          <div className="addon-select" key={item.id}>
                                            <div>
                                              <label htmlFor={item.id}>
                                                <input
                                                  type="radio"
                                                  id={item.id}
                                                  value={item.name}
                                                  name={addonGroup.name}
                                                  disabled={item.isAvailable === false}
                                                  onChange={(event) => this.handleOnSingleChange(addonGroup.maxSelections, addonGroup.isRequired, addonGroup.id, item.id, item.price, event)}
                                                />
                                                {item.name}
                                              </label>
                                            </div>
                                            <div className={"addon-price" + (item.isAvailable === false ? " addon-disabled" : "")}>
                                              {item.price < 0 ? "-" : "+"} {`${this.getCurrencySymbol(this.state.currency)} `}
                                              {Math.abs(item.price).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                              })}
                                            </div>
                                          </div>
                                        ))
                                    : addonGroup.items
                                        .sort((a, b) => a.sortOrder - b.sortOrder)
                                        .map((item, i) => (
                                          <div className="addon-select" key={item.id}>
                                            <div>
                                              <input
                                                type="checkbox"
                                                value={item.price}
                                                name={item.name}
                                                id={item.id}
                                                disabled={(!addonsChecked[i] && disabled) || item.isAvailable === false}
                                                onChange={(event) => this.handleOnMultipleChange(addonGroup.maxSelections, addonGroup.isRequired, addonGroup.id, item.id, item.name, event, i)}
                                              />
                                              <label htmlFor={item.id}>{item.name}</label>
                                            </div>
                                            <div className={"addon-price" + ((!addonsChecked[i] && disabled) || item.isAvailable === false ? " addon-disabled" : "")}>
                                              {item.price < 0 ? "-" : "+"} {`${this.getCurrencySymbol(this.state.currency)} `}
                                              {Math.abs(item.price).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                              })}
                                            </div>
                                          </div>
                                        ))}
                                  <span className="addon-error">{this.state.addonErrors[addonGroup.id]}</span>
                                </div>
                              </div>
                            ))}
                        </div>

                        {this.state.adingItem[0]?.preOrderHours ? (
                          <div className="pre-order-title-main">
                            <div>
                              <i className="fas fa-info-circle"></i>
                            </div>
                            <div>
                              <h6 className="addons-title-a">Pre Order</h6>
                              <h6 className="addons-title-b">
                                This item needs to be ordered at least <b>{this.state.adingItem[0].preOrderHours / 24} days</b> before the required date
                              </h6>
                            </div>
                          </div>
                        ) : null}

                        <div className="menu-item-details-buttons">
                          <div className="menu-item-couter">
                            <button
                              type="button"
                              className="item-details-cart-count"
                              onClick={this.decrementItemCount}
                              style={{
                                background:
                                  localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                    ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                    : "#ff7068",
                              }}
                              disabled={this.state.adingItem[0].minOrderQty === this.state.itemCount}
                            >
                              <i className="fas fa-minus"></i>
                            </button>

                            <div className="count-display">{this.state.itemCount}</div>

                            <button
                              type="button"
                              className="item-details-cart-count"
                              onClick={this.incrementItemCount}
                              style={{
                                background:
                                  localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                    ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                    : "#ff7068",
                              }}
                              disabled={this.state.adingItem[0].maxOrderQty === this.state.itemCount}
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                          </div>

                          <div className="add-to-cart-btn">
                            <button
                              type="button"
                              className="item-details-cart-btn"
                              onClick={this.handleAddToCart}
                              style={{
                                background:
                                  localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                    ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                    : "#ff7068",
                              }}
                            >
                              ADD TO CART{" "}
                              <span className="add-to-cart-btn-total">
                                ({`${this.getCurrencySymbol(this.state.currency)}`}
                                {subTotal.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                })}
                                )
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
