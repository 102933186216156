    import axios from "axios";

    const getConstant = () => {
        return {
          API_URL: process.env.REACT_APP_API_URL,
          API_V2: "v2",
        };
    }

    //get order data
    export const getOrder = async(id, onSuccess, onError) => {
            const {API_URL, API_V2} = getConstant();
            const url = `${API_URL}/${API_V2}/delivery_orders/tracking/${id}`

            axios.get(url)
            .then(res => {
                onSuccess(res);
            })
            .catch(err => {
                onError(err);
            })
    }