import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Navbar from "../components/menu/Navbar";
import Footer from "../components/menu/Footer";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import PhoneInput from "react-phone-input-2";
import ReactCodeInput from "react-verification-code-input";
import { phonenumberVerify, phonenumberVerifyOTP, submitGuestAddressAPI } from "../components/api/UserDetailsAPI";
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import { errorAllert, successAlert } from "../methods/Alert";
import TitleOfPage from "../components/TitleOfPage";
import ManageOrderType from "../components/orderManage/ManageOrderType";
import ManageOrderTime from "../components/orderManage/ManageOrderTime";
import SweetAlert from "react-bootstrap-sweetalert";
import { isAuthenticatedWithGuest } from "../auth/auth";
import moment from "moment";
import ManageOrderTimeOnResClosed from "../components/orderManage/ManageOrderTimeOnResClosed";
import ManageOrderTimePreOrder from "../components/orderManage/ManageOrderTimePreOrder";

Geocode.setApiKey("AIzaSyC8EFXuIwNdJVSEzVOCIDJeCRM2Rhuj8VY");
Geocode.enableDebug();

class Guest extends Component {
  state = {
    guestFirstName: "",
    guestLastName: "",
    guestEmail: "",
    guestPhoneNo: "",
    address: {},
    note: "",

    FNameError: "",
    LNameError: "",
    mobileError: "",
    addressError: "",
    emailError: "",

    phoneId: "",
    addressId: "",

    guestMobile: "",
    guestAddress: "",
    houseNoError: "",
    cityError: "",

    showPhone: true,
    EnterValidAddErr: false,
    loadingContinue: false,
    showAddress: true,
    loadingContinueToCheckout: false,
    toggleRerender: false,

    houseNo: "",
    street2: "",
    city: "",
    cities: [],
    charsLeft: 1000,
    restId: localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).id,

    // alerts
    errorAlert: false,

    errorAlertText: "",
    errorAlertHeading: "",
    isResAvailable: true,
  };

  componentDidMount = () => {
    let restData = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant"));
    this.setState({
      isResAvailable: restData.deliveryDetail.isAvailable,
    });

    window.scrollTo(0, 0);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleEmailChange = (e) => {
    this.setState({ [e.target.name]: e.target.value.trim() });
  };

  // validate
  mobileValidate = () => {
    let mobileError = "";

    let newPhone = this.state.guestMobile ? this.state.guestMobile.replace(/\s/g, "") : "";

    if (newPhone === "") {
      mobileError = "Mobile is required";
    } else if (newPhone.length < 11) {
      mobileError = "Mobile is invalid";
    }
    if (mobileError) {
      this.setState({
        mobileError,
      });
      return false;
    }
    return true;
  };

  sendToVerify = () => {
    if (this.mobileValidate()) {
      this.setState({ loadingVerify: true });

      phonenumberVerify(
        this.state.guestMobile,
        (data) => {
          this.setState({
            showPhone: false,
            showModal: true,
            phoneId: data.id,
            loadingVerify: false,
          });
        },
        (error) => {
          this.setState({
            loadingVerify: false,
            mobileError: `${error?.response?.data?.message} ${error?.response?.data?.code}`,
          });
        }
      );

      setTimeout(() => {
        this.setState({
          showPhone: true,
          showModal: false,
          phoneId: "",
        });
      }, 900000);
    }
  };

  verify = (code) => {
    this.setState({ loadingOTP: true, verificationError: "" });
    phonenumberVerifyOTP(
      code,
      this.state.phoneId,
      (data) => {
        console.log(data);
        this.setState({
          guestPhoneNo: this.state.guestMobile,
          showPhone: true,
          showModal: false,
          loadingOTP: false,
        });
        document.getElementById("phoneModal").click();
      },

      (error) => {
        this.setState({
          verificationError: `Verification code is invalid. Error code: ${error.response.data.code}`,
          loadingOTP: false,
        });
      }
    );
  };

  closeModal = () => {
    this.setState({
      houseNo: "",
      street2: "",
      city: "",
      houseNoError: "",
      cityError: "",
      showAddress: true,
      showMap: false,
      EnterValidAddErr: false,
    });
  };

  // marker drag and drop
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    console.log(newLat, newLng);

    Geocode.fromLatLng(newLat, newLng)
      .then((res) => {
        this.setState({
          latitude: newLat,
          longitude: newLng,
        });

        this.WrappedMap = withScriptjs(
          withGoogleMap((props) => (
            <GoogleMap
              defaultZoom={16}
              defaultCenter={{
                lat: this.state.latitude,
                lng: this.state.longitude,
              }}
            >
              <Marker
                position={{
                  lat: this.state.latitude,
                  lng: this.state.longitude,
                }}
                draggable={true}
                onDragEnd={this.onMarkerDragEnd}
              />
            </GoogleMap>
          ))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // validate
  validateAddress = () => {
    let houseNoError = "";
    let cityError = "";

    if (this.state.houseNo === "") {
      houseNoError = "House No and name is required";
    }

    if (this.state.city === "") {
      cityError = "City is required";
    }

    if (houseNoError || cityError) {
      this.setState({
        houseNoError,
        cityError,
      });
      return false;
    }
    return true;
  };

  continue = (e) => {
    e.preventDefault();

    if (this.validateAddress()) {
      this.setState({
        houseNoError: "",
        cityError: "",
        EnterValidAddErr: false,
      });

      this.setState({ loadingContinue: true });

      const google = window.google;
      let service = new google.maps.Geocoder();

      service.geocode(
        {
          address: `${this.state.houseNo} ${this.state.street2}, ${this.state.city} }`,
          componentRestrictions: {
            country: "LK",
          },
        },
        (geocoderResult, status) => {
          if (status === "OK") {
            this.setState({
              latitude: geocoderResult[0].geometry.location.lat(),
              longitude: geocoderResult[0].geometry.location.lng(),
            });

            this.WrappedMap = withScriptjs(
              withGoogleMap((props) => (
                <GoogleMap
                  defaultZoom={16}
                  defaultCenter={{
                    lat: geocoderResult[0].geometry.location.lat(),
                    lng: geocoderResult[0].geometry.location.lng(),
                  }}
                >
                  <Marker
                    position={{
                      lat: geocoderResult[0].geometry.location.lat(),
                      lng: geocoderResult[0].geometry.location.lng(),
                    }}
                    draggable={true}
                    onDragEnd={this.onMarkerDragEnd}
                  />
                </GoogleMap>
              ))
            );

            this.setState({
              showMap: true,
              showAddress: false,
              loadingContinue: false,
            });
          } else {
            // if google returns an error for the address try once without line2 and once without the city(if line 2 is not null). SPR-118
            if (status === "ZERO_RESULTS") {
              service.geocode(
                {
                  address: `${this.state.houseNo} ${""}, ${this.state.city} }`,
                  componentRestrictions: {
                    country: "LK",
                  },
                },
                (geocoderResult, status) => {
                  if (status === "OK") {
                    this.setState({
                      latitude: geocoderResult[0].geometry.location.lat(),
                      longitude: geocoderResult[0].geometry.location.lng(),
                    });

                    this.WrappedMap = withScriptjs(
                      withGoogleMap((props) => (
                        <GoogleMap
                          defaultZoom={16}
                          defaultCenter={{
                            lat: geocoderResult[0].geometry.location.lat(),
                            lng: geocoderResult[0].geometry.location.lng(),
                          }}
                        >
                          <Marker
                            position={{
                              lat: geocoderResult[0].geometry.location.lat(),
                              lng: geocoderResult[0].geometry.location.lng(),
                            }}
                            draggable={true}
                            onDragEnd={this.onMarkerDragEnd}
                          />
                        </GoogleMap>
                      ))
                    );

                    this.setState({
                      showMap: true,
                      showAddress: false,
                      loadingContinue: false,
                    });
                  } else {
                    if (this.state.street2 !== "") {
                      service.geocode(
                        {
                          address: `${this.state.houseNo} ${this.state.street2} }`,
                          componentRestrictions: {
                            country: "LK",
                          },
                        },
                        (geocoderResult, status) => {
                          if (status === "OK") {
                            this.setState({
                              latitude: geocoderResult[0].geometry.location.lat(),
                              longitude: geocoderResult[0].geometry.location.lng(),
                            });

                            this.WrappedMap = withScriptjs(
                              withGoogleMap((props) => (
                                <GoogleMap
                                  defaultZoom={16}
                                  defaultCenter={{
                                    lat: geocoderResult[0].geometry.location.lat(),
                                    lng: geocoderResult[0].geometry.location.lng(),
                                  }}
                                >
                                  <Marker
                                    position={{
                                      lat: geocoderResult[0].geometry.location.lat(),
                                      lng: geocoderResult[0].geometry.location.lng(),
                                    }}
                                    draggable={true}
                                    onDragEnd={this.onMarkerDragEnd}
                                  />
                                </GoogleMap>
                              ))
                            );

                            this.setState({
                              showMap: true,
                              showAddress: false,
                              loadingContinue: false,
                            });
                          } else {
                            this.setState({
                              EnterValidAddErr: true,
                              loadingContinue: false,
                            });
                          }
                        }
                      );
                    }
                  }
                }
              );
            }
            this.setState({ EnterValidAddErr: true, loadingContinue: false });
          }
        }
      );
    }
  };

  handleCities = (e) => {
    const google = window.google;
    let service = new google.maps.places.AutocompleteService();
    if (e.target.value) {
      service.getPlacePredictions(
        {
          input: e.target.value,
          types: ["geocode"],
          componentRestrictions: {
            country: "LK",
          },
        },
        (predictions, status) => {
          if (status === "OK") {
            this.setState({ cities: predictions });
          } else if (status === "ZERO_RESULTS") {
            this.setState({ cities: [] });
          }
        }
      );
      this.setState({ city: e.target.value });
    } else {
      this.setState({ cities: [], city: "" });
    }
  };

  addAddress = (e) => {
    const addressData = {
      line1: this.state.houseNo,
      line2: this.state.street2,
      city: this.state.city,
      country: "Sri Lanka",
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };
    this.setState({
      address: addressData,
      showMap: false,
      showAddress: true,
      EnterValidAddErr: false,
    });
    document.getElementById("addressModal").click();
    successAlert("Address has been added successfully!");
  };

  // validate
  validate = () => {
    let FNameError = "";
    let LNameError = "";
    let mobileError = "";
    let addressError = "";
    let emailError = "";

    const emailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.state.guestFirstName === "") {
      FNameError = "First name is required";
    }

    if (this.state.guestLastName === "") {
      LNameError = "Last name is required";
    }

    if (this.state.guestEmail) {
      if (emailCheck.test(String(this.state.guestEmail).toLowerCase()) === false) {
        emailError = "You have entered an invalid email address";
      }
    }

    if (localStorage.getItem("orderType") === "dine_in" && localStorage.getItem("selectedOrderTypeData") && JSON.parse(localStorage.getItem("selectedOrderTypeData"))?.phoneNumberRequired === false) {
      mobileError = "";
    } else {
      if (this.state.phoneId === "") {
        mobileError = "Mobile is required";
      } else if (this.state.guestMobile === "") {
        mobileError = "Please enter your mobile number";
      } else if (this.state.guestPhoneNo === "" && this.state.guestMobile !== "") {
        mobileError = "Please verify your mobile number";
      }
    }

    if (localStorage.getItem("orderType") === "delivery") {
      if (this.state.address && Object.keys(this.state.address).length === 0) {
        addressError = "Delivery address is required";
      }
    }

    if (FNameError || LNameError || mobileError || addressError || emailError) {
      this.setState({
        FNameError,
        LNameError,
        mobileError,
        addressError,
        emailError,
      });
      return false;
    }
    return true;
  };

  handleAdditionalInfoChange = (e) => {
    let inputVal = e.target.value;
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ charsLeft: 1000 - inputVal.length });
  };

  handlesubmitUserDetails = async (deliveryData) => {
    this.setState({ loadingContinueToCheckout: true });
    try {
      let res = await submitGuestAddressAPI(this.state.restId, this.state.address.latitude, this.state.address.longitude);

      if (res && res.status === 200) {
        if (isAuthenticatedWithGuest()) {
          // store
          localStorage.setItem("deliveryFee", res.data.price);
          localStorage.setItem("guest_checkout", "true");
          localStorage.setItem("guest_details", JSON.stringify(deliveryData));
          this.props.history.push("/checkout/your-details/order");
        } else {
          alert("Please select Continue As a Guest");
          document.getElementById("showGuestModal").click();
        }

        this.setState({
          loadingContinueToCheckout: false,
        });
      }
    } catch (error) {
      if (error.response.data.code === 1067) {
        this.setState({ errorAlert: true });
        this.setState({
          errorAlertHeading: "Delivery location is too far from Restaurant",
          errorAlertText: `Listing does not deliver to this location. Error code: ${error.response.data.code}`,
          loadingContinueToCheckout: false,
        });
      }
      if (error.response.data.code === 400) {
        errorAllert(`Something went wrong please try again! Error code: ${error.response.data.code}`);
        this.setState({ loadingContinueToCheckout: false });
      } else {
        this.setState({ loadingContinueToCheckout: false });
        errorAllert("Something went wrong please try again!");
      }
    }
  };

  submitUserDetails = () => {
    if (this.validate()) {
      let guestData = {
        listingId: this.state.restId,
        payOnDelivery: null,
        matchMinOrder: JSON.parse(localStorage.getItem("matchMinOrder")),
        deliveryNote: this.state.note,
        orderType: localStorage.getItem("orderType"),
        asap: JSON.parse(localStorage.getItem("asap")),
        expectedAt: localStorage.getItem("expectedAt") !== "" ? localStorage.getItem("expectedAt") : null,
        tableId: localStorage.getItem("tableId") && localStorage.getItem("orderType") === "dine_in" ? localStorage.getItem("tableId") : null,
        orderItems: [],
        guestDetail: {
          firstName: this.state.guestFirstName,
          lastName: this.state.guestLastName,
          email: this.state.guestEmail,
          phoneId: this.state.phoneId,
          deliveryAddress: [],
        },
      };

      if (localStorage.getItem("orderType") === "delivery") {
        // const fullAddress = `${this.state.address.line1}, ${this.state.address?.line2}, ${this.state.address.city}, ${this.state.address.country}`;
        let address = {
          line1: this.state.address?.line1 ? this.state.address.line1 : null,
          line2: this.state.address?.line2 ? this.state.address?.line2 : null,
          city: this.state.address?.city ? this.state.address.city : null,
          country: "Sri Lanka",
          latitude: this.state.address?.latitude ? this.state.address?.latitude : null,
          longitude: this.state.address?.longitude ? this.state.address?.longitude : null,
        };
        // guestData.deliveryAddress = fullAddress;
        guestData.guestDetail.deliveryAddress = address;
        this.handlesubmitUserDetails(guestData);
      } else {
        if (isAuthenticatedWithGuest()) {
          // store
          localStorage.setItem("guest_checkout", "true");
          localStorage.setItem("guest_details", JSON.stringify(guestData));
          this.props.history.push("/checkout/your-details/order");
        } else {
          alert("Please select Continue As a Guest");
          document.getElementById("showGuestModal").click();
        }
      }
    }
  };

  rerenderParentCallback = () => {
    this.setState({ toggleRerender: !this.state.toggleRerender });
  };

  // close modal
  close = () => {
    this.setState({
      errorAlert: false,
      errorAlertHeading: "",
      errorAlertText: "",
    });
  };

  handleOrderSheduleTime = (time) => {
    this.setState({ expectedAt: time });
  };

   // TODO: make utility and move into it
   getFormattedPrice = (price, currency) => {
    const symbol = this.getCurrencySymbol(currency);
    return `${symbol} ${price}`;
  }

  // TODO: make utility and move into it
  getCurrencySymbol = (currency) => {
    return currency.symbol || currency.code;
  };

  render() {
    const restData = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant"));
    const cartItems = localStorage.getItem(`cart_${restData.id}`) ? JSON.parse(localStorage.getItem(`cart_${restData.id}`)) : [];
    const maxPreorderHours = cartItems.reduce((prev, current) => {
      return prev.preOrderHours > current.preOrderHours ? prev : current;
    }, 0);

    const isPhoneNumberRequired = localStorage.getItem("selectedOrderTypeData") && JSON.parse(localStorage.getItem("selectedOrderTypeData"))?.phoneNumberRequired;
    const { currency } = restData;

    return (
      <React.Fragment>
        <TitleOfPage title="Guest Details" />
        <div className="p-0 page-background-grey">
          <Navbar
            restName={localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).name}
            name={localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).name}
            restId={localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).id}
            restIsAvailable={false}
            displayOrderType={false}
            displayPickupType={false}
            displayMenuStore={true}
          />

          {this.state.errorAlert ? (
            <SweetAlert danger title={this.state.errorAlertHeading} onConfirm={this.close} confirmBtnStyle={{ background: "#f26666", border: 0 }}>
              {this.state.errorAlertText}
            </SweetAlert>
          ) : null}

          <div className="container min-height-viewport padding-top-bottom-25">
            <div className="cart-sec">
              <div className="row m-0">
                <div className="col p-0">
                  <div className="go-back-action-buttons">
                    <Link className="text-muted d-block mb-3" to="/cart" style={{ textDecoration: "none" }}>
                      {" "}
                      <i className="fas fa-chevron-left"></i>Back
                    </Link>
                  </div>

                  <div className="order-modification">
                    <div className="order-type-change">
                      <ManageOrderType rerenderParentCallback={this.rerenderParentCallback} />
                    </div>

                    <div className="order-time-change">
                      {!this.state.isResAvailable ? (
                        <>
                          <div data-toggle="modal" data-target="#scheduleOnClosed" className="schedule-onclosed-wrapper">
                            {localStorage.getItem("expectedAt") !== "" ? (
                              "Order scheduled for: " + moment(localStorage.getItem("expectedAt")).format("MMMM Do YYYY, h:mm a") ||
                              "Order scheduled for: " + moment(this.props.expectedAt).format("MMMM Do YYYY, h:mm a")
                            ) : (
                              <>
                                <i className="fas fa-calendar-alt pr-2"></i> Schedule an Order{" "}
                              </>
                            )}
                          </div>
                          <ManageOrderTimeOnResClosed handleOrderShedule={this.handleOrderSheduleTime} />
                        </>
                      ) : maxPreorderHours && maxPreorderHours.preOrderHours > 0 ? (
                        <>
                          <div data-toggle="modal" data-target="#schedulePreOrder" className="schedule-onclosed-wrapper">
                            {localStorage.getItem("expectedAt") !== "" ? (
                              "Order scheduled for: " + moment(localStorage.getItem("expectedAt")).format("MMMM Do YYYY, h:mm a") ||
                              "Order scheduled for: " + moment(this.props.expectedAt).format("MMMM Do YYYY, h:mm a")
                            ) : (
                              <>
                                <i className="fas fa-calendar-alt pr-2"></i> Schedule an Order{" "}
                              </>
                            )}
                          </div>
                          <ManageOrderTimePreOrder handleOrderShedule={this.handleOrderSheduleTime} />
                        </>
                      ) : (
                        <ManageOrderTime rerenderParentCallback={this.rerenderParentCallback} />
                      )}
                    </div>
                  </div>

                  <div className="your-details-title">
                    <div>
                      <h1 className="cart-heading">Enter Your Details</h1>
                    </div>
                    <div>
                      <h6>
                        {(() => {
                          let cartSubTotal = JSON.parse(localStorage.getItem("cartSubTotal"));
                          cartSubTotal = cartSubTotal?.toLocaleString(undefined, { minimumFractionDigits: 2 });
                          return `Sub Total: ${cartSubTotal ? this.getFormattedPrice(cartSubTotal, currency) : ""}`;
                        })()}
                      </h6>
                    </div>
                  </div>
                  <hr />
                  <div className="guest-details-in">
                    <div className="d-flex">
                      <div className="form-group pl-0 pr-1" style={{ width: "50%" }}>
                        <label htmlFor="guest-name">First Name</label>
                        <input
                          type="text"
                          className={classnames("form-control", {
                            "is-invalid": this.state.FNameError,
                          })}
                          id="guest-name"
                          name="guestFirstName"
                          onChange={this.handleChange}
                          value={this.state.guestFirstName}
                          autoComplete="new-password"
                        />
                        <div className="invalid-feedback">{this.state.FNameError}</div>
                      </div>

                      <div className="form-group pl-1 pr-0" style={{ width: "50%" }}>
                        <label htmlFor="guest-name">Last Name</label>
                        <input
                          type="text"
                          className={classnames("form-control", {
                            "is-invalid": this.state.LNameError,
                          })}
                          id="guest-name"
                          name="guestLastName"
                          onChange={this.handleChange}
                          value={this.state.guestLastName}
                          autoComplete="new-password"
                        />
                        <div className="invalid-feedback">{this.state.LNameError}</div>
                      </div>
                    </div>

                    <div className="form-group p-0">
                      <label htmlFor="guest-email">Email</label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.state.emailError,
                        })}
                        id="guest-email"
                        name="guestEmail"
                        onChange={this.handleEmailChange}
                        value={this.state.guestEmail}
                        autoComplete="new-password"
                      />
                      <div className="invalid-feedback">{this.state.emailError}</div>
                    </div>

                    <div className="form-group p-0 mt-2 your-details-mobile-no">
                      <div className="yd-label-action-btn">
                        <label htmlFor="mobile">Mobile {!isPhoneNumberRequired && localStorage.getItem("orderType") === "dine_in" ? "(Optional)" : ""}</label>
                        <span>
                          {this.state.guestPhoneNo !== "" ? (
                            <button
                              className="add-address-btn pl-2 d-inline-block"
                              type="button"
                              data-toggle="modal"
                              data-target="#phoneModal"
                              style={{
                                color:
                                  localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                    ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                    : "#ff7068",
                              }}
                            >
                              Edit
                            </button>
                          ) : null}

                          {this.state.guestPhoneNo !== "" ? (
                            <button
                              className="add-address-btn pl-2 d-inline-block"
                              type="button"
                              style={{
                                color:
                                  localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                    ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                    : "#ff7068",
                                width: "auto",
                              }}
                              onClick={() =>
                                this.setState({
                                  guestPhoneNo: "",
                                  guestMobile: "",
                                  mobileError: "",
                                  phoneId: "",
                                })
                              }
                            >
                              <i className="fas fa-times-circle"></i>
                            </button>
                          ) : null}
                        </span>
                      </div>
                      {this.state.guestPhoneNo !== "" ? (
                        <React.Fragment>
                          <p
                            className="mb-0"
                            style={{
                              color: "#575d64",
                            }}
                          >
                            +{this.state.guestPhoneNo}{" "}
                            <span className="text-success ml-3" style={{ fontSize: 13, fontWeight: 500 }}>
                              <i className="fas fa-check-circle pr-1"></i> Verified
                            </span>
                          </p>
                        </React.Fragment>
                      ) : (
                        <button
                          className="add-phone-btn-guest d-block"
                          type="button"
                          data-toggle="modal"
                          data-target="#phoneModal"
                          onClick={() => {
                            this.setState({ mobileError: "" });
                            if (this.state.phoneId) {
                              this.setState({ showModal: true, showPhone: false });
                            } else {
                              this.setState({ showModal: false, showPhone: true });
                            }
                          }}
                          style={{
                            color:
                              localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                : "#ff7068",
                            border: this.state.mobileError ? "1px solid #dc3545" : "",
                          }}
                        >
                          {this.state.guestMobile ? (
                            <>
                              <i className="fas fa-exclamation-circle"></i> {this.state.guestMobile}
                            </>
                          ) : (
                            <>
                              <i className="fas fa-plus"></i> Add
                            </>
                          )}
                        </button>
                      )}
                      <div
                        style={{
                          marginTop: ".25rem",
                          fontSize: "80%",
                          color: "#dc3545",
                        }}
                      >
                        {this.state.mobileError}
                      </div>
                    </div>

                    {localStorage.getItem("orderType") === "delivery" ? (
                      <div className="form-group p-0 mt-2 your-details-address-no">
                        <div className="yd-label-action-btn">
                          <label htmlFor="address">Delivery Address</label>
                          {this.state.address && Object.keys(this.state.address).length !== 0 ? (
                            <button
                              className="add-address-btn pl-2 d-inline-block"
                              type="button"
                              data-toggle="modal"
                              data-target="#addressModal"
                              style={{
                                color:
                                  localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                    ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                    : "#ff7068",
                              }}
                            >
                              Edit
                            </button>
                          ) : null}
                        </div>

                        {this.state.address && Object.keys(this.state.address).length !== 0 ? (
                          <React.Fragment>
                            <p className="mb-0" style={{ color: "#575d64" }}>
                              {`${this.state.address.line1}, ${this.state.address.line2 ? this.state.address.line2 + "," : ""} ${this.state.address.city}, ${this.state.address.country}`}
                            </p>
                          </React.Fragment>
                        ) : (
                          <button
                            className="add-phone-btn-guest d-block"
                            type="button"
                            data-toggle="modal"
                            data-target="#addressModal"
                            style={{
                              color:
                                localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                  ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                  : "#ff7068",
                            }}
                          >
                            <i className="fas fa-plus"></i> Add
                          </button>
                        )}

                        <div
                          style={{
                            marginTop: ".25rem",
                            fontSize: "80%",
                            color: "#dc3545",
                          }}
                        >
                          {this.state.addressError}
                        </div>
                      </div>
                    ) : null}

                    <div className="form-group p-0 mt-2">
                      <label htmlFor="email">Additional Info (Optional)</label>
                      <textarea
                        type="text"
                        maxLength={1000}
                        className="form-control"
                        id="note"
                        name="note"
                        onChange={this.handleAdditionalInfoChange}
                        value={this.state.note}
                        style={{ height: 100 }}
                      />
                      <span className="additional-info-text-limit">Characters Left: {this.state.charsLeft}</span>
                    </div>

                    <div className="checkout-btn">
                      <button
                        className="checkout mt-2"
                        onClick={this.submitUserDetails}
                        style={{
                          background:
                            localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                              ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                              : "#ff7068",
                        }}
                      >
                        {this.state.loadingContinueToCheckout ? (
                          <div className="spinner-border spinner-border-sm text-light" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}

                        {this.state.loadingContinueToCheckout ? " Continuing..." : <span>Continue</span>}
                      </button>

                      <div className="cart-continue-login">
                        <a data-toggle="modal" data-target="#login-sign-up-modal">
                          Sign in
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* mobile modal */}
        <div className="modal fade" id="phoneModal" tabIndex="-1" role="dialog" aria-labelledby="addressModal" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title">{this.state.showPhone ? "Mobile Number" : "Enter OTP"}</h6>
                <button type="button" id="close" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({ showPhone: true, showModal: false })}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.showPhone ? (
                  <form noValidate className="user-details-form">
                    <div className="form-group p-0 mt-4 mb-0">
                      <div
                        style={{
                          marginTop: ".25rem",
                          fontSize: "80%",
                          color: "#dc3545",
                          marginBottom: "5px",
                          textAlign: "center",
                        }}
                      >
                        {this.state.mobileError}
                      </div>
                      <PhoneInput country={"lk"} value={this.state.guestMobile} enableSearch={true} disableSearchIcon={true} onChange={(phone) => this.setState({ guestMobile: phone })} prefix="" />
                      <div className="send-to-verify-button">
                        <button
                          type="button"
                          className="send-to-verify"
                          onClick={this.sendToVerify}
                          style={{
                            background:
                              localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                : "#ff7068",
                          }}
                        >
                          {this.state.loadingVerify ? (
                            <div className="spinner-border spinner-border-sm" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                          {this.state.loadingVerify ? " Sending..." : <span>Send Verification Code</span>}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : null}
                {this.state.showModal ? (
                  <React.Fragment>
                    <p className="otp-sub-title">A code has been sent to the provided mobile number.</p>
                    <div>
                      <p className="mobile-number-popup-display-title">Your Phone Number</p>
                      <div className="mobile-number-popup-display-wrapper">
                        <h6 className="mobile-number-popup-display">+{this.state.guestMobile}</h6>
                        <h6 className="mobile-number-popup-display-edit" onClick={() => this.setState({ showPhone: true, showModal: false })}>
                          (Edit)
                        </h6>
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: ".25rem",
                        fontSize: "80%",
                        color: "#dc3545",
                        marginBottom: 15,
                        textAlign: "center",
                      }}
                    >
                      {this.state.verificationError}
                    </div>

                    <ReactCodeInput fields={4} className="mx-auto" onComplete={(val) => this.verify(val)} />

                    {this.state.loadingOTP ? (
                      <div className="text-center mt-3">
                        <div className="spinner-border spinner-border-sm" role="status">
                          <span className="sr-only"></span>
                        </div>
                        <h6 className="mt-2">Verifying... Please wait!</h6>
                      </div>
                    ) : null}
                    <p
                      type="button"
                      className="send-code-again"
                      onClick={this.sendToVerify}
                      style={{
                        color:
                          localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                            ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                            : "#ff7068",
                      }}
                    >
                      Send Code Again
                    </p>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* address modal */}
        <div className="modal fade" id="addressModal" tabIndex="-1" role="dialog" aria-labelledby="addressModal" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="addressModal">
                  Add Delivery Address
                </h6>
                <button type="button" id="close" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pt-0 pb-0">
                {this.state.showAddress ? (
                  <div className="row mx-0">
                    <div className="col p-0">
                      <p className="mb-3 mt-3" style={{ fontSize: 14, fontWeight: 600 }}>
                        Please enter your address details
                      </p>

                      <React.Fragment>
                        <form noValidate className="user-details-form">
                          <div className="form-group p-0 mb-0">
                            <input
                              type="text"
                              className={classnames("form-control", {
                                "is-invalid": this.state.houseNoError,
                              })}
                              id="houseNo"
                              name="houseNo"
                              placeholder="Street Number & Name"
                              onChange={this.handleChange}
                              value={this.state.houseNo}
                              autoComplete="off"
                            />
                            <div className="invalid-feedback">{this.state.houseNoError}</div>
                          </div>

                          <div className="form-group p-0 mb-0 mt-3">
                            <input
                              type="text"
                              className="form-control"
                              name="street2"
                              placeholder="Address Line 2 (Optional)"
                              onChange={this.handleChange}
                              value={this.state.street2}
                              autoComplete="off"
                            />
                          </div>

                          <div className="form-group p-0 mb-0 mt-3">
                            <input
                              type="text"
                              list="cities"
                              className={classnames("form-control", {
                                "is-invalid": this.state.cityError,
                              })}
                              name="city"
                              placeholder="City"
                              value={this.state.city}
                              onChange={this.handleCities}
                              autoComplete="off"
                            />
                            <datalist id="cities">
                              {this.state.cities.map((city, i) => (
                                <option key={i}>{city.structured_formatting.main_text}</option>
                              ))}
                            </datalist>
                            <div className="invalid-feedback">{this.state.cityError}</div>
                          </div>

                          {this.state.EnterValidAddErr ? (
                            <p
                              style={{
                                fontSize: 13,
                                color: "#dc3545",
                                marginTop: 5,
                              }}
                            >
                              Please enter a valid address
                            </p>
                          ) : null}
                          <div className="form-group p-0 mb-0 mt-3">
                            <input readOnly type="text" className="form-control" name="Country" value="Sri Lanka" autoComplete="off" />
                          </div>
                        </form>
                        <div className="checkout-btn text-right">
                          <button
                            onClick={this.continue}
                            className="save-address"
                            style={{
                              background:
                                localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                  ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                  : "#151b26",
                              margin: "20px 0 0 0",
                            }}
                          >
                            {this.state.loadingContinue ? (
                              <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : null}

                            {this.state.loadingContinue ? " Continuing..." : <span>Continue</span>}
                          </button>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                ) : null}
                {this.state.showMap ? (
                  <div className="row mx-0 mb-4">
                    <div className="col p-0">
                      <p className="mb-1 mb-3 mt-3" style={{ fontSize: 14, fontWeight: 600 }}>
                        Please move the pin to the correct location
                      </p>

                      <React.Fragment>
                        <div className="row mx-0 mb-4">
                          <div className="col p-0 border" style={{ height: 260 }}>
                            <this.WrappedMap
                              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC8EFXuIwNdJVSEzVOCIDJeCRM2Rhuj8VY`}
                              loadingElement={<div style={{ height: `100%` }} />}
                              containerElement={<div style={{ height: 260 }} />}
                              mapElement={<div style={{ height: `100%` }} />}
                            />
                          </div>
                        </div>

                        <div className="map-save-wrapper">
                          <div className="map-save-wrapper-inner">
                            <h6
                              className="go-back-map-view"
                              onClick={() =>
                                this.setState({
                                  showMap: false,
                                  showAddress: true,
                                  city: "",
                                  houseNo: "",
                                  street2: "",
                                })
                              }
                            >
                              Back
                            </h6>
                          </div>

                          <div className="map-save-wrapper-inner">
                            <button
                              onClick={this.addAddress}
                              className="save-address map-view"
                              style={{
                                background:
                                  localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                    ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                    : "#ff7068",
                              }}
                            >
                              {this.state.loading ? (
                                <div className="spinner-border spinner-border-sm text-light" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : null}

                              {this.state.loading ? " Saving..." : <span>Save</span>}
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <button type="button" hidden data-toggle="modal" data-target="#login-sign-up-modal" id="showGuestModal"></button>

        <div className="row m-0 footer-row">
          <div className="col p-0">
            <Footer restName={localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).name} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Guest);
