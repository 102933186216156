// import { remove } from "js-cookie";

// add to cart
export const addToCart = (cartItem, restId, addOns, portionSize, next) => {
  // create empty aray variable called cart
  let cart = [];

  // check window is not undefined
  if (typeof window !== "undefined") {
    if (localStorage.getItem(`cart_${restId}`)) {
      // get all cart items from local storage
      cart = JSON.parse(localStorage.getItem(`cart_${restId}`));
    }

    let addOnItems = [];

    for (const group of addOns) {
      const groupId = group.groupId;

      let addonItem = {};
      for (const item of group.items) {
        const newAddonitem = item;
        newAddonitem["groupId"] = groupId;
        addonItem = newAddonitem;
        addOnItems.push(addonItem);
      }
    }

    setTimeout(() => {
      cartItem["addOns"] = addOnItems;
      cartItem["portionSize"] = portionSize;

      if (cart.length > 0) {
        const item = cart.filter((el) => el.id === cartItem.id);

        if (item.length > 0) {
          const portionSizeDupli = cart.filter(
            (el) => el.portionSize.id === cartItem.portionSize.id
          );

          if (portionSizeDupli.length > 0) {
            let isAddonsDupli = true;

            portionSizeDupli.forEach((el, i) => {
              let obj1 = JSON.stringify(portionSizeDupli[i].addOns);
              let obj2 = JSON.stringify(cartItem.addOns);

              if (
                obj1 === obj2 &&
                el.id === cartItem.id &&
                el.portionSize.id === cartItem.portionSize.id
              ) {
                isAddonsDupli = false;

                cart.map((el) => {
                  if (
                    JSON.stringify(el.addOns) ===
                      JSON.stringify(cartItem.addOns) &&
                    el.id === cartItem.id &&
                    el.portionSize.id === cartItem.portionSize.id
                  ) {
                    el.count =
                      el.maxOrderQty >= el.count + cartItem.count
                        ? el.count + cartItem.count
                        : el.maxOrderQty;

                    return el;
                  } else {
                    return el;
                  }
                });
              }
            });

            if (isAddonsDupli) {
              cart.push(cartItem);
            }
          } else {
            cart.push(cartItem);
          }
        } else {
          cart.push(cartItem);
        }
      } else {
        cart.push(cartItem);
      }

      localStorage.setItem(`cart_${restId}`, JSON.stringify(cart));
      next();
    }, 100);
  }
};

// items Of Cart
export const itemsOfCart = (restId) => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem(`cart_${restId}`)) {
      // counts items
      const noOfItems = JSON.parse(
        localStorage.getItem(`cart_${restId}`)
      ).reduce((currentValue, nextValue) => {
        return +currentValue + +nextValue.count;
      }, 0);
      return noOfItems;
    } else {
      return 0;
    }
  }
};

// increase Cart Quntity
export const increaseCartQuntity = (id, restId) => {
  let cart = [];

  if (typeof window !== "undefined") {
    if (localStorage.getItem(`cart_${restId}`)) {
      cart = JSON.parse(localStorage.getItem(`cart_${restId}`));
    }

    cart.map((item, i) => {
      if (item.id === id) {
        cart[i].count += 1;
      }

      localStorage.setItem(`cart_${restId}`, JSON.stringify(cart));
    });
  }
};

// decrease Cart Quntity
export const decreaseCartQuntity = (id, restId) => {
  let cart = [];

  if (typeof window !== "undefined") {
    if (localStorage.getItem(`cart_${restId}`)) {
      cart = JSON.parse(localStorage.getItem(`cart_${restId}`));
    }

    cart.map((item, i) => {
      if (item.id === id) {
        cart[i].count -= 1;
      }

      localStorage.setItem(`cart_${restId}`, JSON.stringify(cart));
    });
  }
};

// remove a cart item
export const removeCartItem = (id, restId) => {
  // create empty aray variable called cart
  let cart = [];

  // check window is not undefined
  if (typeof window !== "undefined") {
    if (localStorage.getItem(`cart_${restId}`)) {
      // get all cart items from local storage
      cart = JSON.parse(localStorage.getItem(`cart_${restId}`));
    }

    // remove from cart using splice method
    cart.map((item, i) => {
      if (i === id) {
        cart.splice(i, 1);
      }

      // store cart on local storage
      localStorage.setItem(`cart_${restId}`, JSON.stringify(cart));
    });
  }
};

//clear whole cart
export const clearCart = (restId) => {
  localStorage.removeItem("deliveryNote");
  localStorage.removeItem("deliveryFee");
  localStorage.removeItem("deliveryAddress");
  localStorage.removeItem("tableId");
  localStorage.removeItem("totalExtraCost");
  localStorage.removeItem("extraCostData");
  localStorage.removeItem("cartSubTotal");
  localStorage.removeItem("selectedOrderTypeData");
  localStorage.removeItem("matchMinOrder");
  localStorage.removeItem("asap");
  localStorage.removeItem("expectedAt");
  localStorage.removeItem("guest_checkout");
  localStorage.removeItem("guest_details");
  localStorage.removeItem(`cart_${restId}`);
}