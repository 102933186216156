import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";

import ForgetPasswordModal from "./ForgetPasswordModal";

import "../styles/LoginSignup.css";

const initialState = {
  // login
  email: "",
  password: "",

  emailError: "",
  passwordError: "",

  // register
  name: "",
  regEmail: "",
  regPwd: "",
  regPwdConfirm: "",

  nameError: "",
  regEmailError: "",
  regPwdError: "",
  regPwdConfError: "",

  // alerts
  registerSuccess: false,
  loginSuccess: false,
  loginFail: false,
  emailNotVerified: false,
  showResendSuccess: false,
  showResendFail: false,

  // loading
  loading: false,
  loadingReg: false,

  redirectToUserDetail: false,
};

class LoginSignupModal extends Component {
  state = initialState;

  // handle inputs
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // validate
  validate = () => {
    let emailError = "";
    let passwordError = "";

    if (this.state.email === "") {
      emailError = "Email is required";
    }

    if (this.state.password === "") {
      passwordError = "Password is required";
    }

    if (emailError || passwordError) {
      this.setState({
        emailError,
        passwordError,
      });
      return false;
    }
    return true;
  };

  // login
  handleLogin = (e) => {
    e.preventDefault();

    if (this.validate()) {
      this.setState({
        registerSuccess: false,
        loginSuccess: false,
        loginFail: false,
        emailNotVerified: false,
        showResendSuccess: false,
        showResendFail: false,
      });

      // create user data object
      const loginData = {
        email: this.state.email,
        password: this.state.password,
        accountType: "email",
        clientId: "web",
      };

      // set loading
      this.setState({ loading: true, loginSuccess: false });

      // send post req to register an user
      axios
        .post(`${process.env.REACT_APP_API_URL}/user/login`, loginData)
        .then((res) => {
          if (document.location.host.includes("spritzer")) {
            document.cookie = `auth_token=${JSON.stringify(
              res.data
            )};domain=spritzer.app`;
            // document.cookie = `auth_token=${JSON.stringify(res.data)}`;
          } else {
            document.cookie = `auth_token=${JSON.stringify(res.data)}`;
          }

          axios
            .get(`${process.env.REACT_APP_API_URL}/user/profile`, {
              headers: {
                Authorization: `Bearer ${res.data.providerToken}`,
              },
            })
            .then((res) => {
              if (typeof window !== "undefined") {
                localStorage.setItem("profile", JSON.stringify(res.data));
              }

              this.setState({
                email: "",
                password: "",
                emailError: "",
                passwordError: "",
                loading: false,
                loginSuccess: true,
                loginFail: false,
                redirectToUserDetail: true,
              });

              document.getElementById("login-sign-up-modal").click();
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                emailError: "",
                passwordError: "",
                loading: false,
                loginFail: true,
                loginSuccess: false,
              });
            });
        })
        .catch((err) => {
          if (err.response.data.code === 1010) {
            this.setState({
              emailError: "",
              passwordError: "",
              loading: false,
              loginFail: true,
              loginSuccess: false,
            });
          } else if (err.response.data.code === 1011) {
            this.setState({
              emailError: "",
              passwordError: "",
              loading: false,
              emailNotVerified: true,
              loginFail: false,
              loginSuccess: false,
            });
          } else {
            this.setState({
              emailError: "",
              passwordError: "",
              loading: false,
              loginFail: true,
              loginSuccess: false,
            });
          }
        });
    }
  };

  // validate
  regValidate = () => {
    let nameError = "";
    let regEmailError = "";
    let regPwdError = "";
    let regPwdConfError = "";

    if (this.state.name === "") {
      nameError = "Name is required";
    }

    if (this.state.regEmail === "") {
      regEmailError = "Email is required";
    } else if (!this.state.regEmail.includes("@")) {
      regEmailError = "Email is invalid";
    }

    if (this.state.regPwd === "") {
      regPwdError = "Password is required";
    } else if (this.state.regPwd.length < 6) {
      regPwdError = "Password should be between 6 and 60 characters";
    }

    if (this.state.regPwdConfirm === "") {
      regPwdConfError = "Confirm password is required";
    } else if (this.state.regPwdConfirm !== this.state.regPwd) {
      regPwdConfError = "Password should be matched";
    }

    if (nameError || regEmailError || regPwdError || regPwdConfError) {
      this.setState({
        nameError,
        regEmailError,
        regPwdError,
        regPwdConfError,
      });
      return false;
    }
    return true;
  };

  // register
  handleRegister = (e) => {
    e.preventDefault();

    if (this.regValidate()) {
      // create user data object
      const user = {
        name: this.state.name,
        email: this.state.regEmail,
        password: this.state.regPwd,
        accountType: "email",
        isBusiness: false,
      };

      // set loading
      this.setState({ loadingReg: true, registerSuccess: false });

      // send post req to register an user
      axios
        .post(`${process.env.REACT_APP_API_URL}/user/register`, user)
        .then((res) => {
          axios
            .post(`${process.env.REACT_APP_API_URL}/user/login`, {
              email: this.state.regEmail,
              password: this.state.regPwd,
              accountType: "email",
              clientId: "web",
            })
            .then((res) => {
              if (document.location.host.includes("spritzer")) {
                document.cookie = `auth_token=${JSON.stringify(
                  res.data
                )};domain=spritzer.app`;
                // document.cookie = `auth_token=${JSON.stringify(res.data)}`;
              } else {
                document.cookie = `auth_token=${JSON.stringify(res.data)}`;
              }

              axios
                .get(`${process.env.REACT_APP_API_URL}/user/profile`, {
                  headers: {
                    Authorization: `Bearer ${res.data.providerToken}`,
                  },
                })
                .then((res) => {
                  if (typeof window !== "undefined") {
                    localStorage.setItem("profile", JSON.stringify(res.data));
                  }

                  this.setState({
                    redirectToUserDetail: true,
                  });
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));

          this.setState({
            name: "",
            regEmail: "",
            regPwd: "",
            regPwdConfirm: "",
            regEmailError: "",
            nameError: "",
            regPwdError: "",
            regPwdConfError: "",
            loadingReg: false,
            registerSuccess: true,
          });
        })
        .catch((err) => {
          if (err.response.data.code === 1004) {
            this.setState({
              regEmailError: "Email already exists",
              nameError: "",
              regPwdError: "",
              regPwdConfError: "",
              loadingReg: false,
            });
          }
        });
    }
  };

  resendEmailVerification = () => {
    console.log(this.state.email);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/user/activate/resend?email=${this.state.email}`
      )
      .then((res) => {
        console.log(res);
        this.setState({
          emailNotVerified: false,
          showResendSuccess: true,
          loginSuccess: false,
          loginFail: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          emailNotVerified: false,
          showResendFail: true,
          loginSuccess: false,
          loginFail: false,
        });
      });
  };

  closeModal = () => {
    this.setState(initialState);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.redirectToUserDetail ? (
          <Redirect to="/checkout/your-details" />
        ) : null}

        <div
          className="modal fade login-sign-up-modal"
          id="login-sign-up-modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="login signup modal"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header pb-0 pt-2" style={{ border: 0 }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState(this.closeModal)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md border-right">
                    <h5 className="heading">Login</h5>
                    {this.state.loginFail ? (
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#f00",
                          marginBottom: "5px",
                        }}
                      >
                        Email and/or password is incorrect.
                      </p>
                    ) : null}

                    {this.state.emailNotVerified ? (
                      <React.Fragment>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#f00",
                            marginBottom: "5px",
                          }}
                        >
                          Your email has not been verified. Please check your
                          inbox for the verification email or click "Resend" to
                          send it again.
                        </p>
                        <button
                          type="button"
                          onClick={this.resendEmailVerification}
                          style={{
                            background: "#2196F3",
                            color: "#fff",
                            border: 0,
                            borderRadius: 3,
                            margin: "auto",
                            padding: "3px 10px",
                            display: "block",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          Resend
                        </button>
                      </React.Fragment>
                    ) : null}

                    {this.state.showResendSuccess ? (
                      <p className="text-success text-center">
                        <i className="fas fa-check"> </i> Verification Mail
                        Sent.
                      </p>
                    ) : null}

                    {this.state.showResendFail ? (
                      <p className="text-danger text-center">
                        <i className="fas fa-times"> </i> Opps! Something Went
                        Wrong.
                      </p>
                    ) : null}
                    {this.state.loginSuccess === true ? (
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#009688",
                          marginBottom: "5px",
                        }}
                      >
                        Logged in Successfully.
                      </p>
                    ) : null}
                    <form noValidate>
                      <div className="form-group p-0">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className={classnames("form-control", {
                            "is-invalid": this.state.emailError,
                          })}
                          id="email"
                          name="email"
                          onChange={this.handleChange}
                          value={this.state.email}
                        />
                        <div className="invalid-feedback">
                          {this.state.emailError}
                        </div>
                      </div>
                      <div className="form-group p-0 mb-0">
                        <label htmlFor="pwd">Password</label>
                        <input
                          type="password"
                          className={classnames("form-control", {
                            "is-invalid": this.state.passwordError,
                          })}
                          id="pwd"
                          name="password"
                          onChange={this.handleChange}
                          value={this.state.password}
                        />
                        <div className="invalid-feedback">
                          {this.state.passwordError}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn mt-4"
                        onClick={this.handleLogin}
                      >
                        {this.state.loading ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}
                        {this.state.loading ? (
                          " Logging..."
                        ) : (
                          <span> Login</span>
                        )}
                      </button>
                      <p
                        className="text-center orange-text mt-2 mb-0"
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        data-toggle="modal"
                        data-target="#forgetpasswordModel"
                        onClick={() =>
                          document.getElementById("login-sign-up-modal").click()
                        }
                      >
                        Forgot your password?
                      </p>
                    </form>
                  </div>

                  <div className="col-md">
                    <h5 className="heading mt-5 mt-md-0">Register</h5>
                    {this.state.registerSuccess ? (
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#009688",
                          marginBottom: "5px",
                        }}
                      >
                        Registered in Successfully.
                      </p>
                    ) : null}
                    <form noValidate>
                      <div className="form-group p-0">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          className={classnames("form-control", {
                            "is-invalid": this.state.nameError,
                          })}
                          id="name"
                          name="name"
                          onChange={this.handleChange}
                          value={this.state.name}
                        />
                        <div className="invalid-feedback">
                          {this.state.nameError}
                        </div>
                      </div>
                      <div className="form-group p-0">
                        <label htmlFor="regEmail">Email</label>
                        <input
                          type="email"
                          className={classnames("form-control", {
                            "is-invalid": this.state.regEmailError,
                          })}
                          id="regEmail"
                          name="regEmail"
                          onChange={this.handleChange}
                          value={this.state.regEmail}
                        />
                        <div className="invalid-feedback">
                          {this.state.regEmailError}
                        </div>
                      </div>
                      <div className="form-group p-0">
                        <label htmlFor="regPwd">Password</label>
                        <input
                          type="password"
                          className={classnames("form-control", {
                            "is-invalid": this.state.regPwdError,
                          })}
                          id="regPwd"
                          name="regPwd"
                          onChange={this.handleChange}
                          value={this.state.regPwd}
                        />
                        <div className="invalid-feedback">
                          {this.state.regPwdError}
                        </div>
                      </div>
                      <div className="form-group p-0 mb-0">
                        <label htmlFor="regPwdConfirm">Confirm Password</label>
                        <input
                          type="password"
                          className={classnames("form-control", {
                            "is-invalid": this.state.regPwdConfError,
                          })}
                          id="regPwdConfirm"
                          name="regPwdConfirm"
                          onChange={this.handleChange}
                          value={this.state.regPwdConfirm}
                        />
                        <div className="invalid-feedback">
                          {this.state.regPwdConfError}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn mt-4"
                        onClick={this.handleRegister}
                      >
                        {this.state.loadingReg ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}
                        {this.state.loadingReg ? (
                          " Registering..."
                        ) : (
                          <span> Register</span>
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ForgetPasswordModal />
      </React.Fragment>
    );
  }
}

export default withRouter(LoginSignupModal);
