import { store } from "react-notifications-component";

export const successAlert = (text) => {
  return store.addNotification({
    title: "Success",
    message: text ? text : "Your operation has been done successfully",
    insert: "top",
    type: "success", // 'default', 'success', 'info', 'warning'
    container: "top-right", // where to position the notifications
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      pauseOnHover: true,
      click: true,
      showIcon: true,
    },
    isMobile: true,
  });
};

export const errorAllert = (text) => {
  return store.addNotification({
    title: "Error",
    message: text ? text : "Oops, Something went wrong.",
    insert: "top",
    type: "danger", // 'default', 'success', 'info', 'warning'
    container: "top-right", // where to position the notifications
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      pauseOnHover: true,
      click: true,
      showIcon: true,
    },
    isMobile: true,
  });
};

export const deletedAlert = (text) => {
  return store.addNotification({
    title: "Success",
    message: text ? text : "Deleted successfully.",
    insert: "top",
    type: "success", // 'default', 'success', 'info', 'warning'
    container: "top-right", // where to position the notifications
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      pauseOnHover: true,
      click: true,
      showIcon: true,
    },
    isMobile: true,
  });
};

export const warningAlert = (text) => {
  return store.addNotification({
    title: "Warning",
    message: text ? text : "Warning!",
    insert: "top",
    type: "warning", // 'default', 'success', 'info', 'warning'
    container: "top-right", // where to position the notifications
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      pauseOnHover: true,
      click: true,
      showIcon: true,
    },
    isMobile: true,
  });
};
