import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import OrderStatusNavbar from "../components/menu/OrderStatusNavbar";
import Footer from "../components/menu/Footer";
import TitleOfPage from "../components/TitleOfPage";
import "../styles/orderStatus.css";
import { getOrder } from "../components/api/orderStatus";

class OrderStatus extends Component {
  constructor() {
    super();
    this.state = {
      restaurant: {},
      orderId: null,
      orderEvents: [],
      status: null,
      statusNo: null,
      orderCreateDate: null,
      orderTotal: null,
      orderType: null,
      isError: false
    };
  }

  componentDidMount = async () => {
    try {
      const params = this.props.match.params;

      const restaurant = localStorage.getItem("restaurant")
        ? JSON.parse(localStorage.getItem("restaurant"))
        : {};
      getOrder(params.trackingid, 
        (res) => {
          const { orderEvents, status, orderId, createTime, total, orderType } = res.data;

          for (let index = 0; index < orderEvents.length; index++) {
            if (orderEvents[index].eventId === status) {
              this.setState({ statusNo: index });
            }
          }
    
          const orderDate = this.getDay(createTime);
    
          this.setState({
            restaurant: restaurant,
            orderEvents: orderEvents,
            status: status,
            orderId: orderId,
            orderCreateDate: orderDate,
            orderTotal: total,
            orderType: orderType
          });
        },
        (error) => {
          this.setState({ isError: true });
          throw error
        }
        )
    } catch (error) {
      this.setState({ isError: true });
      throw error;
    }
  };

  getDay = (createTime) => {
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    const orderDate = new Date(createTime);
    let day = weekday[orderDate.getDay()];
    let month = months[orderDate.getMonth()]
    return `${day} ${orderDate.getDate()} ${month}`
  }

  getTime = (eventTimes) => {
    const eventTime = new Date(eventTimes);
    return eventTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  }

  render() {
    const { restaurant, orderId, orderEvents, statusNo, orderCreateDate, orderTotal, orderType, isError } = this.state;
    const { name: restaurantName, id: restaurantId } = restaurant || {};

    return (
      <React.Fragment>
        <TitleOfPage title={!isError ? `Order status: ${orderId}` : "Order not found!"} />
        <div className="p-0">
          <OrderStatusNavbar
            restName={restaurantName}
            name={false}
            restId={restaurantId}
            restIsAvailable={false}
            displayOrderType={false}
            displayPickupType={false}
            displayMenuStore={true}
          />
          <div class="container min-height-viewport padding-top-bottom-25">
            <div className="order-status-container">
              <h4 className="order-status-resturant-name">{restaurantName}</h4>
              <div className="order-status-id">
                {!isError ?
                <><h5>{orderCreateDate}</h5>
                <h6>Order ID</h6>
                <h5>{orderId}</h5>
                <h6>Total</h6>
                <h5>{`Rs. ${orderTotal}`}</h5></> :
                <div className="error-msg">
                  <h5>Order not found!</h5>
                </div>
                }
              </div>
              <div class="order-status-timeline">
                <div className="time-line">
                <div className="icon-line">
                    {orderEvents?.map((item, id) =>
                      id + 1 !== orderEvents?.length ? (
                        id <= statusNo ? (
                          <i className={"fa fa-check-circle status-icon"} key={id}></i>
                        ) : (
                          <i className={"far fa-check-circle status-icon"} key={id}></i>
                        )
                      ) : id !== statusNo ? (
                        <i className="far fa-check-circle" key={id}></i>
                      ) : (
                        <i className="fa fa-check-circle" key={id}></i>
                      )
                    )}
                  </div>
                  <div className="detail-line">
                    {orderEvents?.map((item, id) => (
                      <div className="detail-box" key={id}>
                        {
                          item.eventId === "created" || item.eventId === "pending" ? (
                            <><span className="status">Order placed</span><br/>
                            <span className="date-time">{this.getTime(item.eventTime)}</span></>
                          ) : item.eventId === "accepted" ? (
                            <><span className="status">Preparing Order</span><br/>
                            <span className="date-time">{this.getTime(item.eventTime)}</span></>
                          ) : item.eventId === "dispatched" ? (
                            orderType === "delivery" ? (
                              <><span className="status">Out for delivery</span><br/>
                              <span className="date-time">{this.getTime(item.eventTime)}</span></>
                            ) : orderType === "pickup" ? (
                              <><span className="status">Ready for pickup</span><br/>
                              <span className="date-time">{this.getTime(item.eventTime)}</span></>
                            ) : orderType === "dineIn" ? (
                              <><span className="status">Order ready</span><br/>
                              <span className="date-time">{this.getTime(item.eventTime)}</span></>
                            ) : null
                          ) : item.eventId === "rejected" ? (
                            <><span className="status">Order rejected (Please contact restaurant)</span><br/>
                            <span className="date-time">{this.getTime(item.eventTime)}</span></>
                          ) : null
                        }
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 footer-row">
          <div className="col p-0">
            <Footer restName={restaurantName} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(OrderStatus);
