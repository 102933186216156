import React, { Component } from "react";
import ScheduleTimes from "../menu/ScheduleTimes";
import moment from "moment";
import { getAvailableOrderScheduleTime } from "../api/CheckoutAPI";
import "../../styles/home.css";

import { returnTimesInBetween } from "../../methods/getSchedule";

class ManageOrderTimePreOrder extends Component {
  constructor(props) {
    super();
    this.state = {
      listingId: localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).id,
      acceptDays: [],
      orderAcceptingHours: [],
      orderAcceptingHoursByDay: [],
      expectedAt: "",
      orderDate: "",
      scheduleTime: "",

      invalidTimeError: "",
      invalidDateError: "",
      isTimeAvailable: false,
    };
  }

  componentDidMount = () => {
    if (localStorage.getItem("expectedAt")) {
      const tempExpect = moment(localStorage.getItem("expectedAt")).format("YYYY MM DD");
      const tempExpectTime = moment(localStorage.getItem("expectedAt")).format("HH:mm");
      this.setState({
        expectedAt: localStorage.getItem("expectedAt"),
        orderDate: tempExpect,
        scheduleTime: tempExpectTime,
      });
    } else {
      localStorage.setItem("expectedAt", "");
    }

    if (localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).deliveryDetail && JSON.parse(localStorage.getItem("restaurant")).deliveryDetail.orderAcceptingHours) {
      this.setState({
        orderAcceptingHours: localStorage.getItem("restaurant")
          ? JSON.parse(localStorage.getItem("restaurant")).deliveryDetail
            ? JSON.parse(localStorage.getItem("restaurant")).deliveryDetail.orderAcceptingHours
            : []
          : [],
      });
    }

    let acceptDays = [];
    let day;

    for (let i = 0; i < 14; i++) {
      day = moment()
        .add(`${[i]}`, "days")
        .calendar("YYYY/MM/DD");
      acceptDays = [...acceptDays, day];
    }

    this.setState({ acceptDays });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.expectedAt !== undefined && nextProps.expectedAt !== prevState.expectedAt) {
      return { expectedAt: nextProps.expectedAt };
    }
    return null;
  }

  selectASAP = () => {
    this.setState({
      expectedAt: "",
    });
    localStorage.setItem("expectedAt", "");
  };

  schedule = () => {
    if (this.state.orderDate && this.state.scheduleTime) {
      if (this.state.invalidTimeError === "") {
        // let expectedAt = `${this.state.orderDate} ${this.state.scheduleTime}:00.0`;
        // expectedAt = moment(expectedAt).format("YYYY-MM-DD HH:mm:ss.0"););

        // this.setState({
        //   expectedAt: expectedAt,
        // });
        const { expectedAt } = this.state;
        localStorage.setItem("expectedAt", expectedAt);
        localStorage.setItem("asap", false);
        this.props.handleOrderShedule(expectedAt);
        document.getElementById("schedulePreOrderClose").click();
      }
    } else {
      this.setState({
        invalidTimeError: "Please fill all fields",
      });
    }
  };

  handleScheduleTime = (e) => {
    // if (this.state.orderDate !== "") {
    //   this.setState({ scheduleTime: e.target.value });

    //   let expectedAt = `${this.state.orderDate} ${e.target.value}:00.0`;
    //   expectedAt = moment(expectedAt).format("YYYY-MM-DD HH:mm:ss.0");

    //   const listingId = this.state.listingId ? this.state.listingId : localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).id;

    //   getAvailableOrderScheduleTime(
    //     expectedAt,
    //     listingId,
    //     (data) => {
    //       if (data.value === true) {
    //         this.setState({ invalidTimeError: "", isTimeAvailable: true });
    //       } else {
    //         this.setState({
    //           invalidTimeError: "The restaurant is not accepting given time",
    //           isTimeAvailable: false,
    //         });
    //       }
    //     },
    //     (error) => {
    //       console.log(error);
    //       this.setState({
    //         invalidTimeError: `The restaurant is not accepting given date and/or time. Error code: ${error.code}`,
    //         isTimeAvailable: false,
    //       });
    //     }
    //   );
    // } else {
    //   this.setState({
    //     invalidDateError: "Please select a day first",
    //     isTimeAvailable: false,
    //   });
    // }

    const listing = JSON.parse(localStorage.getItem("restaurant"))
    if (!Boolean(this.state.orderDate) || !listing) {
      this.setState({
        invalidDateError: "Please select a day first",
        isTimeAvailable: false,
      })
      return;
    }
    
    const currentYear = moment().get("year");
    const orderTime = e.target.value;
    const orderDate = moment(this.state.orderDate).set("year", currentYear).format("YYYY/MM/DD");
    const orderDateTime = moment(`${orderDate} ${orderTime}`, ["YYYY/MM/DD HH:mm A"]);
    const expectedAt = orderDateTime.format("YYYY-MM-DD HH:mm:ss.0"); 
    getAvailableOrderScheduleTime(
      expectedAt,
      listing.id,
      (data) => {
        if (data.value) {
          this.setState({
            scheduleTime: orderTime,
            invalidTimeError: "",
            isTimeAvailable: true,
            expectedAt: expectedAt,
          })
        } else {
          this.setState({
            scheduleTime: orderTime,
            invalidTimeError: "The restaurant is not accepting given time",
            isTimeAvailable: false,
            expectedAt: null,
          })
        }
      },
      (error) => {
        console.log(">>>> error >>>> ", error);
        this.setState({
          scheduleTime: orderTime,
          invalidTimeError: "Error occurred, try again",
          isTimeAvailable: false,
        });
      }
    );
  };

  // handle inputs
  handleChange = (e) => {
    const selectedDate = e.target.value;
    const dayName = moment(selectedDate).format("dddd");

    const orderAcceptingDurations = Array.isArray(this.state.orderAcceptingHours) 
      && this.state.orderAcceptingHours.filter((obj) => obj.day === dayName);
    const timeslots = [];
    orderAcceptingDurations.forEach((def) => {
      const startTime = moment(def.openTime, ["HHmm"]);
      const endTime = moment(def.closeTime, ["HHmm"]);
      let tempTime = startTime;
      while (endTime.isAfter(tempTime) ) {
        timeslots.push(tempTime.format("HHmm"));
        tempTime = tempTime.add(15, "minutes");
      }
    });
    this.setState({
      orderDate: selectedDate,
      scheduleTime: "",
      isTimeAvailable: false,
      invalidDateError: "",
      invalidTimeError: "",
      orderAcceptingHoursByDay: timeslots
    }); 

    // const findDay = this.state.orderAcceptingHours.length > 0 && this.state.orderAcceptingHours.filter((el) => el.day === day);

    // if (findDay.length > 0) {
    //   let orderAcceptingHoursByDay = [];

    //   findDay.forEach((el) => {
    //     orderAcceptingHoursByDay = orderAcceptingHoursByDay.concat(returnTimesInBetween(el.openTime, el.closeTime));
    //   });

    //   this.setState({ orderAcceptingHoursByDay });
    // } else {
    //   this.setState({ orderAcceptingHoursByDay: [] });
    // }

    // this.setState({
    //   [e.target.name]: e.target.value,
    //   scheduleTime: "",
    //   isTimeAvailable: false,
    //   invalidDateError: "",
    //   invalidTimeError: "",
    // });
  };

  cancelSchedule = () => {
    this.setState({
      orderDate: "",
      scheduleTime: "",
      expectedAt: "",
    });
    localStorage.setItem("expectedAt", "");
    localStorage.setItem("asap", true);
    this.props.handleOrderShedule("");
  };

  isClosed = (day, index, nonAvailableDays) => {
    if (this.state.orderAcceptingHours.length > 0) {
      const isDays = this.state.orderAcceptingHours.filter((el) => el.day === moment(day).format("dddd"));

      if (isDays.length > 0) {
        if (index < nonAvailableDays) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  render() {
    const cartItems = JSON.parse(localStorage.getItem(`cart_${this.state.listingId}`));    
    let maxPreOrderHours = 0;
    if (Array.isArray(cartItems)) {
      maxPreOrderHours = Math.max(...cartItems.map((item) => item.preOrderHours || 0));
    }
    const preOrderDayThreshold = moment.duration(maxPreOrderHours, "hours").days();

    return (
      <div>
        <div
          className="modal fade"
          id="schedulePreOrder"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered date-time-picker-modal"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="m-0">Pick a time to get your order</h6>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="schedulePreOrderClose"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body p-0">
                <div className="form-group p-0 mt-4 ">
                  <h6 className="schedule-text">
                    Minimum pre-order requirement: {preOrderDayThreshold} days
                  </h6>
                  <p
                    style={{
                      fontSize: "80%",
                      color: "#dc3545",
                      margin: "8px 0 8px 0",
                      textAlign: "center",
                    }}
                  >
                    {this.state.invalidDateError}
                  </p>
                  <select
                    className="form-control custom-select schedule-date"
                    name="orderDate"
                    value={this.state.orderDate}
                    onChange={this.handleChange}
                  >
                    <option value="" selected hidden>
                      - Select a Day -
                    </option>
                    {/* {this.state.acceptDays &&
                      this.state.acceptDays.map((day, i) => (
                        <option key={i} value={moment(day).format("YYYY-MM-DD")} disabled={this.isClosed(day, i, maxPreorderHours.preOrderHours / 24)}>
                          {moment(day).format("dddd - DD MMM")}
                          {this.isClosed(day, i, maxPreorderHours.preOrderHours / 24) && i < maxPreorderHours.preOrderHours / 24 ? " - Not available" : ""}
                        </option>
                      ))} */}
                    {Array.isArray(this.state.acceptDays) &&
                      this.state.acceptDays.map((date) => {
                        const dateDayName = moment(date).format("dddd");
                        const { orderAcceptingHours } = this.state;
                        let resturarntStatus = "";
                        if (Array.isArray(orderAcceptingHours)) {
                          const dateOrderingDef = orderAcceptingHours.find(
                            (def) => String(def.day) === dateDayName
                          );
                          if (dateOrderingDef === undefined) {
                            resturarntStatus = "Closed";
                          } else {
                            if (Boolean(preOrderDayThreshold)) {
                              const today = moment();
                              const nextAvailableDay = today.add(
                                preOrderDayThreshold,
                                "day"
                              );
                              const isNotAvailable =
                                moment(date).isBefore(nextAvailableDay);
                              resturarntStatus = isNotAvailable
                                ? "Not Available"
                                : "";
                            }
                          }
                        }
                        let label = `${moment(date).format("dddd - DD MMM")}`;
                        label += resturarntStatus
                          ? ` - ${resturarntStatus}`
                          : "";
                        return (
                          <option
                            key={`order-date-${date}`}
                            value={moment(date).format("YYYY-MM-DD")}
                            disabled={Boolean(resturarntStatus)}
                          >
                            {label}
                          </option>
                        );
                      })}
                  </select>

                  <select
                    className={`form-control custom-select mt-3 schedule-date ${
                      this.state.orderDate === ""
                        ? "disabled-select-option"
                        : ""
                    }`}
                    name="scheduleTime"
                    value={this.state.scheduleTime}
                    onChange={this.handleScheduleTime}
                    disabled={!Boolean(this.state.orderDate)}
                  >
                    <option selected hidden value="">
                      - Select a Time -
                    </option>
                    {/* {this.state.orderAcceptingHoursByDay.length > 0 ? (
                      this.state.orderAcceptingHoursByDay.map((el, i) => (
                        <option value={el.format24} key={i}>
                          {el.format12}
                        </option>
                      ))
                    ) : (
                      <ScheduleTimes />
                    )} */}
                    {Array.isArray(this.state.orderAcceptingHoursByDay) &&
                    this.state.orderAcceptingHoursByDay.length ? (
                      this.state.orderAcceptingHoursByDay.map((element) => {
                        return (
                          <option key={element} value={element}>
                            {moment(element, ["HHmm"]).format("hh:mm A")}
                          </option>
                        );
                      })
                    ) : (
                      <ScheduleTimes />
                    )}
                  </select>

                  <p
                    style={{
                      width: "80%",
                      fontSize: "80%",
                      color: "#dc3545",
                      margin: "auto",
                      marginTop: 5,
                    }}
                  >
                    {this.state.invalidTimeError}
                  </p>
                </div>

                <div className="text-center">
                  <button
                    className="schedule-btn"
                    onClick={this.schedule}
                    disabled={this.state.isTimeAvailable === false}
                    style={{
                      background:
                        localStorage.getItem("restaurant") &&
                        JSON.parse(localStorage.getItem("restaurant"))
                          .branding &&
                        JSON.parse(localStorage.getItem("restaurant")).branding
                          .colorAccent
                          ? JSON.parse(localStorage.getItem("restaurant"))
                              .branding.colorAccent
                          : "#f26666",
                    }}
                  >
                    Schedule
                  </button>
                  <button
                    className="cancel-schedule-btn"
                    onClick={this.cancelSchedule}
                  >
                    Cancel the Schedule
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageOrderTimePreOrder;
