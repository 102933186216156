import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import EmailComponent from "./EmailContinue";
import LoginComponent from "./Login";
import RegisterComponent from "./Register";
import "../../styles/LoginSignup.css";

class PopupWindow extends Component {
  constructor() {
    super();
    this.state = {
      isEmailActive: true,
      isLoginActive: false,
      isRegisterActive: false,
      currentUserEmail: "",
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isEmailActive: true,
        isLoginActive: false,
        isRegisterActive: false,
      });
    }
  }

  handleEmailScreenChange = () => {
    this.setState({
      isLoginActive: true,
      isEmailActive: false,
    });
  };

  handleLoginScreenChange = () => {
    this.setState({
      isEmailActive: false,
      isLoginActive: false,
      isRegisterActive: true,
    });
  };

  handleRegisterScreenChange = () => {
    this.setState({
      isEmailActive: false,
      isLoginActive: true,
      isRegisterActive: false,
    });
  };

  setCurrentUserEmail = (email) => {
    this.setState({
      currentUserEmail: email,
    });
  };

  handleCloseLoginModal = () => {
    this.setState({
      isEmailActive: true,
      isLoginActive: false,
      isRegisterActive: false,
    });
  };

  render() {
    const { isEmailActive, isLoginActive, isRegisterActive } = this.state;
    const currentTitle =
      isEmailActive && this.props.history.location.pathname !== "/cart"
        ? "Enter Email to Continue"
        : isEmailActive && this.props.history.location.pathname === "/cart"
        ? "How do you want to continue?"
        : isLoginActive
        ? "Login with Spritzer"
        : "Register with Spritzer";
    return (
      <div className="login-register-popup-main" ref={this.setWrapperRef}>
        <div
          className="modal fade login-sign-up-modal"
          id="login-sign-up-modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="login signup modal"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content login-reg-popup">
              <div className="modal-header pb-0 pt-2">
                <h6 className="update-bank-model-heading">{currentTitle}</h6>
                <button
                  type="button"
                  className="close"
                  id="login-sign-up-modal-close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  aria-label="Close"
                  onClick={this.handleCloseLoginModal}
                >
                  <span aria-hidden="true" className="close-cart">
                    &times;
                  </span>
                </button>
              </div>

              {/* Spritzer Note */}

              {!isEmailActive && isLoginActive ? (
                <h6 className="spritzer-note">
                  You have ordered from a restaurant powered by Spritzer before.
                  Would you like to use the same email and password?
                </h6>
              ) : null}

              {!isEmailActive && isRegisterActive ? (
                <h6 className="spritzer-note">
                  When you create an account with a restaurant powered by
                  Spritzer, you will be able to use the same account to make
                  orders with any restaurant powered by Spritzer.
                </h6>
              ) : null}

              {/* Login Fail Note */}
              {this.props.loginFail ? (
                <p className="login-notify">
                  <i className="fas fa-info-circle"></i>Email and/or password is
                  incorrect.
                </p>
              ) : null}

              {/* Email not verified Note */}
              {this.props.emailNotVerified ? (
                <React.Fragment>
                  <p className="login-notify">
                    <i className="fas fa-info-circle"></i>Your email has not
                    been verified. Please check your inbox for the verification
                    email or click "Resend" to send it again.
                  </p>
                  <buttonc
                    type="button"
                    className="resend-btn"
                    onClick={this.props.resendEmailVerification()}
                  >
                    Resend
                  </buttonc>
                </React.Fragment>
              ) : null}

              {/* Verification Mail Sent Note */}
              {this.props.showResendSuccess ? (
                <p className="text-success text-center">
                  <i className="fas fa-check"></i> Verification Mail Sent.
                </p>
              ) : null}

              {/* Error Note */}
              {this.props.showResendFail ? (
                <p className="text-danger text-center">
                  <i className="fas fa-times"></i> Opps! Something Went Wrong.
                </p>
              ) : null}

              {/* Login Success Note */}
              {this.props.loginSuccess === true ? (
                <p className="login-notify-success">
                  <i className="fas fa-check"></i> Logged in Successfully.
                </p>
              ) : null}

              {/* Registered Successfully Note */}
              {this.props.registerSuccess ? (
                <p className="login-notify-success">
                  <i className="fas fa-check"></i> Registered in Successfully.
                </p>
              ) : null}

              <div className="modal-body">
                {isEmailActive && (
                  <EmailComponent
                    containerRef={(ref) => (this.current = ref)}
                    handleEmailScreenChange={this.handleEmailScreenChange}
                    handleLoginScreenChange={this.handleLoginScreenChange}
                    setCurrentUserEmail={this.setCurrentUserEmail}
                  />
                )}

                {isLoginActive && (
                  <LoginComponent
                    containerRef={(ref) => (this.current = ref)}
                    handleLoginFn={this.props.handleLogin}
                    handleLoginScreenChange={this.handleLoginScreenChange}
                    currentUserEmail={this.state.currentUserEmail}
                    loadingProgress={this.props.loadingProgress}
                  />
                )}

                {isRegisterActive && (
                  <RegisterComponent
                    containerRef={(ref) => (this.current = ref)}
                    handleRegisterScreenChange={this.handleRegisterScreenChange}
                    currentUserEmail={this.state.currentUserEmail}
                    handleRegisterFn={this.props.handleRegister}
                    loadingProgressReg={this.props.loadingProgressReg}
                  />
                )}
              </div>

              {!isEmailActive ? (
                <div className="login-register-popup-footer">
                  <h6>
                    By continuing your are agreeing to the Spritzer
                    <span>
                      {" "}
                      <a
                        href="https://www.spritzer.app/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </span>{" "}
                    and
                    <span>
                      {" "}
                      <a
                        href="https://www.spritzer.app/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </a>
                    </span>
                    . For more details please visit
                  </h6>

                  {process.env.REACT_APP_API_URL.includes("dev") ? (
                    <a
                      href="http://www-dev.spritzer.app/user-login"
                      target="_blank"
                    >
                      www-dev.spritzer.app/user-login
                    </a>
                  ) : (
                    <a
                      href="http://www.spritzer.app/user-login"
                      target="_blank"
                    >
                      www.spritzer.app/user-login
                    </a>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(PopupWindow);
