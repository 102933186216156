import React, { Component } from "react";
import classnames from "classnames";
import { Link, Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactCodeInput from "react-verification-code-input";
import Footer from "../menu/Footer";
import { submitUserDetailsAPI, handleDeliveryAddress, getUserProfile, phonenumberVerify, phonenumberVerifyOTP, updatePhonenumber } from "../api/UserDetailsAPI";
import Navbar from "../menu/Navbar";

import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import Geocode from "react-geocode";

import ManageOrderTime from "../orderManage/ManageOrderTime";
import ManageOrderType from "../orderManage/ManageOrderType";
import { errorAllert } from "../../methods/Alert";
import ManageOrderTimeOnResClosed from "../orderManage/ManageOrderTimeOnResClosed";
import ManageOrderTimePreOrder from "../orderManage/ManageOrderTimePreOrder";
import moment from "moment";

Geocode.setApiKey("AIzaSyC8EFXuIwNdJVSEzVOCIDJeCRM2Rhuj8VY");
Geocode.enableDebug();

export default class YourDetails extends Component {
  state = {
    name: localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")).name : "",
    oldPhone: localStorage.getItem("profile") ? (JSON.parse(localStorage.getItem("profile")).phone !== undefined ? JSON.parse(localStorage.getItem("profile")).phone : "") : "",
    phone: localStorage.getItem("profile") ? (JSON.parse(localStorage.getItem("profile")).phone !== undefined ? JSON.parse(localStorage.getItem("profile")).phone : "") : "",
    houseNo: "",
    street2: "",
    city: "",
    address: "",
    addresses: localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")).deliveryAddresses : [],
    latitude: 6.9271,
    longitude: 79.8612,
    note: localStorage.getItem("deliveryNote") ? localStorage.getItem("deliveryNote") : "",
    currency: {},

    phoneId: "",
    addressId: localStorage.getItem("deliveryAddress") ? localStorage.getItem("deliveryAddress") : "",

    nameError: "",
    mobileError: "",
    addressError: "",

    showPhone: true,
    showModal: false,
    showAddress: true,
    showMap: false,
    EnterValidAddErr: false,

    userName: localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).name,
    restId: localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).id,
    restName: localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).name,
    redirectToCheckout: false,

    // alerts
    errorAlert: false,

    errorAlertHeading: "",
    errorAlertText: "",

    // alert
    loading: false,
    loadingContinue: false,
    suceessAddAddress: false,
    loadingVerify: false,
    loadingOTP: false,

    toggleRerender: false,
    loadingContinueToCheckout: false,
    charsLeft: 1000,
    cities: [],
    expectedAt: "",
    isResAvailable: true,
  };

  componentDidMount = () => {
    let noOfshadow = document.querySelectorAll(".modal-backdrop").length;
    let restData = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant"));

    for (let i = 0; i < noOfshadow; i++) {
      document.querySelectorAll(".modal-backdrop")[i].style.display = "none";
    }

    this.setState({
      isResAvailable: restData.deliveryDetail.isAvailable,
      currency: restData.currency,
    });

    window.scrollTo(0, 0);
  };

  // get chnage values
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // get chnage values
  handleAdditionalInfoChange = (e) => {
    let inputVal = e.target.value;
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ charsLeft: 1000 - inputVal.length });
  };

  // get address
  handleChangeAddress = (e) => {
    this.setState({ addressId: e.target.value });
    localStorage.setItem("deliveryAddress", e.target.value);
  };

  // validate
  validateAddress = () => {
    let houseNoError = "";
    let cityError = "";

    if (this.state.houseNo === "") {
      houseNoError = "House No and name is required";
    }

    if (this.state.city === "") {
      cityError = "City is required";
    }

    if (houseNoError || cityError) {
      this.setState({
        houseNoError,
        cityError,
      });
      return false;
    }
    return true;
  };

  continue = (e) => {
    e.preventDefault();

    if (this.validateAddress()) {
      this.setState({
        houseNoError: "",
        cityError: "",
        EnterValidAddErr: false,
      });

      this.setState({ loadingContinue: true });

      const google = window.google;
      let service = new google.maps.Geocoder();

      service.geocode(
        {
          address: `${this.state.houseNo} ${this.state.street2}, ${this.state.city} }`,
          componentRestrictions: {
            country: "LK",
          },
        },
        (geocoderResult, status) => {
          if (status === "OK") {
            this.setState({
              latitude: geocoderResult[0].geometry.location.lat(),
              longitude: geocoderResult[0].geometry.location.lng(),
            });

            this.WrappedMap = withScriptjs(
              withGoogleMap((props) => (
                <GoogleMap
                  defaultZoom={16}
                  defaultCenter={{
                    lat: geocoderResult[0].geometry.location.lat(),
                    lng: geocoderResult[0].geometry.location.lng(),
                  }}
                >
                  <Marker
                    position={{
                      lat: geocoderResult[0].geometry.location.lat(),
                      lng: geocoderResult[0].geometry.location.lng(),
                    }}
                    draggable={true}
                    onDragEnd={this.onMarkerDragEnd}
                  />
                </GoogleMap>
              ))
            );

            this.setState({
              showMap: true,
              showAddress: false,
              loadingContinue: false,
            });
          } else {
            // if google returns an error for the address try once without line2 and once without the city(if line 2 is not null). SPR-118
            if (status === "ZERO_RESULTS") {
              service.geocode(
                {
                  address: `${this.state.houseNo} ${""}, ${this.state.city} }`,
                  componentRestrictions: {
                    country: "LK",
                  },
                },
                (geocoderResult, status) => {
                  if (status === "OK") {
                    this.setState({
                      latitude: geocoderResult[0].geometry.location.lat(),
                      longitude: geocoderResult[0].geometry.location.lng(),
                    });

                    this.WrappedMap = withScriptjs(
                      withGoogleMap((props) => (
                        <GoogleMap
                          defaultZoom={16}
                          defaultCenter={{
                            lat: geocoderResult[0].geometry.location.lat(),
                            lng: geocoderResult[0].geometry.location.lng(),
                          }}
                        >
                          <Marker
                            position={{
                              lat: geocoderResult[0].geometry.location.lat(),
                              lng: geocoderResult[0].geometry.location.lng(),
                            }}
                            draggable={true}
                            onDragEnd={this.onMarkerDragEnd}
                          />
                        </GoogleMap>
                      ))
                    );

                    this.setState({
                      showMap: true,
                      showAddress: false,
                      loadingContinue: false,
                    });
                  } else {
                    if (this.state.street2 !== "") {
                      service.geocode(
                        {
                          address: `${this.state.houseNo} ${this.state.street2} }`,
                          componentRestrictions: {
                            country: "LK",
                          },
                        },
                        (geocoderResult, status) => {
                          if (status === "OK") {
                            this.setState({
                              latitude: geocoderResult[0].geometry.location.lat(),
                              longitude: geocoderResult[0].geometry.location.lng(),
                            });

                            this.WrappedMap = withScriptjs(
                              withGoogleMap((props) => (
                                <GoogleMap
                                  defaultZoom={16}
                                  defaultCenter={{
                                    lat: geocoderResult[0].geometry.location.lat(),
                                    lng: geocoderResult[0].geometry.location.lng(),
                                  }}
                                >
                                  <Marker
                                    position={{
                                      lat: geocoderResult[0].geometry.location.lat(),
                                      lng: geocoderResult[0].geometry.location.lng(),
                                    }}
                                    draggable={true}
                                    onDragEnd={this.onMarkerDragEnd}
                                  />
                                </GoogleMap>
                              ))
                            );

                            this.setState({
                              showMap: true,
                              showAddress: false,
                              loadingContinue: false,
                            });
                          } else {
                            this.setState({ EnterValidAddErr: true, loadingContinue: false });
                          }
                        }
                      );
                    }
                  }
                }
              );
            }
            this.setState({ EnterValidAddErr: true, loadingContinue: false });
          }
        }
      );
    }
  };

  addAddress = (e) => {
    e.preventDefault();

    const addressData = {
      line1: this.state.houseNo,
      line2: this.state.street2,
      city: this.state.city,
      country: "Sri Lanka",
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };

    this.setState({ loading: true });

    handleDeliveryAddress(
      addressData,
      (data) => {
        this.setState({ addressId: data.id });
        localStorage.setItem("deliveryAddress", data.id);

        getUserProfile(
          (profileData) => {
            if (typeof window !== "undefined") {
              localStorage.setItem("profile", JSON.stringify(profileData));
            }

            this.setState({
              addresses: profileData.deliveryAddresses,
              houseNo: "",
              street2: "",
              city: "",
              loading: false,
              showMap: false,
              showAddress: true,
              EnterValidAddErr: false,
              suceessAddAddress: true,
            });

            document.getElementById("addressModal").click();
          },

          (error) => {
            this.setState({
              loading: false,
              suceessAddAddress: false,
            });
            console.log(error);
            errorAllert(`Something went wrong please try again! Error code: ${error.response.data.code}`);
          }
        );
      },

      (error) => {
        this.setState({
          loading: false,
          suceessAddAddress: false,
        });
        console.log(error);
        errorAllert(`Something went wrong please try again! Error code: ${error.response.data.code}`);
      }
    );
  };

  // validate
  mobileValidate = () => {
    let mobileError = "";

    let newPhone = this.state.phone ? this.state.phone.replace(/\s/g, "") : "";

    if (newPhone === "") {
      mobileError = "Mobile is required";
    } else if (newPhone.length < 11) {
      mobileError = "Mobile is invalid";
    }

    if (mobileError) {
      this.setState({
        mobileError,
      });
      return false;
    }
    return true;
  };

  sendToVerify = () => {
    if (this.mobileValidate()) {
      this.setState({ loadingVerify: true });

      phonenumberVerify(
        this.state.phone,
        (data) => {
          this.setState({
            showPhone: false,
            showModal: true,
            phoneId: data.id,
            loadingVerify: false,
          });
        },
        (error) => {
          this.setState({
            loadingVerify: false,
          });
        }
      );
    }
  };

  verify = (code) => {
    this.setState({ loadingOTP: true });

    phonenumberVerifyOTP(
      code,
      this.state.phoneId,
      (data) => {
        // update phone number
        updatePhonenumber(
          this.state.phoneId,
          (data) => {
            getUserProfile(
              (data) => {
                if (typeof window !== "undefined") {
                  localStorage.setItem("profile", JSON.stringify(data));
                }

                this.setState({
                  oldPhone: data.phone,
                  showPhone: true,
                  showModal: false,
                  loadingOTP: false,
                });
                document.getElementById("phoneModal").click();
              },

              (error) => {
                console.log(error);
              }
            );
          },

          (error) => {
            console.log(error);
          }
        );
      },

      (error) => {
        this.setState({
          verificationError: `Verification code is invalid. Error code: ${error.response.data.code}`,
          loadingOTP: false,
        });
      }
    );
  };

  // validate
  validate = () => {
    let nameError = "";
    let mobileError = "";
    let addressError = "";

    let newPhone = this.state.phone ? this.state.phone.replace(/\s/g, "") : "";

    if (this.state.name === "") {
      nameError = "Name is required";
    }

    if (localStorage.getItem("orderType") === "delivery") {
      if (this.state.addressId === "" || this.state.addressId === "- Select Delivery Address -") {
        addressError = "Delivery address is required";
      }
    }

    if (newPhone === "") {
      mobileError = "Mobile is required";
    } else if (newPhone.length < 11) {
      mobileError = "Mobile is invalid";
    }

    if (nameError || mobileError || addressError) {
      this.setState({
        nameError,
        mobileError,
        addressError,
      });
      return false;
    }
    return true;
  };

  submitUserDetails = (e) => {
    e.preventDefault();
    if (this.validate()) {
      if (localStorage.getItem("orderType") === "delivery") {
        this.setState({ loadingContinueToCheckout: true });
        this.handlesubmitUserDetails();
      } else {
        localStorage.removeItem("deliveryAddress");
        localStorage.removeItem("deliveryFee");
        localStorage.removeItem("deliveryNote");
        localStorage.setItem("deliveryNote", this.state.note);
        this.setState({
          redirectToCheckout: true,
          loadingContinueToCheckout: false,
        });
      }
    }
  };

  async handlesubmitUserDetails() {
    try {
      let res = await submitUserDetailsAPI(this.state.restId, this.state.addressId);

      if (res && res.status === 200) {
        // store
        localStorage.setItem("deliveryFee", res.data.price);
        localStorage.removeItem("deliveryNote");
        localStorage.setItem("deliveryNote", this.state.note);
        localStorage.setItem("deliveryAddress", this.state.addressId);

        this.setState({
          redirectToCheckout: true,
          loadingContinueToCheckout: false,
        });
      }
    } catch (error) {
      if (error.response.data.code === 1067) {
        this.setState({ errorAlert: true });
        this.setState({
          errorAlertHeading: "Delivery location is too far from Restaurant",
          errorAlertText: `Listing does not deliver to this location. Error code: ${error.response.data.code}`,
          loadingContinueToCheckout: false,
        });
      }
      if (error.response.data.code === 400) {
        errorAllert(`Something went wrong please try again! Error code: ${error.response.data.code}`);
        this.setState({ loadingContinueToCheckout: false });
      } else {
        errorAllert("Something went wrong please try again!");
      }
    }
  }

  // marker drag and drop
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    console.log(newLat, newLng);

    Geocode.fromLatLng(newLat, newLng)
      .then((res) => {
        this.setState({
          latitude: newLat,
          longitude: newLng,
        });

        this.WrappedMap = withScriptjs(
          withGoogleMap((props) => (
            <GoogleMap
              defaultZoom={16}
              defaultCenter={{
                lat: this.state.latitude,
                lng: this.state.longitude,
              }}
            >
              <Marker
                position={{
                  lat: this.state.latitude,
                  lng: this.state.longitude,
                }}
                draggable={true}
                onDragEnd={this.onMarkerDragEnd}
              />
            </GoogleMap>
          ))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  closeModal = () => {
    this.setState({
      suceessAddAddress: false,
      houseNo: "",
      street2: "",
      city: "",
      houseNoError: "",
      cityError: "",
      showAddress: true,
      showMap: false,
      EnterValidAddErr: false,
    });
  };

  // close modal
  close = () => {
    this.setState({
      errorAlert: false,
      errorAlertHeading: "",
      errorAlertText: "",
    });
  };

  rerenderParentCallback = () => {
    this.setState({ toggleRerender: !this.state.toggleRerender });
  };

  resetDeliveryAddress = () => {
    this.setState({ addressId: "" });
  };

  handleCities = (e) => {
    const google = window.google;
    let service = new google.maps.places.AutocompleteService();
    if (e.target.value) {
      service.getPlacePredictions(
        {
          input: e.target.value,
          types: ["geocode"],
          componentRestrictions: {
            country: "LK",
          },
        },
        (predictions, status) => {
          if (status === "OK") {
            this.setState({ cities: predictions });
          } else if (status === "ZERO_RESULTS") {
            this.setState({ cities: [] });
          }
        }
      );
      this.setState({ city: e.target.value });
    } else {
      this.setState({ cities: [], city: "" });
    }
  };

  handleOrderSheduleTime = (time) => {
    this.setState({ expectedAt: time });
  };

  // TODO: make utility and move into it
  getFormattedPrice = (price, currency) => {
    const symbol = this.getCurrencySymbol(currency);
    return `${symbol} ${price}`;
  }

  // TODO: make utility and move into it
  getCurrencySymbol = (currency) => {
    return currency.symbol || currency.code;
  };

  render() {
    const restData = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant"));
    const cartItems = localStorage.getItem(`cart_${restData.id}`) ? JSON.parse(localStorage.getItem(`cart_${restData.id}`)) : [];
    const maxPreorderHours = cartItems.reduce((prev, current) => {
      return prev.preOrderHours > current.preOrderHours ? prev : current;
    }, 0);

    return (
      <React.Fragment>
        {this.state.redirectToCheckout ? <Redirect to="/checkout/your-details/order" /> : null}
        <div className="p-0 page-background-grey">
          <Navbar restName={localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).name} restId={this.state.restId} name={this.state.userName} restIsAvailable={false} />

          {this.state.errorAlert ? (
            <SweetAlert danger title={this.state.errorAlertHeading} onConfirm={this.close} confirmBtnStyle={{ background: "#f26666", border: 0 }}>
              {this.state.errorAlertText}
            </SweetAlert>
          ) : null}

          <div className="container min-height-viewport padding-top-bottom-25">
            <div className="cart-sec">
              <div className="row m-0">
                <div className="col p-0">
                  <div className="go-back-action-buttons">
                    <Link className="text-muted d-block mb-3" to="/cart" style={{ textDecoration: "none" }}>
                      {" "}
                      <i className="fas fa-chevron-left"></i>Back
                    </Link>
                  </div>

                  <div className="order-modification">
                    <div className="order-type-change">
                      <ManageOrderType rerenderParentCallback={this.rerenderParentCallback} resetDeliveryAddress={this.resetDeliveryAddress} />
                    </div>

                    <div className="order-time-change">
                      {!this.state.isResAvailable ? (
                        <>
                          <div data-toggle="modal" data-target="#scheduleOnClosed" className="schedule-onclosed-wrapper">
                            {localStorage.getItem("expectedAt") !== "" ? (
                              "Order scheduled for: " + moment(localStorage.getItem("expectedAt")).format("MMMM Do YYYY, h:mm a") ||
                              "Order scheduled for: " + moment(this.props.expectedAt).format("MMMM Do YYYY, h:mm a")
                            ) : (
                              <>
                                <i className="fas fa-calendar-alt pr-2"></i> Schedule an Order{" "}
                              </>
                            )}
                          </div>
                          <ManageOrderTimeOnResClosed handleOrderShedule={this.handleOrderSheduleTime} />
                        </>
                      ) : maxPreorderHours && maxPreorderHours.preOrderHours > 0 ? (
                        <>
                          <div data-toggle="modal" data-target="#schedulePreOrder" className="schedule-onclosed-wrapper">
                            {localStorage.getItem("expectedAt") !== "" ? (
                              "Order scheduled for: " + moment(localStorage.getItem("expectedAt")).format("MMMM Do YYYY, h:mm a") ||
                              "Order scheduled for: " + moment(this.props.expectedAt).format("MMMM Do YYYY, h:mm a")
                            ) : (
                              <>
                                <i className="fas fa-calendar-alt pr-2"></i> Schedule an Order{" "}
                              </>
                            )}
                          </div>
                          <ManageOrderTimePreOrder handleOrderShedule={this.handleOrderSheduleTime} />
                        </>
                      ) : (
                        <ManageOrderTime rerenderParentCallback={this.rerenderParentCallback} />
                      )}
                    </div>
                  </div>

                  <div className="your-details-title">
                    <div>
                      <h1 className="cart-heading">Your Details</h1>
                    </div>
                    <div>
                      <h6>
                        {(() => {
                          const value = JSON.parse(localStorage.getItem("cartSubTotal"))
                            .toLocaleString(undefined,{ minimumFractionDigits: 2});
                          return `Sub Total ${this.getFormattedPrice(value, this.state.currency)}`
                        })()}
                      </h6>
                    </div>
                  </div>

                  <hr />
                  <form noValidate className="user-details-form">
                    <div className="form-group p-0">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.state.nameError,
                        })}
                        id="name"
                        name="name"
                        onChange={this.handleChange}
                        value={this.state.name}
                        disabled
                      />
                      <div className="invalid-feedback">{this.state.nameError}</div>
                    </div>

                    <div className="form-group p-0 mt-4 your-details-mobile-no">
                      <div className="yd-label-action-btn">
                        <label htmlFor="mobile">Mobile</label>
                        <button
                          className="add-address-btn pl-2 d-inline-block"
                          type="button"
                          data-toggle="modal"
                          data-target="#phoneModal"
                          style={{
                            color:
                              localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                : "#ff7068",
                          }}
                        >
                          Edit
                        </button>
                      </div>
                      {this.state.oldPhone !== "" ? (
                        <React.Fragment>
                          <p className="mb-0" style={{ color: "#575d64" }}>
                            +{this.state.oldPhone}{" "}
                            <span className="text-success ml-3" style={{ fontSize: 13, fontWeight: 500 }}>
                              <i className="fas fa-check-circle pr-1"></i> Verified
                            </span>
                          </p>
                        </React.Fragment>
                      ) : (
                        <button
                          className="add-phone-btn d-block"
                          type="button"
                          data-toggle="modal"
                          data-target="#phoneModal"
                          onClick={() => this.setState({ mobileError: "" })}
                          style={{
                            color:
                              localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                : "#ff7068",
                          }}
                        >
                          <i className="fas fa-plus"></i> Add
                        </button>
                      )}
                      <div
                        style={{
                          marginTop: ".25rem",
                          fontSize: "80%",
                          color: "#dc3545",
                        }}
                      >
                        {this.state.mobileError}
                      </div>
                    </div>

                    {localStorage.getItem("orderType") === "delivery" ? (
                      <div className="form-group p-0 mt-4">
                        <div className="yd-label-action-btn">
                          <label htmlFor="address">Delivery Address</label>
                          <button
                            className="add-address-btn"
                            type="button"
                            data-toggle="modal"
                            data-target="#addressModal"
                            style={{
                              color:
                                localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                  ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                                  : "#ff7068",
                            }}
                          >
                            Add
                          </button>
                        </div>
                        <select
                          className={classnames("form-control custom-select", {
                            "is-invalid": this.state.addressError,
                          })}
                          id="address"
                          name="address"
                          onChange={this.handleChangeAddress}
                        >
                          <option value="" disabled selected>
                            - Select Delivery Address -
                          </option>
                          {this.state.addresses &&
                            this.state.addresses.map((address, i) => (
                              <option key={i} value={address.id} selected={address.id === this.state.addressId ? true : false}>
                                {address.line1}, {address.line2 ? `${address.line2},` : ""} {address.city}
                              </option>
                            ))}
                        </select>
                        <div className="invalid-feedback">{this.state.addressError}</div>
                      </div>
                    ) : null}

                    <div className="form-group p-0 mt-4">
                      <label htmlFor="email">Additional Info (Optional)</label>
                      <textarea
                        type="text"
                        maxLength={1000}
                        className="form-control"
                        id="note"
                        name="note"
                        onChange={this.handleAdditionalInfoChange}
                        value={this.state.note}
                        style={{ height: 100 }}
                      />
                      <span className="additional-info-text-limit">Characters Left: {this.state.charsLeft}</span>
                    </div>
                  </form>

                  <div className="checkout-btn">
                    <button
                      className="checkout mt-3"
                      onClick={this.submitUserDetails}
                      style={{
                        background:
                          localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                            ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                            : "#ff7068",
                      }}
                    >
                      {this.state.loadingContinueToCheckout ? (
                        <div className="spinner-border spinner-border-sm text-light" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}

                      {this.state.loadingContinueToCheckout ? " Continuing..." : <span>Continue</span>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 footer-row">
          <div className="col p-0">
            <Footer restName={this.state.restName} />
          </div>
        </div>

        {/* address modal */}
        <div className="modal fade" id="addressModal" tabIndex="-1" role="dialog" aria-labelledby="addressModal" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="addressModal">
                  Add Delivery Address
                </h6>
                <button type="button" id="close" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pt-0 pb-0">
                {this.state.suceessAddAddress ? (
                  <p
                    style={{
                      fontSize: 14,
                      color: "#009688",
                      marginBottom: 15,
                    }}
                  >
                    Address has been added successfully!
                  </p>
                ) : null}
                {this.state.showAddress ? (
                  <div className="row mx-0">
                    <div className="col p-0">
                      <p className="mb-3 mt-3" style={{ fontSize: 14, fontWeight: 600 }}>
                        Please enter your address details
                      </p>

                      <React.Fragment>
                        <form noValidate className="user-details-form">
                          <div className="form-group p-0 mb-0">
                            <input
                              type="text"
                              className={classnames("form-control", {
                                "is-invalid": this.state.houseNoError,
                              })}
                              id="houseNo"
                              name="houseNo"
                              placeholder="Street Number & Name"
                              onChange={this.handleChange}
                              value={this.state.houseNo}
                              autoComplete="off"
                            />
                            <div className="invalid-feedback">{this.state.houseNoError}</div>
                          </div>

                          <div className="form-group p-0 mb-0 mt-3">
                            <input
                              type="text"
                              className="form-control"
                              name="street2"
                              placeholder="Address Line 2 (Optional)"
                              onChange={this.handleChange}
                              value={this.state.street2}
                              autoComplete="off"
                            />
                          </div>

                          <div className="form-group p-0 mb-0 mt-3">
                            <input
                              type="text"
                              list="cities"
                              className={classnames("form-control", {
                                "is-invalid": this.state.cityError,
                              })}
                              name="city"
                              placeholder="City"
                              value={this.state.residentCity}
                              onChange={this.handleCities}
                              autoComplete="off"
                            />
                            <datalist id="cities">
                              {this.state.cities.map((city, i) => (
                                <option key={i}>{city.structured_formatting.main_text}</option>
                              ))}
                            </datalist>
                            <div className="invalid-feedback">{this.state.cityError}</div>
                          </div>

                          {/* <div className="form-group p-0 mb-0 mt-3">
                            <input
                              type="text"
                              className={classnames("form-control", {
                                "is-invalid": this.state.cityError,
                              })}
                              name="city"
                              placeholder="City"
                              onChange={this.handleChange}
                              value={this.state.city}
                              autoComplete="off"
                            />
                            <div className="invalid-feedback">
                              {this.state.cityError}
                            </div>
                          </div> */}
                          {this.state.EnterValidAddErr ? (
                            <p
                              style={{
                                fontSize: 13,
                                color: "#dc3545",
                                marginTop: 5,
                              }}
                            >
                              Please enter a valid address
                            </p>
                          ) : null}
                          <div className="form-group p-0 mb-0 mt-3">
                            <input readOnly type="text" className="form-control" name="Country" value="Sri Lanka" autoComplete="off" />
                          </div>
                        </form>
                        <div className="checkout-btn text-right">
                          <button
                            onClick={this.continue}
                            className="save-address"
                            style={{
                              background:
                                localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                  ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                  : "#151b26",
                              margin: "20px 0 0 0",
                            }}
                          >
                            {this.state.loadingContinue ? (
                              <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : null}

                            {this.state.loadingContinue ? " Continuing..." : <span>Continue</span>}
                          </button>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                ) : null}
                {this.state.showMap ? (
                  <div className="row mx-0 mb-4">
                    <div className="col p-0">
                      <p className="mb-1 mb-3 mt-3" style={{ fontSize: 14, fontWeight: 600 }}>
                        Please move the pin to the correct location
                      </p>

                      <React.Fragment>
                        <div className="row mx-0 mb-4">
                          <div className="col p-0 border">
                            <this.WrappedMap
                              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC8EFXuIwNdJVSEzVOCIDJeCRM2Rhuj8VY`}
                              loadingElement={<div style={{ height: `100%` }} />}
                              containerElement={<div style={{ height: 260 }} />}
                              mapElement={<div style={{ height: `100%` }} />}
                            />
                          </div>
                        </div>

                        <div className="map-save-wrapper">
                          <div className="map-save-wrapper-inner">
                            <h6
                              className="go-back-map-view"
                              onClick={() =>
                                this.setState({
                                  showMap: false,
                                  showAddress: true,
                                  city: "",
                                  houseNo: "",
                                })
                              }
                            >
                              Back
                            </h6>
                          </div>

                          <div className="map-save-wrapper-inner">
                            <button
                              onClick={this.addAddress}
                              className="save-address map-view"
                              style={{
                                background:
                                  localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                    ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                    : "#ff7068",
                              }}
                            >
                              {this.state.loading ? (
                                <div className="spinner-border spinner-border-sm text-light" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : null}

                              {this.state.loading ? " Saving..." : <span>Save</span>}
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* mobile modal */}
        <div className="modal fade" id="phoneModal" tabIndex="-1" role="dialog" aria-labelledby="addressModal" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="addressModal">
                  {this.state.showPhone ? "Mobile Number" : "Enter OTP"}
                </h6>
                <button type="button" id="close" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({ showPhone: true, showModal: false })}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.showPhone ? (
                  <form noValidate className="user-details-form">
                    <div className="form-group p-0 mt-4 mb-0">
                      <div
                        style={{
                          marginTop: ".25rem",
                          fontSize: "80%",
                          color: "#dc3545",
                        }}
                      >
                        {this.state.mobileError}
                      </div>
                      <PhoneInput country={"lk"} value={this.state.phone} enableSearch={true} disableSearchIcon={true} onChange={(phone) => this.setState({ phone })} prefix="" />
                      <div className="send-to-verify-button">
                        <button
                          type="button"
                          className="send-to-verify"
                          onClick={this.sendToVerify}
                          style={{
                            background:
                              localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                                : "#ff7068",
                          }}
                        >
                          {this.state.loadingVerify ? (
                            <div className="spinner-border spinner-border-sm" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                          {this.state.loadingVerify ? " Sending..." : <span>Send Verification Code</span>}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : null}
                {this.state.showModal ? (
                  <React.Fragment>
                    <p className="otp-sub-title">A code has been sent to the provided mobile number.</p>
                    <div>
                      <p className="mobile-number-popup-display-title">Your Phone Number</p>
                      <div className="mobile-number-popup-display-wrapper">
                        <h6 className="mobile-number-popup-display">+{this.state.oldPhone ? this.state.oldPhone : this.state.phone}</h6>
                        <h6 className="mobile-number-popup-display-edit" onClick={() => this.setState({ showPhone: true, showModal: false })}>
                          (Edit)
                        </h6>
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: ".25rem",
                        fontSize: "80%",
                        color: "#dc3545",
                        marginBottom: 15,
                        textAlign: "center",
                      }}
                    >
                      {this.state.verificationError}
                    </div>

                    <ReactCodeInput fields={4} className="mx-auto" onComplete={(val) => this.verify(val)} />

                    {this.state.loadingOTP ? (
                      <div className="text-center mt-3">
                        <div className="spinner-border spinner-border-sm" role="status">
                          <span className="sr-only"></span>
                        </div>
                        <h6 className="mt-2">Verifying... Please wait!</h6>
                      </div>
                    ) : null}

                    <p
                      type="button"
                      className="send-code-again"
                      onClick={this.sendToVerify}
                      style={{
                        color:
                          localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                            ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent2
                            : "#ff7068",
                      }}
                    >
                      Send Code Again
                    </p>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
