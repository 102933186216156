import React, { Component } from "react";
import classnames from "classnames";

import "../../styles/LoginSignup.css";

const initialState = {

    // register
    name: "",
    regEmail: "",
    regPwd: "",
    regPwdConfirm: "",

    nameError: "",
    regEmailError: "",
    regPwdError: "",
    regPwdConfError: "",


    // loading
    loadingReg: false,
};

class Register extends Component {
    state = initialState;

    componentDidMount() {
        this.setState({
            regEmail: this.props.currentUserEmail
        });
    }

    // handle inputs
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleEmailChange = (e) => {
        this.setState({ [e.target.name]: e.target.value.trim() });
    };


    // validate
    regValidate = () => {
        let nameError = "";
        let regEmailError = "";
        let regPwdError = "";
        let regPwdConfError = "";

        let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (this.state.name === "") {
            nameError = "Name is required";
        } else {
            nameError = "";
        }

        if (this.state.regEmail === "") {
            regEmailError = "Email is required";
        } else if (!this.state.regEmail.match(mailformat)) {
            regEmailError = "You have entered an invalid email address!";
        } else {
            regEmailError = ""
        }

        if (this.state.regPwd === "") {
            regPwdError = "Password is required";
        } else if (this.state.regPwd.length < 6) {
            regPwdError = "Password should be between 6 and 60 characters";
        } else {
            regPwdError = ""
        }

        if (this.state.regPwdConfirm === "") {
            regPwdConfError = "Confirm password is required";
        } else if (this.state.regPwdConfirm !== this.state.regPwd) {
            regPwdConfError = "Password should be matched";
        } else {
            regPwdConfError = ""
        }

        if (nameError || regEmailError || regPwdError || regPwdConfError) {
            this.setState({
                nameError,
                regEmailError,
                regPwdError,
                regPwdConfError,
            });
            return false;
        }
        return true;
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.loading !== nextProps.loadingProgressReg) {
            return {
                loadingReg: nextProps.loadingProgressReg
            };
        }
    }

    // register
    handleRegister = (e) => {
        e.preventDefault();

        if (this.regValidate()) {
            this.props.handleRegisterFn(e, this.state.name, this.state.regEmail, this.state.regPwd);
        }
    };

    render() {
        return (
            <div className="register-main" ref={this.props.containerRef}>
                <div>
                    <form noValidate className="login-reg-form">
                        <div className="form-group p-0">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                className={classnames("form-control", {
                                    "is-invalid": this.state.nameError,
                                })}
                                id="name"
                                name="name"
                                onChange={this.handleChange}
                                value={this.state.name}
                            />
                            <div className="invalid-feedback">
                                {this.state.nameError}
                            </div>
                        </div>
                        <div className="form-group p-0">
                            <label htmlFor="regEmail">Email</label>
                            <input
                                type="text"
                                className={classnames("form-control", {
                                    "is-invalid": this.state.regEmailError,
                                })}
                                id="regEmail"
                                name="regEmail"
                                onChange={this.handleEmailChange}
                                value={this.state.regEmail}
                            />
                            <div className="invalid-feedback">
                                {this.state.regEmailError}
                            </div>
                        </div>
                        <div className="form-group p-0">
                            <label htmlFor="regPwd">Password</label>
                            <input
                                type="password"
                                className={classnames("form-control", {
                                    "is-invalid": this.state.regPwdError,
                                })}
                                id="regPwd"
                                name="regPwd"
                                onChange={this.handleChange}
                                value={this.state.regPwd}
                            />
                            <div className="invalid-feedback">
                                {this.state.regPwdError}
                            </div>
                        </div>
                        <div className="form-group p-0 mb-0">
                            <label htmlFor="regPwdConfirm">Confirm Password</label>
                            <input
                                type="password"
                                className={classnames("form-control", {
                                    "is-invalid": this.state.regPwdConfError,
                                })}
                                id="regPwdConfirm"
                                name="regPwdConfirm"
                                onChange={this.handleChange}
                                value={this.state.regPwdConfirm}
                            />
                            <div className="invalid-feedback">
                                {this.state.regPwdConfError}
                            </div>
                        </div>

                        <div className="login-or-reg-buttons">
                            <div className="login-or-reg-button">
                                <h6 className="login-or-reg-text-btn" onClick={() => this.props.handleRegisterScreenChange()}>I have an account</h6>
                            </div>
                            <div className="login-or-reg-button">
                                <button
                                    type="submit"
                                    className="btn"
                                    onClick={this.handleRegister}
                                >
                                    {this.state.loadingReg ? (
                                        <div
                                            className="spinner-border spinner-border-sm text-light"
                                            role="status"
                                        >
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) : null}
                                    {this.state.loadingReg ? (
                                        " Registering..."
                                    ) : (
                                            <span> Register</span>
                                        )}
                                </button>
                            </div>
                        </div>

                    </form>
                </div>

            </div>
        );
    }
}

export default Register
