import React, { Component } from "react";
// import YourCart from "../components/cart/YourCart";
import YourCart from "../components/cart/NewYourCart";
import TitleOfPage from "../components/TitleOfPage";

import "../styles/cart.css";

export default class Cart extends Component {
  state = {
    restName:
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant")).name,
  };
  render() {
    return (
      <React.Fragment>
        <TitleOfPage title={`Cart | ${this.state.restName}`} />
        <YourCart />
      </React.Fragment>
    );
  }
}
