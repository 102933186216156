import React from "react";
import CartItems from "../components/cart/CartItems";

const NewCart = (props) => {
  return (
    <div>
      <CartItems handleCartDisplayCloseFn={props.handleCartDisplayClose} cartUpdate={props.cartUpdate} />
    </div>
  );
};

export default NewCart;
