import React, { Component } from "react";

export default class MenuRedirect extends Component {
  componentDidMount = () => {
    window.location.href = `https://${this.props.match.params.id}.spritzer.app`;
  };

  render() {
    return <div></div>;
  }
}
