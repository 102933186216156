import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import Footer from "../menu/Footer";
import {
  getDeliveryCost,
  handleOrderAPI,
  deliveryOrders,
  checkoutSessionForAnOrderAPI,
  deliveryOrdersGuestAPI,
  paymentMethodsAPI,
} from "../api/CheckoutAPI";
import Navbar from "../menu/Navbar";
import LoginSignupModal from "../LoginSignupModal";
import payments from "../../images/secured-payments.jpg";
import { ReactComponent as DeliveryTruck } from "../../images/delivery-truck.svg";
import { ReactComponent as MoneyIcon } from "../../images/money.svg";
import { ReactComponent as CardIcon } from "../../images/credit-card.svg";
import { ReactComponent as GlobalCardIcon } from "../../images/stripe.svg";
import Counter from "../../images/counter.png";
import { logErrors } from "../api/LogErrorsAPI";
import qs from "qs";
import "../../styles/order.css";
import { errorAllert } from "../../methods/Alert";

export default class Checkout extends Component {
  state = {
    items: [],
    deliveryFee: localStorage.getItem("deliveryFee")
      ? localStorage.getItem("deliveryFee")
      : 0,
    minimumOrder: "",
    acceptsCash: false,
    acceptsCard: false,
    restName: "",
    extId:
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant")).extId,
    listingId:
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant")).id,
    currency:
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant")).currency,

    //
    selectCard: false,
    cashBtn: false,
    payNowBtn: false,
    showAddBtn: false,
    showBackBtn: false,
    payOrderDissable: false,
    payAtCounterEnabled: false,

    // card payment
    defaultPayment: [],
    selectedCard: "",

    cardNumber: "",
    expiry: "",
    cvc: "",

    // errors
    errorAlertHeading: "",
    errorAlertText: "",

    // loading
    loadingAddCard: false,
    loading: false,

    // alerts
    successAlert: false,
    errorAlert: false,

    redirectToMenu: false,

    extraCosts: [],
    displayExtraCost: [],
    totalExtraCosts: "",

    orderID: "",
    checkoutData: {},
    checkoutType: "",

    // discounts
    orderDiscounts: [],
    applyDiscount: {},

    taxComponents: [],

    // Payment config
    paymentMethods: [],
    payHereData: {},
    payhereCustomBranding: null,
  };

  componentDidMount = () => {
    paymentMethodsAPI(
      (data) => {
        const payHere = data
          .filter((method) => method.ipg === "payhere")
          .find((el) => el);
        this.setState({
          paymentMethods: data,
          payHereData: payHere ? payHere : {},
        });
        if (payHere.id) {
          document.getElementById("pay-sl").checked = false;
        }
        document.getElementById("pay-non-sl").checked = false;
        document.getElementById("pay-cash").checked = false;
      },
      (error) => {
        console.log(error);
      }
    );

    const restData =
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant"));
    const orderTypeData =
      localStorage.getItem("selectedOrderTypeData") &&
      JSON.parse(localStorage.getItem("selectedOrderTypeData"));

    this.setState({
      items: localStorage.getItem(`cart_${restData.id}`)
        ? JSON.parse(localStorage.getItem(`cart_${restData.id}`))
        : [],
      restId: restData.id,
      restName: restData.name,
      acceptsCard: restData.deliveryDetail.acceptsCard,
      acceptsCash: restData.deliveryDetail.acceptsCash,
      extraCosts: orderTypeData.extraCosts,
      payhereCustomBranding: restData.payhereCustomBranding,
      orderDiscounts: restData.deliveryDetail
        ? restData.deliveryDetail.orderDiscounts
          ? restData.deliveryDetail.orderDiscounts
          : []
        : [],
      taxComponents: restData.deliveryDetail
        ? restData.deliveryDetail.taxComponents
          ? restData.deliveryDetail.taxComponents
          : []
        : [],
      payAtCounterEnabled:
        restData.deliveryDetail?.dineInSettings?.payAtCounterEnabled || false,
      // discounts
    });

    if (localStorage.getItem("deliveryAddress") === "delivery") {
      getDeliveryCost(
        restData.id,
        (data) => {
          this.setState({ deliveryFee: data.price });
        },
        (error) => {
          console.log(error);
        }
      );
    }

    const selectedType = localStorage.getItem("orderType");

    if (["delivery"].includes(selectedType)) {
      this.setState({
        minimumOrder: restData.deliveryDetail.deliverySettings.minimumOrder,
      });
    }
    if (["pickup"].includes(selectedType)) {
      this.setState({
        minimumOrder: restData.deliveryDetail.pickupSettings.minimumOrder,
      });
    }
    if (["dine_in"].includes(selectedType)) {
      this.setState({
        minimumOrder: restData.deliveryDetail.dineInSettings.minimumOrder,
      });
    }

    window.scrollTo(0, 0);
    this.loadScripts();

    if (
      (restData.deliveryDetail
        ? restData.deliveryDetail.orderDiscounts
          ? restData.deliveryDetail.orderDiscounts
          : []
        : []
      ).length > 0
    ) {
      this.checkDiscount(
        restData.deliveryDetail
          ? restData.deliveryDetail.orderDiscounts
            ? restData.deliveryDetail.orderDiscounts
            : []
          : []
      );
    }
  };

  // checking discount
  checkDiscount = (orderTypes) => {
    const hasOrderType = orderTypes.filter(
      (el) => el.orderType === localStorage.getItem("orderType")
    );

    if (hasOrderType.length > 0) {
      this.setState({ applyDiscount: hasOrderType[0] });
    }
  };

  handleOrder = (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    let orderedItems = [];

    this.state.items.map((item, i) => {
      let tempMenuItem = {
        itemId: item.id,
        quantity: item.count,
        portionSize: item.portionSize.id ? item.portionSize.id : null,
        addOns: [],
      };

      const addons = item.addOns && item.addOns;
      addons.map((group) => {
        let tempAddOnItem = {
          groupId: "",
          items: [],
        };

        tempAddOnItem.groupId = group.groupId;
        tempAddOnItem.items = addons
          .filter((agroup) => agroup.groupId === tempAddOnItem.groupId)
          .map((item) => item.id);
        tempMenuItem.addOns.push(tempAddOnItem);
      });

      tempMenuItem.addOns = tempMenuItem.addOns
        .slice()
        .reverse()
        .filter((v, i, a) => a.findIndex((t) => t.groupId === v.groupId) === i)
        .reverse();
      orderedItems = [...orderedItems, tempMenuItem];
    });

    const orderData = {
      listingId: this.state.listingId,
      payOnDelivery: true,
      orderItems: orderedItems,
      orderType: localStorage.getItem("orderType"),
      asap: localStorage.getItem("asap") === "true" ? true : false,
      expectedAt:
        localStorage.getItem("expectedAt") === ""
          ? null
          : localStorage.getItem("expectedAt"),
      matchMinOrder: localStorage.getItem("matchMinOrder")
        ? JSON.parse(localStorage.getItem("matchMinOrder"))
        : false,
      deliveryAddress: localStorage.getItem("deliveryAddress")
        ? localStorage.getItem("deliveryAddress")
        : "",
      deliveryNote: localStorage.getItem("deliveryNote")
        ? localStorage.getItem("deliveryNote")
        : "",
      tableId:
        localStorage.getItem("tableId") &&
        localStorage.getItem("orderType") === "dine_in"
          ? localStorage.getItem("tableId")
          : null,
    };

    this.setState({ payOrderDissable: true });

    if (
      localStorage.getItem("guest_checkout") &&
      localStorage.getItem("guest_checkout") == "true"
    ) {
      let guestData =
        localStorage.getItem("guest_details") &&
        JSON.parse(localStorage.getItem("guest_details"));
      guestData.orderItems = orderedItems;
      guestData.payOnDelivery = true;
      const orderType = localStorage.getItem("orderType") || "";
      if (["pickup", "dine_in"].includes(orderType)) {
        delete guestData.guestDetail.deliveryAddress;
      }
      deliveryOrdersGuestAPI(
        guestData,
        (data) => {
          this.setState({
            successAlert: true,
            loading: false,
            payOrderDissable: false,
          });

          localStorage.removeItem("deliveryNote");
          localStorage.removeItem("deliveryFee");
          localStorage.removeItem("deliveryAddress");
          localStorage.removeItem("tableId");
          localStorage.removeItem("totalExtraCost");
          localStorage.removeItem("extraCostData");
          localStorage.removeItem("cartSubTotal");
          localStorage.removeItem("selectedOrderTypeData");
          localStorage.removeItem("matchMinOrder");
          localStorage.removeItem("asap");
          localStorage.removeItem("expectedAt");
          localStorage.removeItem("guest_checkout");
          localStorage.removeItem("guest_details");
        },
        (error) => {
          console.log(error);
          errorAllert(
            `Unexpected error! Error code: ${error?.response?.data.message} | ${error?.response?.data.code}`
          );
          this.setState({ loading: false });
        }
      );
    } else {
      handleOrderAPI(
        orderData,
        (data) => {
          this.setState({
            successAlert: true,
            loading: false,
            payOrderDissable: false,
          });

          localStorage.removeItem("deliveryNote");
          localStorage.removeItem("deliveryFee");
          localStorage.removeItem("deliveryAddress");
          localStorage.removeItem("tableId");
          localStorage.removeItem("totalExtraCost");
          localStorage.removeItem("extraCostData");
          localStorage.removeItem("cartSubTotal");
          localStorage.removeItem("selectedOrderTypeData");
          localStorage.removeItem("matchMinOrder");
          localStorage.removeItem("asap");
          localStorage.removeItem("expectedAt");
          localStorage.removeItem("guest_checkout");
          localStorage.removeItem("guest_details");
        },
        (error) => {
          logErrors(error, orderData);

          if (error.code === 1024 || error.code === 1064) {
            this.setState({
              errorAlertHeading: "Card Declined",
              errorAlertText: `Card could not be added because the payment network returned an error. Please try again with a different credit card. Error code: ${error.code}`,
            });
          } else if (error.code === 1031) {
            this.setState({
              errorAlertHeading: "No Payment Methods",
              errorAlertText: `Please add a credit card and try again. Error code: ${error.code}`,
            });
          } else if (error.code === 1052) {
            this.setState({
              errorAlertHeading: "No Phone Number",
              errorAlertText: `Please add a phone number and try again. Error code: ${error.code}`,
            });
          } else if (error.code === 1057) {
            this.setState({
              errorAlertHeading: "No Delivery Address",
              errorAlertText: `No delivery addresses found. Please add an address and try again. Error code: ${error.code}`,
            });
          } else if (
            error.code === 1016 ||
            error.code === 1046 ||
            error.code === 1055
          ) {
            this.setState({
              errorAlertHeading: "Restaurant Offline",
              errorAlertText: `This restaurant is not accepting orders at the moment. Try again later. Error code: ${error.code}`,
            });
          } else if (error.code === 1056) {
            this.setState({
              errorAlertHeading: "Payment Method Not Accepted",
              errorAlertText: `The selected payment method is not accepted by the restaurant. Please try with a different payment method. Error code: ${error.code}`,
            });
          } else if (
            error.code === 1058 ||
            error.code === 1061 ||
            error.code === 1094
          ) {
            this.setState({
              errorAlertHeading: "Invalid Items",
              errorAlertText: `Please remove invalid items from the cart and try again. Error code: ${error.code}`,
            });
          } else if (error.code === 1060) {
            this.setState({
              errorAlertHeading: "Order Too Small",
              errorAlertText: `The order total is less than the minimum order value allowed by the restaurant. Error code: ${error.code}`,
            });
          } else if (error.code === 1069) {
            this.setState({
              errorAlertHeading: "Invalid order time",
              errorAlertText: `Listing does not accept given time. Error code: ${error.code}`,
            });
          } else if (error.code === 1070) {
            this.setState({
              errorAlertHeading: "Order Type Error",
              errorAlertText: `Listing does not accept given order type. Error code: ${error.code}`,
            });
          } else if (error.code === 1073) {
            this.setState({
              errorAlertHeading: "Invalid table ID",
              errorAlertText: `Your order table ID is Invalid. Error code: ${error.code}`,
            });
          } else {
            this.setState({
              errorAlertHeading: "Oops, Something went wrong",
              errorAlertText: "Your order hasn't been placed successfully.",
            });
          }

          this.setState({
            errorAlert: true,
            loading: false,
            payOrderDissable: false,
          });
        }
      );
    }
  };

  // close modal
  close = () => {
    this.setState({ errorAlert: false, errorAlertText: "" });
  };

  // next
  next = () => {
    localStorage.removeItem(`cart_${this.state.restId}`);
    localStorage.removeItem("orderDetails");
    localStorage.removeItem("deliveryAddress");
    localStorage.removeItem("deliveryNote");
    localStorage.removeItem("orderType");
    localStorage.removeItem("asap");
    localStorage.removeItem("expectedAt");
    localStorage.removeItem("totalExtraCost");
    localStorage.removeItem("extraCostData");
    localStorage.removeItem("cartSubTotal");
    localStorage.removeItem("selectedOrderTypeData");
    localStorage.removeItem("matchMinOrder");
    localStorage.removeItem("guest_checkout");
    localStorage.removeItem("guest_details");

    this.setState({ redirectToMenu: true });
  };

  handleSelectCreditCard = () => {
    this.setState({
      selectCard: true,
      cashBtn: false,
    });

    let addonSubTotal = 0;
    this.state.items.map((item) => {
      const count = item.count;
      const addonTotal =
        item.addOns &&
        item.addOns.reduce((currentValue, nextValue) => {
          return currentValue + parseInt(nextValue.price);
        }, 0);
      addonSubTotal = addonSubTotal + addonTotal * count;
    });

    let portionSizeTotal = 0;
    this.state.items.map((item) => {
      const count = item.count;
      if (item.portionSize.price) {
        portionSizeTotal = portionSizeTotal + item.portionSize.price * count;
      } else {
        let price = item.displayPriceDiscounted
          ? item.displayPriceDiscounted
          : item.displayPrice;
        portionSizeTotal = portionSizeTotal + price * count;
      }
    });

    const subTotal = addonSubTotal + portionSizeTotal;
    const defMinOrder = this.state.minimumOrder - subTotal;
    const discount =
      this.state.orderDiscounts.length > 0 &&
      this.state.applyDiscount.threshold &&
      this.state.applyDiscount.threshold <= subTotal
        ? this.state.applyDiscount.isPercentage
          ? (this.state.applyDiscount.percentage / 100) * subTotal
          : this.state.applyDiscount.value
        : 0;

    const subTotalWithMinValue =
      subTotal + (defMinOrder > 0 ? defMinOrder : 0) - discount;

    let tempObj = [];
    const validObjs = this.state.extraCosts.filter(
      (costObj) => costObj.validForCard === true
    );
    this.setState({ displayExtraCost: validObjs });
    tempObj.push(...validObjs);

    const extraCostForCard = tempObj.reduce(
      (currentCardCost, cardExtraCost) => {
        return cardExtraCost.isPercentage === true
          ? currentCardCost +
              (subTotalWithMinValue * cardExtraCost.percentage) / 100
          : currentCardCost + cardExtraCost.value;
      },
      0
    );
    this.setState({ totalExtraCosts: extraCostForCard });
    localStorage.setItem("totalExtraCost", extraCostForCard);
    localStorage.setItem("extraCostData", JSON.stringify(tempObj));
  };

  handleSelectCash = () => {
    this.setState({
      selectCard: false,
      cashBtn: true,
      payNowBtn: false,
      showBackBtn: false,
    });

    let addonSubTotal = 0;
    this.state.items.map((item) => {
      const count = item.count;
      const addonTotal =
        item.addOns &&
        item.addOns.reduce((currentValue, nextValue) => {
          return currentValue + parseInt(nextValue.price);
        }, 0);
      addonSubTotal = addonSubTotal + addonTotal * count;
    });

    let portionSizeTotal = 0;
    this.state.items.map((item) => {
      const count = item.count;
      if (item.portionSize.price) {
        portionSizeTotal = portionSizeTotal + item.portionSize.price * count;
      } else {
        let price = item.displayPriceDiscounted
          ? item.displayPriceDiscounted
          : item.displayPrice;
        portionSizeTotal = portionSizeTotal + price * count;
      }
    });

    const subTotal = addonSubTotal + portionSizeTotal;
    const defMinOrder = this.state.minimumOrder - subTotal;
    const discount =
      this.state.orderDiscounts.length > 0 &&
      this.state.applyDiscount.threshold &&
      this.state.applyDiscount.threshold <= subTotal
        ? this.state.applyDiscount.isPercentage
          ? (this.state.applyDiscount.percentage / 100) * subTotal
          : this.state.applyDiscount.value
        : 0;

    const subTotalWithMinValue =
      subTotal + (defMinOrder > 0 ? defMinOrder : 0) - discount;

    let tempObj = [];
    const validObjs = this.state.extraCosts.filter(
      (costObj) => costObj.validForCash === true
    );
    this.setState({ displayExtraCost: validObjs });
    tempObj.push(...validObjs);

    const extraCostForCash = tempObj.reduce((currentCost, cashExtraCost) => {
      return cashExtraCost.isPercentage === true
        ? currentCost + (subTotalWithMinValue * cashExtraCost.percentage) / 100
        : currentCost + cashExtraCost.value;
    }, 0);
    this.setState({ totalExtraCosts: extraCostForCash });
    localStorage.setItem("totalExtraCost", extraCostForCash);
    localStorage.setItem("extraCostData", JSON.stringify(tempObj));
  };

  payOrderByCard = () => {
    this.setState({ loading: true });
    let orderedItems = [];

    this.state.items.map((item, i) => {
      let tempMenuItem = {
        itemId: item.id,
        quantity: item.count,
        portionSize: item.portionSize.id ? item.portionSize.id : null,
        addOns: [],
      };

      const addons = item.addOns && item.addOns;
      addons.map((group) => {
        let tempAddOnItem = {
          groupId: "",
          items: [],
        };

        tempAddOnItem.groupId = group.groupId;
        tempAddOnItem.items = addons
          .filter((agroup) => agroup.groupId === tempAddOnItem.groupId)
          .map((item) => item.id);
        tempMenuItem.addOns.push(tempAddOnItem);
      });

      tempMenuItem.addOns = tempMenuItem.addOns
        .slice()
        .reverse()
        .filter((v, i, a) => a.findIndex((t) => t.groupId === v.groupId) === i)
        .reverse();
      orderedItems = [...orderedItems, tempMenuItem];
    });

    const orderData = {
      listingId: this.state.listingId,
      payOnDelivery: false,
      paymentSource: this.state.selectedCard,
      orderItems: orderedItems,
      orderType: localStorage.getItem("orderType"),
      asap: localStorage.getItem("asap") === "true" ? true : false,
      expectedAt:
        localStorage.getItem("expectedAt") === ""
          ? null
          : localStorage.getItem("expectedAt"),
      matchMinOrder: localStorage.getItem("matchMinOrder")
        ? JSON.parse(localStorage.getItem("matchMinOrder"))
        : false,
      deliveryAddress: localStorage.getItem("deliveryAddress")
        ? localStorage.getItem("deliveryAddress")
        : null,
      deliveryNote: localStorage.getItem("deliveryNote")
        ? localStorage.getItem("deliveryNote")
        : "",
      tableId:
        localStorage.getItem("tableId") &&
        localStorage.getItem("orderType") === "dine_in"
          ? localStorage.getItem("tableId")
          : null,
    };

    let checkoutTypeObj = qs.stringify({
      ipg: this.state.checkoutType,
    });

    if (
      localStorage.getItem("guest_checkout") &&
      localStorage.getItem("guest_checkout") == "true"
    ) {
      let guestData =
        localStorage.getItem("guest_details") &&
        JSON.parse(localStorage.getItem("guest_details"));
      guestData.payOnDelivery = false;
      guestData.orderItems = orderedItems;
      const orderType = localStorage.getItem("orderType") || "";
      if (["pickup", "dine_in"].includes(orderType)) {
        delete guestData.guestDetail.deliveryAddress;
      }

      deliveryOrdersGuestAPI(
        guestData,
        (deliveryOrderData) => {
          checkoutSessionForAnOrderAPI(
            deliveryOrderData.orderId,
            checkoutTypeObj,
            (data) => {
              if (data.ipg === "stripe") {
                this.redirect(data);
              } else if (data.ipg === "payhere") {
                this.setState({ checkoutData: data }, () => {
                  const restData =
                    localStorage.getItem("restaurant") &&
                    JSON.parse(localStorage.getItem("restaurant"));
                  if (restData.payhereCustomBranding) {
                    document.getElementById("pay-by-payhere").click();
                  } else {
                    const orderDataStr = JSON.stringify(data);
                    const orderData = Buffer.from(
                      orderDataStr,
                      "utf8"
                    ).toString("base64");
                    const domainName =
                      restData.customDomain ??
                      `${
                        restData.extId ? `${restData.extId}.spritzer.app` : ""
                      }`;

                    if (domainName) {
                      window.location.href = `https://${domainName}/checkout/local-payment-redirect?order=${orderData}`;
                    }
                  }
                });
              } else {
                alert("Select payment gateway");
              }
            },
            (error) => {
              console.log(error);
              errorAllert(
                `Unexpected error! Error code: ${error?.response?.data.message} | ${error?.response?.data.code}`
              );
              this.setState({ loading: false });
            }
          );
          this.setState({ orderID: deliveryOrderData.orderId });
        },
        (error) => {
          console.log(error);
          errorAllert(
            `Unexpected error! Error code: ${error?.response?.data.message} | ${error?.response?.data.code}`
          );
          this.setState({ loading: false });
        }
      );
    } else {
      deliveryOrders(
        orderData,
        (deliveryOrderData) => {
          checkoutSessionForAnOrderAPI(
            deliveryOrderData.orderId,
            checkoutTypeObj,
            (data) => {
              if (data.ipg === "stripe") {
                this.redirect(data);
              } else if (data.ipg === "payhere") {
                this.setState({ checkoutData: data }, () => {
                  const restData =
                    localStorage.getItem("restaurant") &&
                    JSON.parse(localStorage.getItem("restaurant"));
                  if (restData.payhereCustomBranding) {
                    document.getElementById("pay-by-payhere").click();
                  } else {
                    const orderDataStr = JSON.stringify(data);

                    const orderData = Buffer.from(
                      orderDataStr,
                      "utf8"
                    ).toString("base64");
                      // window.location.href = `https://${restData.extId}.spritzer.app/checkout/local-payment-redirect?order=${orderData}`;
                    const domainName =
                      restData.customDomain ??
                      `${
                        restData.extId ? `${restData.extId}.spritzer.app` : ""
                      }`;

                    if (domainName) {
                      window.location.href = `https://${restData.extId}.spritzer.app/checkout/local-payment-redirect?order=${orderData}`;
                    }
                  }
                });
              } else {
                alert("Select payment gateway");
              }
            },
            (error) => {
              console.log(error);
              errorAllert(
                `Unexpected error! Error code: ${error?.response?.data.message} | ${error?.response?.data.code}`
              );
              this.setState({ loading: false });
            }
          );
          this.setState({ orderID: deliveryOrderData.orderId });
        },
        (error) => {
          console.log(error);
          errorAllert(
            `Unexpected error! Error code: ${error?.response?.data.message} | ${error?.response?.data.code}`
          );
          this.setState({ loading: false });
        }
      );
    }
  };

  loadScripts = () => {
    // stripe
    const stripeScript = document.createElement("script");
    stripeScript.src = "https://js.stripe.com/v3/";
    stripeScript.async = true;
    document.body.appendChild(stripeScript);
  };

  handleSelectPaymentMethod = (event) => {
    if (event.target.value === "payhere" || event.target.value === "stripe") {
      this.setState({ checkoutType: event.target.value });
      this.handleSelectCreditCard();
    } else if (event.target.value === "cash") {
      this.setState({ checkoutType: event.target.value });
      this.handleSelectCash();
    } else {
      alert("Please select payment type");
    }
  };

  redirect = (checkoutData) => {
    const stripeId = process.env.REACT_APP_STRIPE_KEY;
    var stripe = window.Stripe(stripeId);

    if (checkoutData.ipg === "stripe") {
      stripe
        .redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: checkoutData.stripeParams.sessionId,
        })
        .then(function (result) {
          this.setState({ loading: false });
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
        })
        .catch((err) => {
          this.setState({ loading: false });
          alert("Error! Try again.");
        });
    }
  };

  // TODO: make utility and move into it
  getFormattedPrice = (price, currency) => {
    const symbol = this.getCurrencySymbol(currency);
    return `${symbol} ${price}`;
  };

  // TODO: make utility and move into it
  getCurrencySymbol = (currency) => {
    return currency.symbol || currency.code;
  };

  render() {
    let addonSubTotal = 0;
    this.state.items.map((item) => {
      const count = item.count;
      const addonTotal =
        item.addOns &&
        item.addOns.reduce((currentValue, nextValue) => {
          return currentValue + parseInt(nextValue.price);
        }, 0);
      addonSubTotal = addonSubTotal + addonTotal * count;
    });

    let portionSizeTotal = 0;
    this.state.items.map((item) => {
      const count = item.count;
      if (item.portionSize.price) {
        portionSizeTotal = portionSizeTotal + item.portionSize.price * count;
      } else {
        let price = item.displayPriceDiscounted
          ? item.displayPriceDiscounted
          : item.displayPrice;
        portionSizeTotal = portionSizeTotal + price * count;
      }
    });

    const subTotal = addonSubTotal + portionSizeTotal;
    const defMinOrder = this.state.minimumOrder - subTotal;
    const discount =
      this.state.orderDiscounts.length > 0 &&
      this.state.applyDiscount.threshold &&
      this.state.applyDiscount.threshold <= subTotal
        ? this.state.applyDiscount.isPercentage
          ? (this.state.applyDiscount.percentage / 100) * subTotal
          : this.state.applyDiscount.value
        : 0;

    const subTotalWithMinValue =
      subTotal + (defMinOrder > 0 ? defMinOrder : 0) - discount;
    const negOrPos = Math.sign(defMinOrder);
    const total =
      negOrPos === 1 ? this.state.minimumOrder : subTotalWithMinValue;
    const totalWithExtraCost =
      total +
      (this.state.totalExtraCosts != "" ? this.state.totalExtraCosts : 0);

    const orderTotalWithExtra = totalWithExtraCost;

    const orderTotalWithExtraWithDeliveryCost =
      orderTotalWithExtra +
      this.state.taxComponents.reduce((currentValue, nextValue) => {
        return !nextValue.included
          ? currentValue + (nextValue.percentage / 100) * orderTotalWithExtra
          : currentValue;
      }, 0);

    const orderFullAmount =
      orderTotalWithExtraWithDeliveryCost + parseFloat(this.state.deliveryFee);

    return (
      <React.Fragment>
        {this.state.redirectToMenu ? <Redirect to="/" /> : null}
        <div className="p-0 page-background-grey">
          <Navbar
            restName={
              localStorage.getItem("restaurant") &&
              JSON.parse(localStorage.getItem("restaurant")).name
            }
            restId={this.state.restId}
            name={
              localStorage.getItem("profile") &&
              JSON.parse(localStorage.getItem("profile")).name
            }
            restIsAvailable={false}
            displayFinalizedInfo={true}
          />

          {this.state.successAlert ? (
            <SweetAlert
              success
              title="Successful"
              onConfirm={this.next}
              confirmBtnText="OK"
              confirmBtnStyle={{
                background:
                  localStorage.getItem("restaurant") &&
                  JSON.parse(localStorage.getItem("restaurant")).branding &&
                  JSON.parse(localStorage.getItem("restaurant")).branding
                    .colorAccent
                    ? JSON.parse(localStorage.getItem("restaurant")).branding
                        .colorAccent
                    : "#ff7068",
                border: 0,
                width: 70,
              }}
            >
              Your order has been placed successfully.
            </SweetAlert>
          ) : null}

          {this.state.errorAlert ? (
            <SweetAlert
              danger
              title={this.state.errorAlertHeading}
              onConfirm={this.close}
              confirmBtnStyle={{
                background:
                  localStorage.getItem("restaurant") &&
                  JSON.parse(localStorage.getItem("restaurant")).branding &&
                  JSON.parse(localStorage.getItem("restaurant")).branding
                    .colorAccent
                    ? JSON.parse(localStorage.getItem("restaurant")).branding
                        .colorAccent
                    : "#ff7068",
                border: 0,
              }}
            >
              {this.state.errorAlertText}
            </SweetAlert>
          ) : null}

          <div className="container min-height-viewport padding-top-bottom-25">
            <div className="cart-sec">
              <div className="row m-0">
                <div className="col p-0">
                  <div className="go-back-action-buttons">
                    <Link
                      className="text-muted d-block mb-3"
                      to={
                        localStorage.getItem("guest_checkout") == "true"
                          ? "/guest"
                          : "/checkout/your-details"
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <i className="fas fa-chevron-left"></i>Back
                    </Link>
                  </div>
                  <h1 className="cart-heading">Checkout</h1>
                  <hr />

                  <p className="payment-method-title">
                    Please select the payment method.
                  </p>

                  <div className="pay-select-container">
                    {this.state.acceptsCard && (
                      <>
                        {this.state.payHereData?.id === "lk_cc" ? (
                          <div className="option">
                            <input
                              type="radio"
                              className="pay-select-radio"
                              id="pay-sl"
                              name="card"
                              value="payhere"
                              onChange={this.handleSelectPaymentMethod}
                            />
                            <label
                              htmlFor="pay-sl"
                              className="pay-select-label"
                              aria-label="Sri Lanka"
                            >
                              <span></span>
                              Sri Lankan Credit/Debit Cards
                              <div className="pay-card">
                                <CardIcon />
                              </div>
                            </label>
                          </div>
                        ) : null}

                        <div className="option">
                          <input
                            type="radio"
                            className="pay-select-radio"
                            id="pay-non-sl"
                            name="card"
                            value="stripe"
                            onChange={this.handleSelectPaymentMethod}
                          />
                          <label
                            htmlFor="pay-non-sl"
                            className="pay-select-label"
                            aria-label="Non Sri Lanka"
                          >
                            <span></span>
                            {this.state.payHereData?.id === "lk_cc"
                              ? "Non Sri Lankan Credit/Debit Cards"
                              : "Credit/Debit Cards"}
                            <div className="pay-card">
                              <GlobalCardIcon />
                            </div>
                          </label>
                        </div>
                      </>
                    )}

                    {this.state.acceptsCash && (
                      <div className="option">
                        <input
                          type="radio"
                          className="pay-select-radio"
                          id="pay-cash"
                          name="card"
                          value="cash"
                          onChange={this.handleSelectPaymentMethod}
                        />
                        <label
                          htmlFor="pay-cash"
                          className="pay-select-label"
                          aria-label="Cash"
                        >
                          <span></span>
                          Cash{" "}
                          {localStorage.getItem("orderType") === "delivery"
                            ? "on Delivery"
                            : ""}
                          <div className="pay-card">
                            {localStorage.getItem("orderType") ===
                            "delivery" ? (
                              <DeliveryTruck />
                            ) : (
                              <MoneyIcon />
                            )}
                          </div>
                        </label>
                      </div>
                    )}

                    {localStorage.getItem("orderType") === "dine_in" &&
                    this.state.payAtCounterEnabled ? (
                      <div className="option">
                        <input
                          type="radio"
                          className="pay-select-radio"
                          id="pay-at-counter"
                          name="card"
                          value="cash"
                          onChange={this.handleSelectPaymentMethod}
                        />
                        <label
                          htmlFor="pay-at-counter"
                          className="pay-select-label"
                          aria-label="Cash"
                        >
                          <span></span>
                          {localStorage.getItem("selectedOrderTypeData") &&
                          JSON.parse(
                            localStorage.getItem("selectedOrderTypeData")
                          )?.payAtCounterText
                            ? localStorage.getItem("selectedOrderTypeData") &&
                              JSON.parse(
                                localStorage.getItem("selectedOrderTypeData")
                              ).payAtCounterText
                            : "Pay at Outlet"}
                          <div className="pay-card">
                            <img src={Counter} alt="Spritzer pay" />
                          </div>
                        </label>
                      </div>
                    ) : null}
                  </div>

                  {this.state.showBackBtn ? (
                    <div
                      className="row m-0 mt-4 back-btn"
                      onClick={() =>
                        this.setState({
                          showBackBtn: false,
                        })
                      }
                    >
                      <div className="col p-0">
                        <span className="back-btn">
                          <i className="fas fa-long-arrow-alt-left"></i> Back
                        </span>
                      </div>
                    </div>
                  ) : null}

                  <div className="total-order-info">
                    {/* <div className="promo-code-check">
                      <h6 className="promo-code-link">Have a Promo Code?</h6>
                    </div> */}

                    <div className="row mx-0 total-sec mb-2">
                      <div className="col-8 p-0">Item Total</div>
                      <div className="col-4 p-0 text-right">
                        {`${this.getFormattedPrice(
                          subTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          }),
                          this.state.currency
                        )}`}
                      </div>
                    </div>

                    {this.state.orderDiscounts.length > 0 &&
                      this.state.applyDiscount.threshold &&
                      this.state.applyDiscount.threshold <= subTotal && (
                        <div className="row m-0 total-sec mb-2">
                          <div className="col-8 p-0">
                            {(() => {
                              if (this.state.applyDiscount.isPercentage) {
                                return `${this.state.applyDiscount.isPercentage}%`;
                              }
                              const { currency, applyDiscount } = this.state;
                              const { value: discountValue, threshold } =
                                applyDiscount;
                              const substractedValue = threshold.toLocaleString(
                                undefined,
                                { minimumFractionDigits: 2 }
                              );

                              let output = `${this.getFormattedPrice(
                                discountValue,
                                currency
                              )} discount for orders `;
                              output += `${this.getFormattedPrice(
                                substractedValue,
                                currency
                              )}`;
                              return output;
                            })()}
                          </div>
                          <div className="col-4 p-0 text-right">
                            {(() => {
                              const { currency, applyDiscount } = this.state;
                              let substraction = applyDiscount.isPercentage
                                ? (applyDiscount.percentage / 100) * subTotal
                                : applyDiscount.value;
                              substraction = substraction.toLocaleString(
                                undefined,
                                { minimumFractionDigits: 2 }
                              );
                              return this.getFormattedPrice(
                                substraction,
                                currency
                              );
                            })()}
                          </div>
                        </div>
                      )}

                    {negOrPos === 1 ? (
                      <div className="row m-0 total-sec mb-2">
                        <div className="col-8 p-0">
                          {(() => {
                            const { minimumOrder, currency } = this.state;
                            const value = minimumOrder.toLocaleString(
                              undefined,
                              { minimumFractionDigits: 2 }
                            );
                            return `Match Minimum Order ${this.getFormattedPrice(
                              value,
                              currency
                            )}`;
                          })()}
                        </div>

                        <div className="col-4 p-0 text-right">
                          {(() => {
                            const { currency } = this.state;
                            const value = defMinOrder.toLocaleString(
                              undefined,
                              { minimumFractionDigits: 2 }
                            );
                            return this.getFormattedPrice(value, currency);
                          })()}
                        </div>
                      </div>
                    ) : null}

                    {this.state.totalExtraCosts ? (
                      <div>
                        {this.state.displayExtraCost.map((cost) => (
                          <div
                            className="row m-0 total-sec mb-2 extra-cost-display"
                            key={cost.id}
                          >
                            <div className="col-8 p-0">
                              {cost.description}{" "}
                              {cost.isPercentage === true
                                ? ` (${cost.percentage}%)`
                                : ""}
                            </div>
                            <div className="col-4 p-0 text-right">
                              {cost.isPercentage === true
                                ? (
                                    (subTotalWithMinValue * cost.percentage) /
                                    100
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })
                                : cost.value.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}

                    <div className="row mx-0 total-sec mb-2 font-weight-bold">
                      <div className="col-8 p-0">Subtotal</div>
                      <div className="col-4 p-0 text-right">
                        {(() => {
                          const { currency } = this.state;
                          const value = orderTotalWithExtra.toLocaleString(
                            undefined,
                            { minimumFractionDigits: 2 }
                          );
                          return this.getFormattedPrice(value, currency);
                        })()}
                      </div>
                    </div>

                    {this.state.taxComponents ? (
                      <div>
                        {this.state.taxComponents.map((tax) => (
                          <div
                            className="row m-0 total-sec mb-2 extra-cost-display"
                            key={tax.id}
                          >
                            <div className="col-8 p-0">
                              {tax.description} {` ${tax.percentage}%`}
                            </div>
                            <div className="col-4 p-0 text-right">
                              {(
                                (subTotalWithMinValue * tax.percentage) /
                                100
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}

                    <div className="row m-0 total-sec order-total-wrapper font-weight-normal">
                      <div className="col-8 p-0">Delivery Charge</div>
                      <div className="col-4 p-0 text-right">
                        {this.getFormattedPrice(
                          parseFloat(this.state.deliveryFee).toFixed(2),
                          this.state.currency
                        )}
                      </div>
                    </div>

                    <div className="row m-0 total-sec order-total-wrapper">
                      <div className="col-8 p-0">Order Total</div>
                      <div className="col-4 p-0 text-right">
                        {this.getFormattedPrice(
                          parseFloat(orderFullAmount).toFixed(2),
                          this.state.currency
                        )}
                      </div>
                    </div>
                  </div>

                  {this.state.cashBtn ? (
                    <div className="checkout-pay-button">
                      <button
                        className={
                          this.state.cashBtn ? "checkout" : "disabled-btn"
                        }
                        onClick={this.handleOrder}
                        disabled={this.state.payOrderDissable ? true : false}
                        style={{
                          background:
                            localStorage.getItem("restaurant") &&
                            JSON.parse(localStorage.getItem("restaurant"))
                              .branding &&
                            JSON.parse(localStorage.getItem("restaurant"))
                              .branding.colorAccent
                              ? JSON.parse(localStorage.getItem("restaurant"))
                                  .branding.colorAccent
                              : "#ff7068",
                        }}
                      >
                        {this.state.loading ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}

                        {this.state.loading ? (
                          " Confirming..."
                        ) : (
                          <span>Confirm Now</span>
                        )}
                      </button>
                    </div>
                  ) : null}

                  {this.state.selectCard ? (
                    <div className="checkout-pay-button">
                      <button
                        className="checkout"
                        onClick={this.payOrderByCard}
                        disabled={
                          this.state.payOrderDissable || this.state.loading
                            ? true
                            : false
                        }
                        style={{
                          background:
                            localStorage.getItem("restaurant") &&
                            JSON.parse(localStorage.getItem("restaurant"))
                              .branding &&
                            JSON.parse(localStorage.getItem("restaurant"))
                              .branding.colorAccent
                              ? JSON.parse(localStorage.getItem("restaurant"))
                                  .branding.colorAccent
                              : "#ff7068",
                        }}
                      >
                        {this.state.loading ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <span>
                            <i className="fas fa-lock pr-2"></i>Continue to
                            Payment
                          </span>
                        )}
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <form
          id="ext-merchant-frm"
          action={
            process.env.REACT_APP_API_URL === "https://api-dev.spritzer.app"
              ? "https://sandbox.payhere.lk/pay/checkout"
              : "https://www.payhere.lk/pay/checkout"
          }
          method="post"
          acceptCharset="UTF-8"
          encType="application/x-www-form-urlencoded"
        >
          <input
            type="hidden"
            name="merchant_id"
            value={this.state.checkoutData?.payhereParams?.merchantId}
          />
          <input
            type="hidden"
            name="return_url"
            value={this.state.checkoutData?.payhereParams?.returnUrl}
          />
          <input
            type="hidden"
            name="cancel_url"
            value={this.state.checkoutData?.payhereParams?.cancelUrl}
          />
          <input
            type="hidden"
            name="notify_url"
            value={this.state.checkoutData?.payhereParams?.notifyUrl}
          />
          <input
            type="hidden"
            name="first_name"
            value={this.state.checkoutData?.payhereParams?.firstName}
          />
          <input
            type="hidden"
            name="last_name"
            value={this.state.checkoutData?.payhereParams?.lastName}
          />
          <input
            type="hidden"
            name="email"
            value={this.state.checkoutData?.payhereParams?.email}
          />
          <input
            type="hidden"
            name="phone"
            value={this.state.checkoutData?.payhereParams?.phone}
          />
          <input
            type="hidden"
            name="address"
            value={this.state.checkoutData?.payhereParams?.address}
          />
          <input
            type="hidden"
            name="city"
            value={this.state.checkoutData?.payhereParams?.city}
          />
          <input
            type="hidden"
            name="country"
            value={this.state.checkoutData?.payhereParams?.country}
          />
          <input
            type="hidden"
            name="hash"
            value={this.state.checkoutData?.payhereParams?.hash}
          />
          <input
            type="hidden"
            name="order_id"
            value={this.state.checkoutData?.payhereParams?.orderId}
          />
          <input
            type="hidden"
            name="items"
            value={this.state.checkoutData?.payhereParams?.items}
          />
          <input
            type="hidden"
            name="currency"
            value={this.state.checkoutData?.payhereParams?.currency}
          />
          <input
            type="hidden"
            name="amount"
            value={this.state.checkoutData?.payhereParams?.amount}
          />
          <button id="pay-by-payhere" hidden>
            Submit
          </button>
        </form>

        <div className="row m-0 footer-row">
          <div className="col p-0">
            <p
              className="text-center mb-0"
              style={{ fontSize: 12, color: "#6c757d" }}
            >
              Payments are secured by
            </p>
            <img
              src={payments}
              alt="secured payments"
              style={{ display: "block", width: "240px", margin: "auto" }}
            />
          </div>
        </div>
        <div className="row m-0 footer-row">
          <div className="col p-0">
            <Footer restName={this.state.restName} />
          </div>
        </div>

        <LoginSignupModal />
      </React.Fragment>
    );
  }
}
