import React, { Component } from "react";
import ManageOrderType from "../../components/orderManage/ManageOrderType";
import ManageOrderTime from "../../components/orderManage/ManageOrderTime";
import { Link } from "react-router-dom";
import moment from "moment";
import ManageOrderTimeOnResClosed from "../orderManage/ManageOrderTimeOnResClosed";
import ManageOrderTimePreOrder from "../orderManage/ManageOrderTimePreOrder";
export default class HeaderBanner extends Component {
  state = {
    isChange: false,
  };

  rerenderParentCallback = () => {
    this.setState({ isChange: true });
  };

  render() {
    const restData = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant"));
    const cartItems = restData && localStorage.getItem(`cart_${restData?.id}`) ? JSON.parse(localStorage.getItem(`cart_${restData.id}`)) : [];
    const maxPreorderHours = cartItems.reduce((prev, current) => {
      return prev.preOrderHours > current.preOrderHours ? prev : current;
    }, 0);

    if (maxPreorderHours.preOrderHours > 0) {
      localStorage.setItem("asap", false);
    } else {
      if (localStorage.getItem("expectedAt") == "") {
        localStorage.setItem("asap", true);
      }
    }

    return (
      <React.Fragment>
        <div
          className="row m-0 menu-header"
          style={{
            backgroundImage: `URL(${
              localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.imageCover
                ? JSON.parse(localStorage.getItem("restaurant")).branding.imageCover.url
                : this.props.headerImg && this.props.headerImg.url
            })`,
          }}
        >
          <div className="header-rest-info-div">
            {localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.imageLogo ? (
              <img src={JSON.parse(localStorage.getItem("restaurant")).branding.imageLogo.url} alt="logo" className="rest-logo-main" />
            ) : null}

            <h4 className="rest-name">{this.props.restDetails && this.props.restDetails.name}</h4>

            <p className="d-inline-block mr-2 mb-0 address">
              {this.props.restDetails && this.props.restDetails.address.locality}
              {", "}
              {this.props.restDetails && this.props.restDetails.address.country}
            </p>

            <p className="mb-0 mt-1">
              {this.props.restDetails && this.props.restDetails.deliveryDetail.isAvailable ? (
                <span className="bg-success text-white d-inline-block res-open-lable">Open Now</span>
              ) : (
                <span className="bg-danger text-white d-inline-block res-closed-lable">Closed</span>
              )}
            </p>

            <Link to="/store-details" className="res-more-info">
              More info
            </Link>
          </div>
        </div>

        <div className="res-home-order-modification">
              {(JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.allowScheduled === true && JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.isAvailable === true) ||
              (JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.allowScheduled === false && JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.isAvailable === true) ||
              (JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.allowScheduled === true && JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.isAvailable === false)
               ? 
               ( <div className="order-modification">
               <div className="order-type-change">
                 <ManageOrderType rerenderParentCallback={this.rerenderParentCallback} handleMinimumOrder={this.handleMinimumOrder} restDetails={this.props.restDetails} />
               </div>
   
               <div className="order-time-change">
                 {maxPreorderHours && maxPreorderHours.preOrderHours === 0 && this.props.restDetails && this.props.restDetails.deliveryDetail.isAvailable === false ? (
                   <>
                     {/* Res closed */}
                     <div data-toggle="modal" data-target="#scheduleOnClosed" className="schedule-onclosed-wrapper">
                       {localStorage.getItem("expectedAt") !== "" ? (
                         "Order scheduled for: " + moment(localStorage.getItem("expectedAt")).format("MMMM Do YYYY, h:mm a") ||
                         "Order scheduled for: " + moment(this.props.expectedAt).format("MMMM Do YYYY, h:mm a")
                       ) : (
                         <>
                           <i className="fas fa-calendar-alt pr-2"></i> Schedule an Order{" "}
                         </>
                       )}
                     </div>
                     <ManageOrderTimeOnResClosed handleOrderShedule={this.props.handleOrderShedule} />
                   </>
                 ) : maxPreorderHours && maxPreorderHours.preOrderHours > 0 ? (
                   <>
                     {/* Pre order schedule */}
                     <div data-toggle="modal" data-target="#schedulePreOrder" className="schedule-onclosed-wrapper">
                       {localStorage.getItem("expectedAt") !== "" ? (
                         "Order scheduled for: " + moment(localStorage.getItem("expectedAt")).format("MMMM Do YYYY, h:mm a") ||
                         "Order scheduled for: " + moment(this.props.expectedAt).format("MMMM Do YYYY, h:mm a")
                       ) : (
                         <>
                           <i className="fas fa-calendar-alt pr-2"></i> Schedule an Order{" "}
                         </>
                       )}
                     </div>
                     <ManageOrderTimePreOrder handleOrderShedule={this.props.handleOrderShedule} />
                   </>
                 ) : (
                   <ManageOrderTime handleOrderShedule={this.props.handleOrderShedule} expectedAt={this.props.expectedAt} isChange={this.state.isChange} />
                 )}
               </div> 
             </div>): null}
         
        </div>
      </React.Fragment>
    );
  }
}
