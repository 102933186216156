const getGenTime = (timeString) => {
  let H = +timeString.substr(0, 2);
  let h = H % 12 || 12;
  let ampm = H < 12 ? " AM" : " PM";
  return {
    format24: timeString,
    format12: h + timeString.substr(2, 3) + ampm,
  };
};

export const returnTimesInBetween = (start, end) => {
  var timesInBetween = [];

  var startH = parseInt(start.slice(0, 2));
  var startM = parseInt(start.slice(2, 4));
  var endH = parseInt(end.slice(0, 2));
  var endM = parseInt(end.slice(2, 4));

  if (startM == 30) startH++;

  for (var i = startH; i < endH; i++) {
    timesInBetween.push(i < 10 ? "0" + i + ":00" : i + ":00");
    timesInBetween.push(i < 10 ? "0" + i + ":30" : i + ":30");
  }

  timesInBetween.push(endH + ":00");
  if (endM == 30) timesInBetween.push(endH + ":30");

  return timesInBetween.map(getGenTime);
};
