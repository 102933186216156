import React, { Component } from "react";
import _ from "lodash";
import Navbar from "../components/menu/Navbar";
import Footer from "../components/menu/Footer";
import GoogleMapReact from "google-map-react";
import "../styles/menu.css";
import { ReactComponent as VisaIcon } from "../images/visa.svg";
import { ReactComponent as MasterIcon } from "../images/mastercard.svg";
import { ReactComponent as CashIcon } from "../images/dollar.svg";
import moment from "moment";

const Marker = (props) => {
  return (
    <>
      <div className="pin"></div>
      <div className="pulse"></div>
    </>
  );
};

export class StoreDetails extends Component {
  state = {
    hours: [],
    restDetails: [],
    today: "",
    currency: {},
  };

  componentDidMount = () => {
    const hours = _.groupBy(
      localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).deliveryDetail && JSON.parse(localStorage.getItem("restaurant")).deliveryDetail.orderAcceptingHours
        ? JSON.parse(localStorage.getItem("restaurant")).deliveryDetail.orderAcceptingHours
        : [],
      "day"
    );

    var array = [];
    var index = 0;
    for (var k in hours) {
      array[index] = hours[k];
      index++;
    }

    const restData = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant"));

    const today = new Date().toLocaleString("en-us", { weekday: "long" });

    this.setState({ hours: array, restDetails: restData, today: today, currency: restData.currency });
  };

  getTime = (time) => {
    if (time.toString().length === 1) {
      return true;
    } else {
      return false;
    }
  };

  static defaultProps = {
    center: {
      lat: localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).address?.latitude,
      lng: localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).address?.longitude,
    },
    zoom: 15,
  };

   // TODO: make utility and move into it
   getFormattedPrice = (price, currency) => {
    const symbol = this.getCurrencySymbol(currency);
    return `${symbol} ${price}`;
  };

  // TODO: make utility and move into it
  getCurrencySymbol = (currency) => {
    return currency.symbol || currency.code;
  };

  render() {
    return (
      <>
        <Navbar
          restName={this.state.restDetails && this.state.restDetails.name}
          restId={this.state.restDetails && this.state.restDetails.id}
          e
          restIsAvailable={this.state.restDetails && this.state.restDetails.deliveryDetail?.isAvailable}
          name={localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).name}
          handleOrderShedule={this.handleOrderShedule}
          expectedAt={this.state.expectedAt}
          scheduleOrder={this.state.scheduleOrder}
        />

        <div className="store-details-wrapper">
          <div>
            {this.state.restDetails ? (
              <div className="rest-details-map">
                <GoogleMapReact defaultCenter={this.props.center} defaultZoom={this.props.zoom}>
                  <Marker lat={this.state.restDetails?.address?.latitude} lng={this.state.restDetails?.address?.longitude} />
                </GoogleMapReact>
              </div>
            ) : null}

            <div className="container-fluid">
              <div className="rest-store-details">
                <h4>{this.state.restDetails && this.state.restDetails.name}</h4>
                <p className="cuisines-para">
                  {this.state.restDetails &&
                    this.state.restDetails.cuisines &&
                    this.state.restDetails.cuisines.map((cuisines, i) => (
                      <span className="cuisines" key={i}>
                        <i className="fas fa-circle"></i>
                        {cuisines.name}
                      </span>
                    ))}
                </p>
                <p className="rest-store-details-desc">{this.state.restDetails && this.state.restDetails.description}</p>
              </div>

              <div className="container rest-store-details-full">
                <div className="row">
                  <div className="col-lg-6 col-md-6 p-0">
                    {this.state.restDetails && this.state.restDetails.deliveryDetail?.orderAcceptingHours && this.state.restDetails.deliveryDetail.orderAcceptingHours.length > 0 && (
                      <p className="rest-store-details-hours rest-store-details-title">
                        Open Hours
                        {this.state.restDetails && this.state.restDetails.deliveryDetail.isAvailable ? (
                          <span className="bg-success text-white d-inline-block res-open-lable">Open Now</span>
                        ) : (
                          <span className="bg-danger text-white d-inline-block res-closed-lable">Closed</span>
                        )}
                      </p>
                    )}

                    <div className="rest-store-details-open-hours-table">
                      <div className="pt-1">
                        <i className="fas fa-clock"></i>
                      </div>
                      <table className="ml-1 mb-1 mt-0" style={{ color: "#676767", fontSize: 14 }}>
                        <tbody>
                          {this.state.hours.map((time, i) => (
                            <tr key={i} style={time[0].day === this.state.today ? { fontWeight: 600, color: "#222222" } : {}}>
                              <td
                                style={{
                                  minWidth: 50,
                                  padding: "0 0 0 4px",
                                  verticalAlign: "text-top",
                                }}
                              >
                                {time[0].day.slice(0, 3)}
                              </td>
                              <td className="py-1" style={{ verticalAlign: "text-top" }}>
                                {time.map((times, i) => (
                                  <p className="m-0 open-time-display" key={i}>
                                    {times.openTime === times.closeTime
                                      ? "Closed"
                                      : `${moment(times.openTime, ["HH.mm"]).format("hh:mm A")}
                                      to
                                        ${moment(times.closeTime, ["HH.mm"]).format("hh:mm A")}`}
                                  </p>
                                ))}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="rsd-minimum-order-value rsd-details-block">
                      <p className="rest-store-details-title">Minimum Order Values</p>

                      <div className="minimum-order-values-wrapper">
                        <div className="minimum-order-values-block">
                          <div className="radio-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enableBackground="new 0 0 512.046 512.046" viewBox="0 0 512.046 512.046">
                              <g>
                                <path d="m181.046 211.023c0 16.538 13.462 30 30 30h75c24.853 0 45-20.147 45-45v-15h-90c0-16.538-13.462-30-30-30s-30 13.462-30 30z" />
                                <path d="m30.046 151.023h121v60h-121z" />
                                <path d="m30.046 241.023h121v60h-121z" />
                                <path d="m211.046 361.023c-22.114 0-41.25-12.153-51.656-30-9.941 0-162.424 0-159.344 0v29.99h31.668c-19.495 19.063-31.668 45.589-31.668 75.01v15h211z" />
                                <path d="m181.046 301.023c0 16.538 13.462 30 30 30h30v120h60v-150c0-16.538-13.462-30-30-30-3.333 0-63.246 0-60 0-10.977 0-21.14-3.177-30-8.344z" />
                                <path d="m112.724 481.023h-104.356c10.393 17.858 29.526 31 51.678 31s42.285-13.142 52.678-31z" />
                                <path d="m451.046 211.023v-60h-30c-16.569 0-30 13.431-30 30 0 16.567 13.431 30 30 30z" />
                                <path d="m449.632 302.393-.696-1.003-8.604-60.366h-19.286c-10.977 0-21.14-3.177-30-8.344v98.344c0 33.091-26.909 60-60 60v60h30c0-49.629 40.371-90 90-90 23.134 0 45.041 9.016 61 23.408v-8.408c0-36.769-27.497-67.223-62.414-73.631z" />
                                <path d="m457.731 391.697 8.168 57.202-29.707 4.248-8.21-57.499c-21.682 9.042-36.936 30.414-36.936 55.375 0 33.137 26.863 61 60 61s61-27.863 61-61c0-30.848-24.366-55.961-54.315-59.326z" />
                                <path d="m181.046 90.023c0 16.567 13.431 31 30 31s30-14.433 30-31v-15h-60z" />
                                <path d="m271.046 15.023h-34.598c-5.226-8.806-14.418-15-25.402-15-16.569 0-30 13.431-30 30v15h90z" />
                              </g>
                            </svg>
                          </div>
                          <p>
                            {this.state.restDetails && this.state.restDetails.deliveryDetail?.deliverySettings && this.state.restDetails.deliveryDetail?.deliverySettings?.minimumOrder.toFixed(2) > 0
                              ? this.getFormattedPrice(this.state.restDetails.deliveryDetail.deliverySettings.minimumOrder.toFixed(2), this.state.currency) 
                              : "N/A"}
                          </p>
                        </div>

                        <div className="minimum-order-values-block">
                          <div className="radio-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" x="0px" y="0px" viewBox="0 0 489.773 489.773">
                              <g>
                                <path
                                  id="XMLID_98_"
                                  d="M155.183,305.646c-2.081,0.175-4.157,0.265-6.238,0.265c-5.693,0-11.23-0.826-16.652-2.081L6.834,429.293   c-6.158,6.149-6.158,16.137,0,22.287l32.47,32.478c6.158,6.15,16.135,6.15,22.276,0l150.785-150.757l-27.944-30.15L155.183,305.646   z"
                                />
                                <path
                                  id="XMLID_97_"
                                  d="M485.345,104.649c-5.888-5.885-15.417-5.885-21.304,0l-81.303,81.301c-7.693,7.685-20.154,7.685-27.847,0   c-7.659-7.679-7.659-20.13,0-27.807l80.901-80.884c6.112-6.118,6.112-16.036,0-22.168c-6.141-6.11-16.055-6.11-22.167,0   l-80.868,80.876c-7.693,7.693-20.14,7.693-27.833,0c-7.677-7.676-7.677-20.136,0-27.806l81.286-81.293   c5.904-5.894,5.904-15.441,0-21.343c-5.888-5.895-15.434-5.895-21.338,0l-91.458,91.463   c-21.989,22.003-28.935,52.888-21.816,80.991l61.31,61.314c28.101,7.093,59.001,0.144,80.965-21.841l91.471-91.458   C491.249,120.1,491.249,110.543,485.345,104.649z"
                                />
                                <path
                                  id="XMLID_96_"
                                  d="M41.093,13.791c-3.134-3.135-7.372-4.854-11.724-4.854c-0.926,0-1.857,0.079-2.766,0.231   c-5.295,0.896-9.838,4.295-12.172,9.133c-26.79,55.373-15.594,121.631,27.894,165.121l77.801,77.791   c7.676,7.685,18.055,11.939,28.819,11.939c1.151,0,2.305-0.048,3.456-0.143l45.171-3.855l196.971,212.489   c3.058,3.303,7.342,5.221,11.855,5.31c0.093,0,0.19,0,0.288,0c4.412,0,8.636-1.743,11.771-4.855l33.734-33.741   c3.117-3.11,4.859-7.331,4.859-11.73c0-4.398-1.742-8.622-4.846-11.732L41.093,13.791z"
                                />
                              </g>
                            </svg>
                          </div>
                          <p>
                            {this.state.restDetails && this.state.restDetails.deliveryDetail?.dineInSettings && this.state.restDetails.deliveryDetail?.dineInSettings?.minimumOrder.toFixed(2) > 0
                              ? this.getFormattedPrice(this.state.restDetails.deliveryDetail.dineInSettings.minimumOrder.toFixed(2), this.state.currency)
                              : "N/A"}
                          </p>
                        </div>

                        <div className="minimum-order-values-block">
                          <div className="radio-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enableBackground="new 0 0 512 512" viewBox="0 0 512 512">
                              <g>
                                <path d="m345.906 0c-19.962 0-36.088 14.41-48.252 36.762-.049-.026-.108-.029-.157-.055-10.897 20.187-18.245 46.243-22.249 72.259 15.602 13.755 25.659 33.651 25.659 56.034 0 5.092-.963 10.06-1.972 15h122.095c-.005-1.714.099-3.375.053-5.112-.455-3.001.766-174.888-75.177-174.888z" />
                                <path d="m150.906 165c0-41.353 33.647-75 75-75 7.588 0 14.767 1.469 21.676 3.574 4.512-24.391 11.367-45.242 19.845-62.946-1.434.075-2.833-.32-4.27-.13-10.459-18.486-30.307-30.498-52.251-30.498-21.694 0-41.426 11.836-51.958 30.322-38.1 3.516-68.042 35.669-68.042 74.678 0 10.356 2.168 20.405 6.475 30-4.307 9.58-6.475 19.644-6.475 30v15h61.972c-1.009-4.94-1.972-9.908-1.972-15z" />
                                <path d="m90.906 512h330v-302h-330zm90-271h30v75c0 24.814 20.186 45 45 45s45-20.186 45-45v-75h30v75c0 41.353-33.647 75-75 75s-75-33.647-75-75z" />
                                <path d="m225.906 120c-24.814 0-45 20.186-45 45 0 5.184 1.196 10.182 2.911 15h84.177c1.716-4.818 2.911-9.816 2.911-15 .001-24.814-20.184-45-44.999-45z" />
                              </g>
                            </svg>
                          </div>
                          <p>
                            {this.state.restDetails && this.state.restDetails.deliveryDetail?.pickupSettings && this.state.restDetails.deliveryDetail.pickupSettings?.minimumOrder.toFixed(2) > 0
                              ? this.getFormattedPrice(this.state.restDetails.deliveryDetail.pickupSettings.minimumOrder.toFixed(2), this.state.currency)
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 p-0">
                    <div>
                      <p className="rest-store-details-hours rest-store-details-title">Location and Contact</p>
                      <div className="rest-contact-block">
                        <i className="fas fa-map-marker-alt"></i> &nbsp; &nbsp;
                        <p>{this.state.restDetails && this.state.restDetails.address?.pretty}</p>
                      </div>

                      <div className="rest-contact-block">
                        <i className="fas fa-phone fa-rotate-90"></i> &nbsp; &nbsp;
                        <a className="CTA-mobile mb-0" href={`tel:${this.state.restDetails && this.state.restDetails.phone}`}>
                          {this.state.restDetails && this.state.restDetails.phone}
                        </a>
                      </div>
                    </div>

                    <div className="rsd-payment-methods rsd-details-block">
                      <p className="rest-store-details-title">Accepted Payment Methods</p>

                      {this.state.restDetails ? (
                        <React.Fragment>
                          {this.state.restDetails.deliveryDetail?.acceptsCard === true && this.state.restDetails.deliveryDetail?.acceptsCash === true ? (
                            <div className="payment-method">
                              <div className="payment-methods-icon">
                                <VisaIcon />
                                <MasterIcon /> | &nbsp;&nbsp;
                                <CashIcon />
                              </div>
                            </div>
                          ) : null}
                          {this.state.restDetails.deliveryDetail?.acceptsCard === true && this.state.restDetails.deliveryDetail?.acceptsCash === false ? (
                            <div className="payment-method">
                              <div className="payment-methods-icon">
                                <VisaIcon />
                                <MasterIcon />
                              </div>
                            </div>
                          ) : null}
                          {this.state.restDetails.deliveryDetail?.acceptsCard === false && this.state.restDetails.deliveryDetail?.acceptsCash === true ? (
                            <div className="payment-method">
                              <div className="payment-methods-icon">
                                <CashIcon />
                              </div>
                            </div>
                          ) : null}
                        </React.Fragment>
                      ) : null}
                    </div>

                    <div className="rsd-delivering-to rsd-details-block">
                      <p className="rest-store-details-title">Delivering To</p>
                      {this.state.restDetails && this.state.restDetails.deliveryDetail?.deliverySettings && this.state.restDetails.deliveryDetail?.deliverySettings?.locations?.length > 0
                        ? this.state.restDetails.deliveryDetail.deliverySettings.locations
                            .map((item) => {
                              return item;
                            })
                            .join(", ")
                        : "N/A"}
                    </div>

                    {(this.state.restDetails && this.state.restDetails.deliveryDetail?.pricing) || (this.state.restDetails && this.state.restDetails.deliveryDetail?.fee) ? (
                      <div className="rsd-delivering-fee rsd-details-block">
                        <p className="rest-store-details-title">Delivery Fee</p>
                        {this.state.restDetails
                          ? this.state.restDetails.deliveryDetail?.pricing
                            ? this.state.restDetails.deliveryDetail?.pricing.freeDistance > 0
                              ? `Free up to ${this.state.restDetails.deliveryDetail?.pricing.freeDistance / 1000}km`
                              : this.state.restDetails.deliveryDetail?.pricing.basePrice > 0
                              ? `Starting from ${this.getFormattedPrice(this.state.restDetails.deliveryDetail.pricing.basePrice.toFixed(2), this.state.currency)}`
                              : `${this.getFormattedPrice(this.state.restDetails.deliveryDetail?.fee.toFixed(2), this.state.currency)}`
                            : `${this.getFormattedPrice(this.state.restDetails.deliveryDetail?.fee.toFixed(2), this.state.currency)}`
                          : ""}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer restName={this.state.restDetails && this.state.restDetails.name} />
      </>
    );
  }
}

export default StoreDetails;
