import { isAuthenticated } from "../../auth/auth";

export const logErrors = async (err, orderData) => {
  const data = {
    clientId: "web",
    response: err?.response?.data?.message ? err.response.data.message : null,
    statusCode: err?.response?.status ? err.response.status : null,
    url: err.request?.responseURL,
    additionalData: {
      agent: navigator.userAgent,
      orderData: orderData ? orderData : null,
    },
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/v2/api_errors`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error("error is not logged");
    }
  } catch (error) {
    console.log(error);
  }
};
