import React, { createContext } from "react";

const defaultValues = {
  errorPage: undefined,
  setErrorPage: undefined,
};

const AppContext = createContext(defaultValues);
export default AppContext;

export const ERROR_PAGES = Object.freeze({
  NotFound: "NotFound",
  ServerError: "ServerError",
  NoConnection: "NoConnection",
  AuthenticationError: "AuthenticationError",
});
