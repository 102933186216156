import React, { Component } from "react";

import "../../styles/shop-footer.css";

import Insta from "../../images/insta.png";
import Fb from "../../images/fb.png";
import Twitter from "../../images/twitter.png";
import Pinterest from "../../images/pinterest.png";

import { hexToRgb } from "../../methods/CalBrightness";

export default class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="container-fluid footer-sec"
          style={{
            background:
              localStorage.getItem("restaurant") &&
              JSON.parse(localStorage.getItem("restaurant")).branding &&
              JSON.parse(localStorage.getItem("restaurant")).branding
                .colorFooter
                ? JSON.parse(localStorage.getItem("restaurant")).branding
                    .colorFooter
                : "#151b26",
          }}
        >
          <div className="container-fluid">
          <div className="row">
            <div className="col-social p-0" style={{
                  color:
                    hexToRgb(
                      localStorage.getItem("restaurant") &&
                        JSON.parse(localStorage.getItem("restaurant"))
                          .branding &&
                        JSON.parse(localStorage.getItem("restaurant")).branding
                          .colorFooter
                        ? JSON.parse(localStorage.getItem("restaurant"))
                            .branding.colorFooter
                        : "#000000"
                    ) > 0.65
                      ? "#000"
                      : "#fff",
                }}>
              <div>
                <h5>Find us on</h5>
                {localStorage.getItem("restaurant") &&
                JSON.parse(localStorage.getItem("restaurant")).branding &&
                JSON.parse(localStorage.getItem("restaurant")).branding.links ? (
                  <div className="social-dev">
                    {JSON.parse(localStorage.getItem("restaurant")).branding
                      .links[1].url ? (
                      <a
                        href={
                          JSON.parse(localStorage.getItem("restaurant")).branding
                            .links[1].url
                        }
                        target="_blank"
                      >
                        <img
                          src={Insta}
                          alt="instagram"
                          className="social-icon"
                        />
                      </a>
                    ) : null}
                    {JSON.parse(localStorage.getItem("restaurant")).branding
                      .links[0].url ? (
                      <a
                        href={
                          JSON.parse(localStorage.getItem("restaurant")).branding
                            .links[0].url
                        }
                        target="_blank"
                        
                      >
                        <img src={Fb} alt="facebook" className="social-icon" />
                      </a>
                    ) : null}
                    {JSON.parse(localStorage.getItem("restaurant")).branding
                      .links[2].url ? (
                      <a
                        href={
                          JSON.parse(localStorage.getItem("restaurant")).branding
                            .links[2].url
                        }
                        target="_blank"
                      >
                        <img
                          src={Twitter}
                          alt="twitter"
                          className="social-icon"
                        />
                      </a>
                    ) : null}
                    {JSON.parse(localStorage.getItem("restaurant")).branding
                      .links[3].url ? (
                      <a
                        href={
                          JSON.parse(localStorage.getItem("restaurant")).branding
                            .links[3].url
                        }
                        target="_blank"
                      >
                        <img
                          src={Pinterest}
                          alt="Pinterest"
                          className="social-icon"
                        />
                      </a>
                    ) : null}
                  </div>
                ) : null}
              </div>

              <div className="footer-restaurant info">
                <div className="rest-contact-block">
                  <i className="fas fa-map-marker-alt"></i> &nbsp; &nbsp;
                  <p>
                    {localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).address?.pretty}
                  </p>
                </div>

                <div className="rest-contact-block">
                  <i className="fas fa-phone fa-rotate-90"></i> &nbsp; &nbsp;
                  <a
                    className="CTA-mobile mb-0"
                    style={{
                      color:
                        hexToRgb(
                          localStorage.getItem("restaurant") &&
                            JSON.parse(localStorage.getItem("restaurant"))
                              .branding &&
                            JSON.parse(localStorage.getItem("restaurant")).branding
                              .colorFooter
                            ? JSON.parse(localStorage.getItem("restaurant"))
                                .branding.colorFooter
                            : "#000000"
                        ) > 0.65
                          ? "#000"
                          : "#fff",
                    }}
                    href={`tel:${
                      localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).phone
                    }`}
                  >
                    {localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).phone}
                  </a>
                </div>
              </div>
            </div>

            <div className="col-rest-about p-0" style={{
                  color:
                    hexToRgb(
                      localStorage.getItem("restaurant") &&
                        JSON.parse(localStorage.getItem("restaurant"))
                          .branding &&
                        JSON.parse(localStorage.getItem("restaurant")).branding
                          .colorFooter
                        ? JSON.parse(localStorage.getItem("restaurant"))
                            .branding.colorFooter
                        : "#000000"
                    ) > 0.65
                      ? "#000"
                      : "#fff",
                }}>
              {/* <h5>About Us</h5>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae harum deserunt explicabo, consequuntur perferendis, mollitia minima reprehenderit excepturi officiis, illum dolore nobis eaque commodi accusantium? Eveniet a quas animi earum?</p> */}
            </div>

          </div>
          <div className="row">
            <div
              className="col p-0"
              style={{
                borderBottom: "0.5px solid",
                borderColor:
                  hexToRgb(
                    localStorage.getItem("restaurant") &&
                      JSON.parse(localStorage.getItem("restaurant")).branding &&
                      JSON.parse(localStorage.getItem("restaurant")).branding
                        .colorFooter
                      ? JSON.parse(localStorage.getItem("restaurant")).branding
                          .colorFooter
                      : "#000000"
                  ) > 0.65
                    ? "#000"
                    : "#fff",
              }}
            >
              <p
                className="shop-copy-right"
                style={{
                  color:
                    hexToRgb(
                      localStorage.getItem("restaurant") &&
                        JSON.parse(localStorage.getItem("restaurant"))
                          .branding &&
                        JSON.parse(localStorage.getItem("restaurant")).branding
                          .colorFooter
                        ? JSON.parse(localStorage.getItem("restaurant"))
                            .branding.colorFooter
                        : "#000000"
                    ) > 0.65
                      ? "#000"
                      : "#fff",
                }}
              >
                &copy; {new Date().getFullYear()} {this.props.restName}.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col p-0">
              <p
                className="text-center mt-3"
                style={{
                  fontSize: 12,
                  color:
                    hexToRgb(
                      localStorage.getItem("restaurant") &&
                        JSON.parse(localStorage.getItem("restaurant"))
                          .branding &&
                        JSON.parse(localStorage.getItem("restaurant")).branding
                          .colorFooter
                        ? JSON.parse(localStorage.getItem("restaurant"))
                            .branding.colorFooter
                        : "#000000"
                    ) > 0.65
                      ? "#000"
                      : "#fff",
                }}
              >
                <span>Powered by</span>{" "}
                <a
                  href="https://www.spritzer.app/"
                  target="_blank"
                  style={{
                    fontFamily: "ubuntu",
                    textDecoration: "underline",

                    color:
                      hexToRgb(
                        localStorage.getItem("restaurant") &&
                          JSON.parse(localStorage.getItem("restaurant"))
                            .branding &&
                          JSON.parse(localStorage.getItem("restaurant"))
                            .branding.colorFooter
                          ? JSON.parse(localStorage.getItem("restaurant"))
                              .branding.colorFooter
                          : "#000000"
                      ) > 0.65
                        ? "#000"
                        : "#fff",
                  }}
                >
                  Spritzer
                </a>
              </p>
            </div>
          </div>
        </div>
        </div>
      </React.Fragment>
    );
  }
}
