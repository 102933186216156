import axios from "axios";
import { isAuthenticated } from "../../auth/auth";
import qs from "qs";

export const submitUserDetailsAPI = (restId, addressId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/v2/listings/${restId}/delivery_cost?addressId=${addressId}`, {
    headers: {
      Authorization: `Bearer ${isAuthenticated().providerToken}`,
    },
  });
};

export const handleDeliveryAddress = (addressData, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v2/my_profile/delivery_addresses`, addressData, {
      headers: {
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getUserProfile = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/user/profile`, {
      headers: {
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const phonenumberVerify = (number, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v2/phonenumbers`, qs.stringify({ number }), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const phonenumberVerifyOTP = (code, phoneId, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v2/phonenumbers/${phoneId}/verify`, qs.stringify({ code: code }), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const updatePhonenumber = (phoneId, onSuccess, onError) => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/v2/my_profile/phonenumber`, qs.stringify({ phoneId }), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })

    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const userLogin = (loginData, onSuccess, onError) => {
  // send post req to register an user
  axios
    .post(`${process.env.REACT_APP_API_URL}/user/login`, loginData)

    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data);
    });
};

export const getUserProfileOnLogin = (providerToken, onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/user/profile`, {
      headers: {
        Authorization: `Bearer ${providerToken}`,
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data);
    });
};

export const getUserProfileAuth = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/user/profile`, {
    headers: {
      Authorization: `Bearer ${JSON.parse(document.cookie.match(new RegExp("(^| )auth_token=([^;]+)"))[2]).providerToken}`,
    },
  });
};

export const userRegister = (userData, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/user/register`, userData)

    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data);
    });
};

export const userLoginAfterRegister = (loginData, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/user/login`, loginData)

    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data);
    });
};

export const resendEmailVerificationAPI = (email, onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/user/activate/resend?email=${email}`)

    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data);
    });
};

export const continueWithEmail = (email, onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/user/exists?email=${email}`, {
      headers: {
        secret: "7EBG2G4JkcaFWVEJaKFTyjzAdMww9QCK7J9WyMdGRcyTQggGG42kqYvtMWuATxWqVSxWpLhGpVHc4XEy2nQjqUwaa7FFm7vGwHHL5eSNGqAZZ3RSyVCzSHTR4yt3u8nS",
      },
    })

    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data);
    });
};

export const submitGuestAddressAPI = (restId, latitude, longitude) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/v2/listings/${restId}/delivery_cost_guest`,
    { params: { latitude: latitude, longitude: longitude } },
  );
};
