exports.isAuthenticated = () => {
  if (typeof window !== "undefined") {
    if (document.cookie.includes("auth_token")) {
      return JSON.parse(document.cookie.match(new RegExp("(^| )auth_token=([^;]+)"))[2]);
    } else {
      return false;
    }
  } else {
    return false;
  }
};

exports.isAuthenticatedWithGuest = () => {
  if (typeof window !== "undefined") {
    if (document.cookie.includes("auth_token")) {
      return JSON.parse(document.cookie.match(new RegExp("(^| )auth_token=([^;]+)"))[2]);
    } else if (document.cookie.includes("guest_token")) {
      const match = document.cookie.match(RegExp('(?:^|;\\s*)' + "guest_token" + '=([^;]*)')); 
      return match && match[1] ? true : false;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

// exports.isAuthenticated = () => {
//   if (document.location.host.includes("spritzer")) {
//     if (typeof window !== "undefined") {
//       if (document.cookie.includes("auth_token")) {
//         return JSON.parse(
//           document.cookie.match(new RegExp("(^| )auth_token=([^;]+)"))[2]
//         );
//       } else {
//         return false;
//       }
//     } else {
//       return false;
//     }
//   } else {
//     if (typeof window !== "undefined") {
//       if (localStorage.getItem("auth_token")) {
//         return JSON.parse(localStorage.getItem("auth_token"));
//       } else {
//         return false;
//       }
//     } else {
//       return false;
//     }
//   }
// };
