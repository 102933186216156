import axios from "axios";
import { isAuthenticated } from "../../auth/auth";

export const getAddOns = (listingId, itemId, onSuccess, onError) => {
    axios.get(`${process.env.REACT_APP_API_URL}/v2/listings/${listingId}/menu_items/${itemId}/addon_groups`,
        {
            headers: {
                Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
        })
        .then((res) => {
            onSuccess(res)
        })
        .catch((err) => {
            onError(err)
        })
};