import axios from "axios";
import { isAuthenticated } from "../../auth/auth";

export const getDeliveryCost = (id, onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v2/listings/${id}/delivery_cost?addressId=${localStorage.getItem("deliveryAddress")}`, {
      headers: {
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(err => {
      onError(err);
    });
};

export const handleOrderAPI = (orderData, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v2/delivery_orders`, orderData, {
      headers: {
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(err => {
      onError(err.response.data);
    });
};

export const getAvailableCards = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v2/paymentsources`, {
      headers: {
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(err => {
      onError(err.response.data);
    });
};

export const addNewCard = (cardData, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v2/paymentsources`, cardData, {
      headers: {
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(err => {
      onError(err.response.data);
    });
};

export const getMyProfile = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v2/my_profile`, {
      headers: {
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(err => {
      onError(err.response.data);
    });
};

export const deliveryOrders = (orderData, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v2/delivery_orders`, orderData, {
      headers: {
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(err => {
      onError(err);
    });
};

export const payBywholeProcessAPI = (cardData, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v2/paymentsources`, cardData, {
      headers: {
        Authorization: `Bearer ${isAuthenticated().providerToken}`,
      },
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(err => {
      onError(err.response.data);
    });
};

export const getAvailableOrderScheduleTime = (expectedAt, listingId, onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v2/listings/${listingId}/is_valid_ordertime?time=${expectedAt}`)

    .then(res => {
      onSuccess(res.data);
    })
    .catch(err => {
      onError(err);
    });
};

export const checkoutSessionForAnOrderAPI = (orderId, checkOutData, onSuccess, onError) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/v2/delivery_orders/${orderId}/checkout`,
    headers: {
      Authorization: `Bearer ${isAuthenticated().providerToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: checkOutData,
  };

  axios(config)
    .then(response => {
      onSuccess(response.data);
    })
    .catch(err => {
      onError(err);
    });
};

export const deliveryOrdersGuestAPI = (orderData, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v2/delivery_orders_guest`, orderData)
    .then(res => {
      onSuccess(res.data);
    })
    .catch(err => {
      onError(err);
    });
};

export const paymentMethodsAPI = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v2/payment_methods`)
    .then(res => {
      onSuccess(res.data);
    })
    .catch(err => {
      onError(err);
    });
};