import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Navbar from "../menu/Navbar";
import Footer from "../menu/Footer";
import { withRouter } from "react-router-dom";
import LoginSignupModal from "../LoginSignupModal";
import { removeCartItem,clearCart } from "../../methods/CartMethods";
import ManageOrderTime from "../orderManage/ManageOrderTime";
import ManageOrderType from "../orderManage/ManageOrderType";
import moment from "moment";
import ManageOrderTimeOnResClosed from "../orderManage/ManageOrderTimeOnResClosed";
import NumberFormat from "react-number-format";
import { getListingAPI } from "../api/ListingsAPI";
import { getAvailableOrderScheduleTime } from "../api/CheckoutAPI";
import ManageOrderTimePreOrder from "../orderManage/ManageOrderTimePreOrder";

class YourCart extends Component {
  state = {
    items: [],
    minimumOrder: "",
    restName: "",
    tableName: "",
    // customDomain: "",
    isChange: false,

    redirectToUserDetail: false,
    matchMinOrder: false,

    // Active Checkout Button
    allowCheckoutButton: false,

    toggleRerender: false,
    expectedAt: "",
    currency: {},
  };

  componentDidMount = () => {
    let restData = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant"));
    let cart;

    if(JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.allowScheduled === true) {
      cart = localStorage.getItem(`cart_${restData.id}`) ? JSON.parse(localStorage.getItem(`cart_${restData.id}`)) : [];
    }
    else {
      clearCart(restData.id);
      cart = [];

    }

    // Get Order Type
    const getOrderType = localStorage.getItem("orderType");

    this.setState({
      extId: restData.extId,
      items: cart,
      restId: restData.id,
      restName: restData.name,
      orderType: getOrderType,
      isResAvailable: restData.deliveryDetail.isAvailable,
      currency: restData.currency,
    });

    if (localStorage.getItem("orderType") === "dine_in" && localStorage.getItem("tableId")) {
      const table = restData.deliveryDetail.dineInSettings.tables.filter((filter) => filter.id === localStorage.getItem("tableId"));

      if (table[0]) {
        this.setState({ tableName: table[0].name });
      } else {
        this.setState({ tableName: "" });
      }
    }
    this.handleMinimumOrder();
    window.scrollTo(0, 0);
  };

  handleMinimumOrder = () => {
    const selectedType = localStorage.getItem("orderType");
    let restData = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant"));
    if (["delivery"].includes(selectedType)) {
      this.setState({
        minimumOrder: restData.deliveryDetail.deliverySettings.minimumOrder,
      });
    }
    if (["pickup"].includes(selectedType)) {
      this.setState({
        minimumOrder: restData.deliveryDetail.pickupSettings.minimumOrder,
      });
    }
    if (["dine_in"].includes(selectedType)) {
      this.setState({
        minimumOrder: restData.deliveryDetail.dineInSettings.minimumOrder,
      });
    }
  };

  // remove items from cart
  handleRemoveCartItem = (id) => {
    // execute removeCartItem methord
    removeCartItem(id, this.state.restId);

    // update the cart
    const cart = JSON.parse(localStorage.getItem(`cart_${this.state.restId}`));
    this.setState({ items: cart });
  };

  storeTotal = () => {
    if (this.state.items.length > 0) {
      if (document.cookie.includes("auth_token")) {
        getListingAPI(
          (data) => {
            let expectedTime = localStorage.getItem("expectedAt");
            let isAsap = localStorage.getItem("asap");

            if (data.deliveryDetail.isAvailable === true) {
                this.props.history.push(`/checkout/your-details`);
            } else {
              this.setState({
                isResAvailable: false,
              });

              if (expectedTime) {
                const expectedAt = moment(expectedTime).format("YYYY-MM-DD HH:mm:ss.0");
                getAvailableOrderScheduleTime(
                  expectedAt,
                  data.id,
                  (data) => {
                    if (data.value === true) {
                      this.props.history.push(`/checkout/your-details`);
                    } else {
                      document.getElementById("schedule-on-closed").click();
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                );
              } else {
                document.getElementById("schedule-on-closed").click();
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        document.getElementById("login-modal").setAttribute("data-target", "#login-sign-up-modal");
        document.getElementById("login-modal").setAttribute("data-toggle", "modal");
        document.getElementById("login-modal").click();

        this.setState({ showModal: true });
      }
    }
  };

  // close modal
  close = () => {
    this.setState({ matchMinOrder: false });
  };

  handleMinOrderChange = (e) => {
    if (e.target.checked) {
      localStorage.setItem("matchMinOrder", true);
      this.setState({ allowCheckoutButton: true });
    } else {
      localStorage.setItem("matchMinOrder", false);
      this.setState({ allowCheckoutButton: false });
    }
  };

  handleOnItemCountChange = (event, id) => {
    let cart = [];

    if (localStorage.getItem(`cart_${this.state.restId}`)) {
      cart = JSON.parse(localStorage.getItem(`cart_${this.state.restId}`));
    }

    cart.map((item, i) => {
      if (i === id) {
        cart[i].count = event.target.value;
      }
    });

    localStorage.setItem(`cart_${this.state.restId}`, JSON.stringify(cart));
    const updatedCart = JSON.parse(localStorage.getItem(`cart_${this.state.restId}`));
    this.setState({ items: updatedCart });
  };

  rerenderParentCallback = () => {
    this.setState({
      toggleRerender: !this.state.toggleRerender,
      isChange: true,
    });
  };

  buildCountOptions(minOrderCount, maxOrderCount) {
    let options = [];
    for (let i = minOrderCount; i <= maxOrderCount; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    let filtered = options.filter(function (el) {
      return el != null;
    });
    return filtered;
  }

  handleOrderSheduleTime = (time) => {
    this.setState({ expectedAt: time });
  };

  // TODO: make utility and move into it
  getFormattedPrice = (price, currency) => {
    const symbol = this.getCurrencySymbol(currency);
    return `${symbol} ${price}`;
  }

  // TODO: make utility and move into it
  getCurrencySymbol = (currency) => {
    return currency.symbol || currency.code;
  };

  render() {
    let addonSubTotal = 0;
    this.state.items.map((item) => {
      const count = item.count;
      const addonTotal =
        item.addOns &&
        item.addOns.reduce((currentValue, nextValue) => {
          return currentValue + parseInt(nextValue.price);
        }, 0);
      addonSubTotal = addonSubTotal + addonTotal * count;
    });

    let portionSizeTotal = 0;
    this.state.items.map((item) => {
      const count = item.count;
      if (item.portionSize.price) {
        portionSizeTotal = portionSizeTotal + item.portionSize.price * count;
      } else {
        let price = item.displayPriceDiscounted ? item.displayPriceDiscounted : item.displayPrice;
        portionSizeTotal = portionSizeTotal + price * count;
      }
    });

    const subTotal = addonSubTotal + portionSizeTotal;
    const defMinOrder = this.state.minimumOrder - subTotal;
    const negOrPos = Math.sign(defMinOrder);
    localStorage.setItem(`cartSubTotal`, JSON.stringify(subTotal));

    const restData = localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant"));
    const cartItems = localStorage.getItem(`cart_${restData.id}`) ? JSON.parse(localStorage.getItem(`cart_${restData.id}`)) : [];
    const maxPreorderHours = cartItems.reduce((prev, current) => {
      return prev.preOrderHours > current.preOrderHours ? prev : current;
    }, 0);

    return (
      <React.Fragment>
        <div className="p-0 page-background-grey">
          <Navbar
            restName={localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).name}
            name={localStorage.getItem("profile") && JSON.parse(localStorage.getItem("profile")).name}
            restId={localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).id}
            restIsAvailable={false}
            displayOrderType={false}
            displayPickupType={false}
            displayMenuStore={true}
          />

          {this.state.redirectToUserDetail ? <Redirect to="/checkout/your-details" /> : null}

          <div className="container min-height-viewport padding-top-bottom-25">
            <div className="cart-sec">
              <div className="row m-0">
                <div className="col p-0 cart-details-main">
                  <div>
                    <div className="go-back-action-buttons">
                      <Link className="text-muted d-block mb-3" to="/" style={{ textDecoration: "none" }}>
                        {" "}
                        <i className="fas fa-chevron-left"></i>Back
                      </Link>
                    </div>

                    <div className="order-modification">
                      {
                        (JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.allowScheduled === true && JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.isAvailable === true) ||
                        (JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.allowScheduled === false && JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.isAvailable === true) ||
                        (JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.allowScheduled === true && JSON.parse(localStorage.getItem("restaurant"))?.deliveryDetail?.isAvailable === false)
                         ? ( 
                          <><div className="order-type-change">
                              <ManageOrderType rerenderParentCallback={this.rerenderParentCallback} handleMinimumOrder={this.handleMinimumOrder} handleSelectedOrderType={this.handleSelectedOrderType} />
                            </div><div className="order-time-change">
                                {!this.state.isResAvailable ? (
                                  <>
                                    <div data-toggle="modal" data-target="#scheduleOnClosed" className="schedule-onclosed-wrapper">
                                      {localStorage.getItem("expectedAt") !== "" ? (
                                        "Order scheduled for: " + moment(localStorage.getItem("expectedAt")).format("MMMM Do YYYY, h:mm a") ||
                                        "Order scheduled for: " + moment(this.props.expectedAt).format("MMMM Do YYYY, h:mm a")
                                      ) : (
                                        <>
                                          <i className="fas fa-calendar-alt pr-2"></i> Schedule an Order{" "}
                                        </>
                                      )}
                                    </div>
                                    <ManageOrderTimeOnResClosed handleOrderShedule={this.handleOrderSheduleTime} />
                                  </>
                                ) : maxPreorderHours && maxPreorderHours.preOrderHours > 0 ? (
                                  <>
                                    <div data-toggle="modal" data-target="#schedulePreOrder" className="schedule-onclosed-wrapper">
                                      {localStorage.getItem("expectedAt") !== "" ? (
                                        "Order scheduled for: " + moment(localStorage.getItem("expectedAt")).format("MMMM Do YYYY, h:mm a") ||
                                        "Order scheduled for: " + moment(this.props.expectedAt).format("MMMM Do YYYY, h:mm a")
                                      ) : (
                                        <>
                                          <i className="fas fa-calendar-alt pr-2"></i> Schedule an Order{" "}
                                        </>
                                      )}
                                    </div>
                                    <ManageOrderTimePreOrder handleOrderShedule={this.handleOrderSheduleTime} />
                                  </>
                                ) : (
                                  <ManageOrderTime rerenderParentCallback={this.rerenderParentCallback} isChange={this.state.isChange} />
                                )}
                              </div></>
                         ) : null }
                    </div>

                    <h1 className="cart-heading">Your {localStorage.getItem("orderType") === "delivery" ? "Delivery" : localStorage.getItem("orderType") === "pickup" ? "Pickup" : "Dine In"} Order</h1>
                    {/* {localStorage.getItem("orderType") === "dine_in" &&
                    localStorage.getItem("tableId") ? (
                      <p
                        className="deliver-from text-muted"
                        style={{ fontSize: 14 }}
                      >
                        Table : {this.state.tableName}
                      </p>
                    ) : null} */}
                    {localStorage.getItem("orderType") !== "dine_in" && localStorage.getItem("expectedAt") ? (
                      <p className="deliver-from text-muted" style={{ fontSize: 14 }}>
                        {localStorage.getItem("expectedAt") && moment(localStorage.getItem("expectedAt")).format("DD MMMM YYYY, h:mm:ss a")}
                      </p>
                    ) : null}
                    <hr />
                    {!this.state.items.length ? (
                      <p className="text-danger text-center">
                        Cart is empty. Click <Link to="/">here</Link> to go back
                      </p>
                    ) : null}

                    <div className="full-page-cart-main" style={{ overflowX: "auto" }}>
                      <div className="table item-table">
                        {this.state.items.map((cartItem, i) => (
                          <div className="item-table-item-details-main" key={i}>
                            <div className="item-table-item-details">
                              <div className="item-table-item-details-item-count">
                                <select
                                  name="order-qty"
                                  className="order-qty"
                                  value={cartItem.count}
                                  onChange={(e) => {
                                    this.handleOnItemCountChange(e, i);
                                  }}
                                >
                                  {this.buildCountOptions(cartItem.minOrderQty, cartItem.maxOrderQty)}
                                </select>
                              </div>

                              <div className="item-table-item-details-item-name">{cartItem.name}</div>

                              <div className="item-table-item-details-item-price">
                                {`${this.getCurrencySymbol(this.state.currency)} `}
                                <NumberFormat
                                  value={(
                                    (cartItem.portionSize.price
                                      ? cartItem.portionSize.price * cartItem.count
                                      : cartItem.hasDiscount
                                      ? cartItem.displayPriceDiscounted * cartItem.count
                                      : cartItem.displayPrice * cartItem.count) +
                                    (cartItem.addOns &&
                                      cartItem.addOns.reduce((currentValue, nextValue) => {
                                        return currentValue + parseInt(nextValue.price) * cartItem.count;
                                      }, 0))
                                  ).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                <div className="remove-cart-item">
                                  <span onClick={() => this.handleRemoveCartItem(i)}>Remove Item</span>
                                </div>
                              </div>
                            </div>

                            <div className="portion-display-cart">
                              {cartItem.portionSize && cartItem.portionSize.price ? (
                                <div key={i} className="addons-display-cart-addon-item">
                                  Portion Size: {cartItem.portionSize.name}
                                </div>
                              ) : null}
                            </div>

                            <div className="addons-display-cart">
                              {cartItem.addOns && cartItem.addOns.length > 0 ? <h6 className="addons-display-cart-title">Add-Ons</h6> : null}
                              {cartItem.addOns &&
                                cartItem.addOns.map((addon, i) => (
                                  <div key={addon.groupId + i} className="addons-display-cart-addon-item">
                                    {addon.name}
                                  </div>
                                ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="row mx-0 total-sec subtotal">
                      <div className="col-12 text-right cart-order-total">
                        {`Total Order: ${this.getFormattedPrice(
                            subTotal.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            }), 
                            this.state.currency)}`
                        }
                      </div>
                    </div>

                    {negOrPos === 1 ? (
                      <div className="match-min-order-checkbox">
                        <input type="checkbox" id="min-order-match" defaultChecked={this.state.allowCheckoutButton} value={this.state.allowCheckoutButton} onChange={this.handleMinOrderChange}></input>
                        <label htmlFor="min-order-match">
                          {(() => {
                            const localeConfig = { minimumFractionDigits: 2, };
                            const _defMinOrder = this.getFormattedPrice(
                              defMinOrder.toLocaleString(undefined, localeConfig), 
                              this.state.currency
                            );
                            let _valueNeedToAdd = defMinOrder + subTotal;
                            _valueNeedToAdd = this.getFormattedPrice(
                              _valueNeedToAdd.toLocaleString(undefined, localeConfig), 
                              this.state.currency
                            );
                            return `Add ${_defMinOrder} to match the minimum order value of ${_valueNeedToAdd}`
                          })()}
                        </label>
                      </div>
                    ) : null}

                    <div className="checkout-btn">
                      <button
                        className="checkout mt-3"
                        onClick={this.storeTotal}
                        disabled={negOrPos === 1 && this.state.allowCheckoutButton === false}
                        style={{
                          background:
                            localStorage.getItem("restaurant") && JSON.parse(localStorage.getItem("restaurant")).branding && JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                              ? JSON.parse(localStorage.getItem("restaurant")).branding.colorAccent
                              : "#ff7068",
                        }}
                      >
                        Continue
                      </button>
                    </div>

                    <div className="cart-continue-shopping">
                      <Link to="/">Continue Shopping</Link>
                    </div>

                    <input type="hidden" id="login-modal" />
                    <input type="hidden" id="schedule-on-closed" data-toggle="modal" data-target="#scheduleOnClosed" />
                    <input type="hidden" id="schedule-order" data-toggle="modal" data-target="#schedule" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 footer-row">
          <div className="col p-0">
            <Footer restName={this.state.restName} />
          </div>
        </div>

        <LoginSignupModal />
      </React.Fragment>
    );
  }
}

export default withRouter(YourCart);
