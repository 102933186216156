export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  let R = parseInt(result[1], 16);
  let G = parseInt(result[2], 16);
  let B = parseInt(result[3], 16);

  const brightness = (0.299 * R + 0.587 * G + 0.114 * B) / 255;

  return brightness;
};
